// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { apiCalling, truthyValue, refFunction } from '../../../components/src/asset'
import { toast } from "react-toastify";
import moment from "moment"


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  receiverId : string
  updateCount: (count: number) => void;
  // Customizable Area End
}

interface SenderInfo {
    senderImage: string,
    senderName : string,
    senderRole : string
}

interface ChatMessage  {
    msgId: number
    msgText : string
    msgType : string
    chatType : string
    chatTime : string
    userImage? : string,
}

interface S {
  // Customizable Area Start  
  accountId : string,
  senderInfo : SenderInfo
  textMsg: string
  chatInfo : ChatMessage[],
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReservationChatController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  msgRef: React.RefObject<HTMLInputElement>
  getSavedUserProfileApiCallId : string = ""
  getChatInfoApiCallId : string = ""
  sendMsgApiCallId: string = ""
  intervalId: NodeJS.Timeout | null;
  readMessageApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        accountId:"",
        senderInfo: {
            senderImage: "",
            senderName: "",
            senderRole: ""
        },
        textMsg: "",
        chatInfo: [],
    };
    this.msgRef = React.createRef()
    this.intervalId = null;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      switch(apiRequestCallId) {
          case this.getChatInfoApiCallId:
              return this.handleChatMsgResponse(responseJson)
          case this.getSavedUserProfileApiCallId:
              return this.handleSavedUserProfileResponse(responseJson);
          case this.sendMsgApiCallId:
              return this.handleSendMessageResponse(responseJson);
          case this.readMessageApiCallId : 
              return this.handleReadMessageResponse(responseJson)
          default:
              break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
      this.getSavedUserProfile()
      if(this.props.receiverId) {
        this.getChatMessages(this.props.receiverId)
        this.intervalId = setInterval(() => {
            this.getChatMessages(this.props.receiverId)
         },2000)
      }
      
  }

  async componentWillUnmount(): Promise<void> {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
      if(prevState.chatInfo.length !== this.state.chatInfo.length) {
         this.scrollToBottom()
      }
  }


  handleSavedUserProfileResponse = (responseJson: any) => {
    if (responseJson && responseJson.user && responseJson.user.data) {
        const { full_name, profile_image_url, role } = responseJson.user.data.attributes;
        const senderDetails = {
            senderImage: truthyValue(profile_image_url.url),
            senderName: truthyValue(full_name),
            senderRole: truthyValue(role)
        };
      this.setState({ accountId: responseJson.user.data.id, senderInfo: senderDetails })
    } else {
      this.setState({ accountId: "" })
    }
  }

  scrollToBottom = () => {
        refFunction(this.msgRef)
        setTimeout(() => {
            this.readMessages()
        },2000)
  };

  handleSendMessageResponse = (responseJson: any) => {
    if (responseJson && responseJson.message && responseJson.message.data) {
        this.getChatMessages(this.props.receiverId)
        toast.success("Message sent successfully")
        this.setState({ textMsg : ""})
    } else {
       toast.error("Something went wrong. Please try again later")
    }
  }

  handleReadMessageResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
       this.getChatMessages(this.props.receiverId) 
    } 
  }

  handleChatMsgResponse = (responseJson: any) => {
    if(responseJson && responseJson.conversation && responseJson.conversation.data) {
        const { messages } = responseJson.conversation.data.attributes
        const unreadCount = truthyValue(responseJson.unread_messages_count) ;

        const chatMsgArr = messages.data.map((arrVal: any) => {
            return {
                    msgId: truthyValue(arrVal.attributes.id),
                    msgText: truthyValue(arrVal.attributes.body),
                    msgType: truthyValue(arrVal.attributes.type),
                    chatType: this.handleUserType(arrVal.attributes.account.id),
                    chatTime: moment(truthyValue(arrVal.attributes.created_at)).fromNow(),
                    userImage : truthyValue(arrVal.attributes.account.profile_image),

            }
        })

        this.setState({ chatInfo: chatMsgArr}, () => this.sendCount(unreadCount))

    }
  }

  sendCount = (count : number) => {
    this.props.updateCount(count)
  }

  getSavedUserProfile = () => {
      const header = {
          'token': localStorage.getItem("userToken")
      }

      this.getSavedUserProfileApiCallId = apiCalling({
          header: JSON.stringify(header),
          method: configJSON.getApiMethod,
          endPoint: configJSON.getSavedUserProfileApiEndPoint,
      })
  }

  handleTextMsgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     this.setState({ textMsg : event.target.value})
  }

  getChatMessages = (receiverId : string) => {
      const header = {
          'token': localStorage.getItem("userToken")
      }

      this.getChatInfoApiCallId = apiCalling({
          header: JSON.stringify(header),
          method: configJSON.getApiMethod,
          endPoint: `${configJSON.getChatInfoApiEndpoint}/${receiverId}`,
      })
  }

    sendMessages = () => {
        if (this.state.textMsg.trim() === "") {
            toast.error("Please enter message")
            return false
        }


        const header = {
            'token': localStorage.getItem("userToken")
        }

        const formData = new FormData()

        formData.append("id", this.props.receiverId)
        formData.append("message[type]", "TextMessage")
        formData.append("message[body]", this.state.textMsg)

        this.sendMsgApiCallId = apiCalling({
            header: JSON.stringify(header),
            method: configJSON.postApiMethod,
            endPoint: configJSON.sendMsgChatApiEndPoint,
            body: formData
        })
    }

    readMessages = () => {

      const header = {
          'token': localStorage.getItem("userToken")
      }

      const formData = new FormData()

      formData.append("id", this.props.receiverId)
     
      this.readMessageApiCallId = apiCalling({
          header: JSON.stringify(header),
          method: configJSON.putApiMethod,
          endPoint: configJSON.readChatMsgApiEndPoint,
          body: formData
      })
  }

    handleChatTypeCondition = (userType : string) => {
        return userType === "sender" ? true : false
    }

    handleUserType = (receiverId : string) => {
        return receiverId.toString() === this.state.accountId.toString() ? "sender" : "receiver"
    }

    handleEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        this.sendMessages()
      }
    };

  // Customizable Area End
}

// Customizable Area End