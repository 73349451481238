// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Typography,
    Button,
    Radio
} from "@material-ui/core"

import PreTripCommonSidebar from "../../../components/src/PreTripCommonSidebar.web";
import {  tipsIcon, checkedIcon, radioIcon } from "./assets";
import { congratsIcon } from "../../../blocks/catalogue/src/assets";
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { AntSwitch } from "../../../components/src/FilterModal.web";
import Loader from "../../../components/src/Loader.web";
import AppHeader from "../../../components/src/AppHeader.web";
import Footer from "../../../components/src/Footer.web";


import PreTripCheckoutController, {
    Props,
  } from "./PreTripCheckoutController";


class PreTripCheckout extends PreTripCheckoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderCongratsWrapper  =  () => {
    return (
        this.state.checkoutCompleted &&
        <div>
            <AppHeader navigation={this.props.navigation} activeLink="Reservation" />

            <CongratsWrapper>
                <Box textAlign={"center"}>
                    <img src={congratsIcon} />
                    <Typography className="congratsText">Congratulations</Typography>
                    <Typography className="subText">You are all set to go for your trip. If you have any concern or questions,</Typography>
                    <Typography className="subText">please do not hesitate to reach out.</Typography>

                    <Box textAlign={"center"} style={{ margin: "30px 345px", background: "#EAECF0", padding: "15px", borderRadius: "12px" }}>
                        <Typography className="subText">Just wanted to make sure,</Typography>
                        <Typography className="keyText">Have you received the car key?</Typography>
                        <Box style={{ display: "flex", gap: 5, alignItems: "center", justifyContent: "center" }}>
                            <Typography className="switchText">No</Typography>
                            <AntSwitch defaultChecked />
                            <Typography className="switchText">Yes</Typography>
                        </Box>
                    </Box>
                    <Button className="startTripBtn" data-test-id="navToHome" onClick={this.startTrip}> Start your trip</Button>
                </Box>

            </CongratsWrapper>
            <Footer />
        </div>
    )
  }

  renderImageBox = () => {
      return (
          <Box className="carBox">
              <div className="addphotos">
                  {this.state.images.map((image, index) => (
                      <div key={index} style={{ border: '1px dashed #D0D5DD', position: 'relative', height: 130, borderRadius: 12, margin: "8px 0" }}>
                          {image.preview ? (
                              <img src={image.preview} alt={`Uploaded ${index}`} style={{ width: '100%', height: '100%', borderRadius: 12 }} />
                          ) : (
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                  <input
                                      type="file"
                                      accept="image/*"
                                      data-test-id="imageGrid"
                                      onChange={(event) => this.handleImageUpload(event, index)}
                                      style={{
                                          position: 'absolute',
                                          width: '100%',
                                          height: '100%',
                                          opacity: 0,
                                          cursor: 'pointer',
                                      }}
                                  />
                                  <div style={{ textAlign: 'center', display: "flex", gap: 5, alignItems: "center" }}>
                                      <Box>
                                          <AddIcon className="colorText" />
                                      </Box>
                                      <Typography className="addPhotoText"> Add Photo</Typography>
                                  </div>
                              </div>
                          )}
                      </div>
                  ))}
              </div>
          </Box>
      )
  }

  render() {
    // Customizable Area Start
    
    return (
        <div>
            {!this.state.checkoutCompleted &&
                <Wrapper>
                    <PreTripCommonSidebar sveCheckoutImages={this.saveCheckinImages} id="checkoutflow" pageTitle="checkOut" navigation={this.props.navigation}>
                        {this.state.loading ? <Loader loading={true} /> :
                            <Box>
                                <Box className="breadcrumbsBox">
                                    <Typography className="breadcrumbText" onClick={this.navigateToReservationPage} data-test-id="bredcrumbOne">Reservations</Typography>
                                    <ArrowForwardIosIcon style={{ height: 14, color: "#64748B" }} fontSize="small" />
                                    <Typography className="breadcrumbText" onClick={this.navigateToPreviousPage} data-test-id="bredcrumbTwo">Home</Typography>
                                    <ArrowForwardIosIcon style={{ height: 14, color: "#64748B" }} fontSize="small" />
                                    <Typography className="breadcrumbText"><span className="colorText"> Checkout</span></Typography>
                                </Box>
                                <Box>
                                    <Typography className="addPhotosText"> Add Photos </Typography>
                                    <Typography className="addPhotossubText">Please take up to 9 photos to document the car's condition. Include interior and exterior. The Hosts photos will show up here also.</Typography>
                                </Box>

                                {this.renderImageBox()}

                                <Box className="tipsBox">
                                    <Box style={{ display: "flex", gap: 10, margin: "10px 0" }}>
                                        <img src={tipsIcon} />
                                        <Typography className="tipsText">Tips & Guide</Typography>
                                    </Box>
                                    <Box>
                                        <AddIcon style={{ color: "#22395D", height: 24, width: 24 }} />
                                    </Box>
                                </Box>


                                <Box>
                                    <Typography className="addPhotosText"> Visible Damage </Typography>

                                    <Box className="radioTextBox">
                                        <Typography className="damageText"> Is there any significant damage?</Typography>
                                        <Box style={{ display: "flex", gap: 10, margin: "10px 0" }}>
                                            <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                                                <Radio icon={<img src={radioIcon} />} checkedIcon={<img src={checkedIcon} />} onChange={this.handleChangeforDamageKey} value={"true"} checked={this.state.damageValue === "true"} name="visibleDamage" />
                                                <Typography className="radioText"> Yes</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                                                <Radio icon={<img src={radioIcon} />} checkedIcon={<img src={checkedIcon} />} value={"false"} onChange={this.handleChangeforDamageKey} checked={this.state.damageValue === "false"} name="visibleDamage" />
                                                <Typography className="radioText"> No</Typography>
                                            </Box>
                                        </Box>
                                    </Box>

                                </Box>

                            </Box>
                        }
                    </PreTripCommonSidebar>
                </Wrapper>
            }
            
            {this.renderCongratsWrapper()}
        </div>
    );
    // Customizable Area End
  }
}

export default PreTripCheckout;

const Wrapper = styled(Box)({
    "& .addphotos": {
        display: 'grid', 
        gridTemplateColumns: 'repeat(3, 1fr)', 
        gap: '15px',
        "@media(max-width: 420px)": {
            display: 'grid', 
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },
    "& .addPhotosText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        textAlign: 'left',
        color:"#23395D",
    },
    "& .addPhotossubText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        textAlign: 'left',
        color:"#475467",
        margin: "5px 0"
    },
    "& .addPhotoText" : {
        color : "#3173E1",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center'
    },
    "& .damageText" : {
        color : "#23395D",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        margin: "10px 0"
    },
    "& .radioText" : {
        color : "#475467",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        
    },
    "& .tipsBox" : {
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 20px",
        border: "1px solid #EAECF0",
        borderRadius: "12px",
        alignItems: "center",
        margin: "25px 0"
    },
    "& .radioTextBox" : {
        padding: "10px 20px",
        border: "1px solid #EAECF0",
        borderRadius: "12px",
        alignItems: "center",
        margin: "10px 0"
    },
    "& .tipsText" : {
        color : "#23395D",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        margin: "10px 0"
    },
    "& .bgGrayCheckOut" : {
        background: "#EAECF0",
        padding: "20px",
      }, 
      "& .colorText" : {
        color: "#3173E1",
      },
      "& .boxDivCheckOut" : {
        padding: "20px",
        background: "white",
        borderRadius: 12,
        marginBottom: "20px"
      }, 
      "& .cancelBtn" : {
        height: "40px",
        border: "1px solid #D0D5DD",
        textTransform: "none",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        borderRadius: 30,
        color:"#475467",
        textAlign: 'left',
        background: "white",
        width: "40%"
      },
      "& .inputFieldsCheckOut" : {
        height : "40px",
        borderRadius: "30px",
        padding: "10px 14px",
      },
      "& .MuiOutlinedInput-input" : {
        padding: 0
      },
      "& .summaryTextCheckOut" : {
        ontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#475467'
      },
      "& .labelTextCheckOut" :{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 600,
        textAlign: 'left',
        color: '#667085'
      },
      "& .priceBoxCheckOut" : {
        display: "flex",
        justifyContent: "space-between",
        margin: "10px 0"
      },
      "& .headerOneTextCheckOut" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        textAlign: 'left',
        color: '#344054',
        paddingBottom: "5px"
      },
      "& .headerTwoTextCheckOut" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 400,
        fontSize: '12px',
        textAlign: 'left',
        color: '#667085'
      },
      "& .breadcrumbsBox" : {
         display: "flex",
         gap: "10px",
         marginBottom: "30px",
         alignItems : "center"
      },
      "& .breadcrumbText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '14px',
        textAlign: 'left',
        color: '#64748B',
        cursor: "pointer"
      }
})

const CongratsWrapper = styled(Box)({
maxWidth: "1220px",
margin: "40px auto",
"& .congratsText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '24px',
    fontWeight: 600,
    color: "#23395D",
    marginTop: "25px",
},
"& .subText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: "#475467",
},
"& .keyText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontWeight: 500,
    color: "#23395D",
},
"& .switchText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    color: "#475467",
},
"& .startTripBtn" : {
    height: "40px",
    fontFamily: 'Plus Jakarta Sans',
    width: "20%",
    borderRadius: 30,
    fontWeight: 600,
    fontSize: '14px',
    textTransform: "none",
    textAlign: 'left',
    color: "white",
    background: "#3173E1"
}
})

// Customizable Area End