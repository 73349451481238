// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Grid,
    Typography,
    OutlinedInput,
    Button,
    Divider,
} from "@material-ui/core"

import AppHeader from "../../blocks/notifications/src/TopHeader.web";
import Footer from "./Footer.web";
import Loader from "./Loader.web";
import CustomStepper from "./CustomStepper.web";

import { calenderIcon, editPenIcon, starIcon } from "../../blocks/reservations/src/assets";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


import PostTripReservationFlowController, {
    Props,
  } from "../../blocks/reservations/src/PostTripReservationFlowController";


class PostTripCommonSidebar extends PostTripReservationFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  handleHeaderPart = (headerVal : string) => {
    const validHeaders = ["renterIssue", "postCheckOut", "ReviewScreen"];
    return validHeaders.includes(headerVal);
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
        <AppHeader activeLink="Reservation" navigation={this.props.navigation} />

            {this.state.loading ? <Loader loading /> :
                <Wrapper>
                    <Grid container spacing={3}>
                        <Grid item md={8}>
                            <Button
                              startIcon={<ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />}
                              data-test-id="backBtn"
                              className="backButton"
                              onClick={this.navToBack}
                            >
                              Back
                            </Button>

                            <Typography className="bookedHeaderText">
                              Booked Trip
                            </Typography>

                            <Box style={{ display: "flex", gap: 20, marginBottom: "30px" }}>
                              <img src={this.state.catalogeInfo.catalougeImg} style={{ width: 132, height: 114, borderRadius: "12px" }} />
                              <Box>
                                <Typography className="catalogeName">{this.state.catalogeInfo.catalougeName}</Typography>
                                <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                                  <img src={starIcon} />
                                  <Typography className="reviewText">  {this.state.catalogeInfo.rating} <span> {this.state.catalogeInfo.reviews} reviews</span></Typography>
                                </div>

                              </Box>
                            </Box>
                            <Box>
                                {this.props.children}
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Box className="bgGray">
                              {this.props.pageTitle && this.handleHeaderPart(this.props.pageTitle) ?
                                <Box className="boxDiv">
                                  <Typography className="startStripText" style={{ textAlign: "center" }}>Trip ending</Typography>
                                  <Box style={{ display: "flex", gap: 10 }}>
                                    <Button fullWidth className="modifyBtn" startIcon={<img src={editPenIcon} />}>Modify Trip</Button>
                                  </Box>
                                </Box>
                                :
                                <Box className="boxDiv">
                                  <Typography className="startStripText">Your trip ends soon : <span className="colorText">{this.state.tripEndTime}</span></Typography>
                                  <Box style={{ display: "flex", gap: 10 }}>
                                    <Button fullWidth className="modifyBtn" startIcon={<img src={editPenIcon} />}>Modify Trip</Button>
                                    <Button fullWidth data-test-id="checkOutBtn" className="checkOutBtn">End Trip</Button>
                                  </Box>
                                </Box>
                              }
                                
                                <Box className="boxDiv">
                                    <Typography className="summaryText">Reservation Summary</Typography>
                                    <Divider style={{ margin: "10px 0" }} />

                                    <Box style={{ margin: "15px 0" }}>
                                        <Typography className="labelText">Pick-up Location</Typography>
                                        <OutlinedInput readOnly disabled fullWidth className="inputFields" value={this.state.homeReservationInfo.pickUpLocation} />
                                    </Box>

                                    <Box style={{ margin: "15px 0" }}>
                                        <Typography className="labelText">{this.handleCatalougesTypes() ? "Pick-up" : "Check-in"}</Typography>
                                        <OutlinedInput readOnly disabled fullWidth endAdornment={<img src={calenderIcon} />} className="inputFields" value={this.state.homeReservationInfo.checkIn} />
                                    </Box>

                                    <Box style={{ margin: "15px 0" }}>
                                        <Typography className="labelText">{this.handleCatalougesTypes() ? "Drop-off" : "Check-out"} </Typography>
                                        <OutlinedInput readOnly disabled fullWidth className="inputFields" endAdornment={<img src={calenderIcon} />} value={this.state.homeReservationInfo.checkOut} />
                                    </Box>

                                      {this.handleCatalougesTypes() ?
                                        <Box style={{ margin: "15px 0" }}>
                                          <Typography className="labelText">Drop-off Location</Typography>
                                          <OutlinedInput readOnly disabled fullWidth className="inputFields" value={this.state.homeReservationInfo.pickUpLocation} />
                                        </Box>
                                        :
                                        <Box style={{ margin: "15px 0" }}>
                                          <Typography className="labelText">Guest</Typography>
                                          <OutlinedInput readOnly disabled fullWidth className="inputFields" value={this.state.homeReservationInfo.guestCount} />
                                        </Box>
                                      }
                                </Box>

                                  {this.handleCatalougesTypes() ?

                                    <Box className="boxDiv">
                                      <Typography className="summaryText">Pricing Breakdown</Typography>
                                      <Divider style={{ margin: "10px 0" }} />

                                        {this.state.pricingBreakDown.map((price, index) =>
                                          <Box className="priceBox" key={index}>
                                            <Box>
                                              <Typography className="headerOneText">{price.label}</Typography>
                                              <Typography className="headerTwoText">{price.details} <span className="colorText"> {price.subDetails} </span></Typography>
                                            </Box>
                                            <Box>
                                              <Typography className="headerOneText">{price.amount}</Typography>
                                            </Box>
                                          </Box>
                                        )}

                                      <Divider style={{ margin: "10px 0" }} />
                                      <Box className="priceBox">
                                        <Box>
                                          <Typography className="headerOneText">Total Price Due</Typography>
                                        </Box>
                                        <Box>
                                          <Typography className="headerOneText">{this.state.totalTripAmount}</Typography>
                                        </Box>
                                      </Box>
                                      <Box className="headerTwoText">
                                        *Your total rent amount will be calculated depending on the pick-up and drop-off dates.
                                      </Box>
                                    </Box>
                                    :
                                    <Box className="boxDiv">
                                      <Typography className="summaryText">Trip cost breakdown</Typography>
                                      <Divider style={{ margin: "10px 0" }} />

                                      <CustomStepper steps={this.state.pricingBreakDown} />

                                      <Divider style={{ margin: "10px 0" }} />
                                      <Box className="priceBox" style={{ alignItems: "center" }}>
                                        <Typography className="totalPriceText">Total(USD)</Typography>
                                        <Typography className="totalPriceValue">{this.state.totalTripAmount}</Typography>
                                      </Box>
                                    </Box>
                                  }
                                

                                <Box className="boxDiv">
                                    <Box style={{ display: "flex", gap: 10 }}>
                                        <img src={this.state.hostInfo.hostImg} style={{ width: 58, height: 58, borderRadius: '100%' }} alt="" />
                                        <Box>
                                            <Typography>
                                                {this.state.hostInfo.hostName}
                                            </Typography>
                                            <Typography> {this.state.hostInfo.hostTrips} trips <span style={{ marginLeft: 5 }}> Host since {this.state.hostInfo.hostDuration}</span></Typography>
                                        </Box>
                                    </Box>

                                    <Box style={{ display: "flex", gap: 10, margin: "10px 0" }}>
                                        <Button fullWidth color="primary" variant="outlined" style={{ textTransform: "none", borderRadius: "12px" }}>View Profile</Button>
                                        <Button fullWidth variant="outlined" style={{ textTransform: "none", borderRadius: "12px" }}>Contact</Button>

                                    </Box>

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Wrapper>
            }

         <Footer />

      </div>
    );
    // Customizable Area End
  }
}

export default PostTripCommonSidebar;

const Wrapper  = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .backButton" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      lineHeight: '20px',
      textTransform: "none",
      fontWeight: 500,
      textAlign: 'left',
      color: '#667085',
      background: "white",
    },
    "& .bgGray": {
        background: "#EAECF0",
        padding: "20px",
      },
      "& .startStripText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '28px',
        textAlign: 'left',
        color: "#23395D"
      },
      "& .colorText": {
        color: "#3173E1",
      },
      "& .boxDiv": {
        padding: "20px",
        background: "white",
        borderRadius: 12,
        marginBottom: "20px"
      },
      "& .modifyBtn": {
        height: "40px",
        border: "1px solid #D0D5DD",
        borderRadius: 30,
        marginTop: "15px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        textAlign: 'left',
        color: "#475467"
      },
      "& .checkOutBtn": {
        height: "40px",
        fontFamily: 'Plus Jakarta Sans',
        marginTop: "15px",
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        borderRadius: 30,
        textAlign: 'left',
        color: "white",
        background: "#C3240F"
      },
      "& .inputFields": {
        height: "40px",
        borderRadius: "30px",
        padding: "10px 14px",
        color : "#98A2B3"
      },
      "& .MuiOutlinedInput-input": {
        padding: 0
      },
      "& .summaryText": {
        ontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#475467'
      },
      "& .labelText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#667085'
      },
      "& .priceBox": {
        display: "flex",
        justifyContent: "space-between",
        margin: "10px 0"
      },
      "& .headerOneText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'left',
        color: '#344054',
        paddingBottom: "5px"
      },
      "& .headerTwoText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 400,
        fontSize: '12px',
        textAlign: 'left',
        color: '#667085'
      },
      "& .breadcrumbText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '14px',
        textAlign: 'left',
        color: '#64748B',
        cursor: "pointer"
      },
      "& .breadcrumbsBox": {
        display: "flex",
        gap: "10px",
        marginBottom: "30px",
        alignItems: "center"
      },
      "& .catalogeName": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
        textAlign: 'left',
        color: '#23395D'
      },
      "& .reviewText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
        color: '#1D2939'
      },
      "& .totalPriceText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
        color: '#23395D'
      },
      "& .totalPriceValue" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#23395D'
      },
      "& .bookedHeaderText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '30px',
        fontWeight: 600,
        textAlign: 'left',
        color: '#23395D',
        margin: "25px 0"
      }
})

// Customizable Area End