//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BASE_URL } from "../../../framework/src/config.js";
import { truthyValue } from "../../../components/src/asset";
import swal from "sweetalert";
interface Review {
  id: string;
  type: string;
  attributes: {
      id: number;
      catalogue_id: number;
      rating: number;
      comment: string;
      anonymity: boolean;
      reviewer_name: string;
      catalogue_name: string;
      profile_img: {
          url: string;
      };
      created_at: string;
      time_ago: string;
  };
}

interface ReviewsData {
  data: Review[];
}

interface ReviewObject {
  reviews: ReviewsData;
  average_rating: string;
  host_created: string;
  rating_1: number;
  rating_2: number;
  rating_3: number;
  rating_4: number;
  rating_5: number;
}

// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  productData: { name: string; price: number; description: string; images: { url: string }[] };
  rentalPrice: number | null,
  total_nightly_price:number |null,
  number_of_days:number |null,
  refundable_deposit: number | null,
  discount: number | null,
  totalPriceDue: number | null,
  rate:number | null, 
  isloading: boolean;
  focusedImageIndex: number;
  product_id: number,
  tabValue:number,
  currentDate:string,
  type:string,
  allReviews:ReviewObject,
  policyData:any;
  startDate: string | null;
  endDate: string | null;
  lat : string,
  long : string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = '';
  getReviewApiCallID:string="";
  getPoliciesApiCallID:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      isloading: false,
      productData: { name: "", price: 1, description: "", images: [{ url: "" }] },
      allReviews:[],
      policyData:[],
      totalPriceDue:0,
      rate:0,
      rentalPrice:0,
      total_nightly_price:0,
      number_of_days:0,
      refundable_deposit: 0,
      product_id: 0,
      tabValue:0,
      discount:0,
      focusedImageIndex: 1,
      type:"",
      currentDate:"",
      lat: "",
      long: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && apiRequestCallId === this.productApiItemCallId) {
        this.handleProductData(responseJson,errorReponse)
        console.log("popppppppp", responseJson)
      }
      if(apiRequestCallId &&  apiRequestCallId ===this.getReviewApiCallID)
      {
        this.handleAllReviews(responseJson);
      }
      if(apiRequestCallId &&  apiRequestCallId ===this.getPoliciesApiCallID)
      {
       this.handlePolicyData(responseJson);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

handleAllReviews=(responseJson:any)=>{
  if(responseJson){
    this.setState({allReviews:responseJson})
  }
};

handlePolicyData=(responseJson:any)=>{
  if(responseJson && responseJson.data){
    this.setState({policyData:responseJson.data})
  } else { 
     this.setState({ policyData : []})
  }
};

handleProductData=(responseJson:any,errorReponse:any)=>{ 
  if (responseJson) {
  this.setState({ productData: responseJson.catalogue, 
         lat : truthyValue(responseJson.catalogue.data.attributes.address.latitude),
         long: truthyValue(responseJson.catalogue.data.attributes.address.longitude)
        
        },()=>{
    this.getPricingBreakDown()
  });
} else {
  this.parseApiErrorResponse(errorReponse);
}}

  formattedDate = () => {
     let currentDate = new Date();
     let monthNames = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct","Nov", "Dec"];
    return (`${monthNames[currentDate.getMonth()]} ${currentDate.getDate()}th,${currentDate.getFullYear().toString().slice(2)}`)
  }

  onGoBack = () => {
    this.props.navigation.navigate("BuildingBlocks");
  };

  getProductDescriptionId=()=>{
    this.setState({product_id:parseInt(this.props.navigation.getParam("id")),type:this.props.navigation.getParam("type")},()=>{this.getPostData()});
  }

  getPricingBreakDown=()=>{
    let productData=this.state.productData;
    let total_nightly_price=parseInt(productData?.data.attributes.total_nightly_price ?? 0);
    let number_of_days=parseInt(productData?.meta.number_of_days ?? 0);
    let discount_rate =parseInt(productData?.meta.discount_rate ?? 0);
    let refundable_deposit= parseInt(productData?.meta.refundable_deposit ?? 0);
    let discount=(total_nightly_price*discount_rate)/100;
    let totalPriceDue= (total_nightly_price*number_of_days) - discount +refundable_deposit;
    this.setState({
      refundable_deposit:refundable_deposit,
      number_of_days:number_of_days,
      total_nightly_price:total_nightly_price,
      rate:discount_rate,rentalPrice:(total_nightly_price*number_of_days),discountPrice:discount
    },()=>{
      this.setState({totalPriceDue:totalPriceDue})
    });
  };

  getPostData= ()=> {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

 this.setState({currentDate:`${day}/${month}/${year}`});

    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}?id=${this.state.product_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiPostMethod
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify( {"end_date": `${day}/${month}/${year}`,
      "start_date": `${day}/${month}/${year}`})
    );

    this.productApiItemCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllReviews=()=>{
    const header = {
      "Content-Type": configJSON.productApiContentType,     
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReviewApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getReviewsEndPoints}?catalogue_id=${this.state.product_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllPolicies=()=>{
    const header = {
      "Content-Type": configJSON.productApiContentType,     
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPoliciesApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllPoliciesEndPoints}/${this.state.product_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleTabChange=(event: React.ChangeEvent<{}>,newValue: number)=>{
    this.setState({tabValue:newValue});
  }

  compareDates = (targetD, currentD) => {
    const target = new Date(targetD);
    const current = new Date(currentD);

    if (target >= current) {
      return true;
    } else {
      return false;
    }
  }

  handleStartDate = (date: React.ChangeEvent<HTMLInputElement>) => {
    const checkInDate = date.target.value;
    localStorage.setItem("startDate", checkInDate);
    
    this.setState((prevState) => ({
        ...prevState,
        startDate: checkInDate
    }));
  };


  handleEndDate = (date: React.ChangeEvent<HTMLInputElement>) => {
    const checkOutDate = date.target.value;
    localStorage.setItem("endDate", checkOutDate);
    
    this.setState((prevState) => ({
        ...prevState,
        endDate: checkOutDate
    }));
  };

  handleMinimumCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const currentDate = String(today.getDate()).padStart(2, '0');
    const formattedToday = `${yyyy}-${month}-${currentDate}`;

    return formattedToday
  };

  reserveCatalogueData = () => {
    const validDates = this.compareDates(this.state.startDate, this.state.endDate)

    if (this.state.startDate && this.state.endDate) {
      if (validDates) {
        swal("The drop off date must be greater than the pick up date.", "", "error")
        return false;
      } else {
        this.navToCheckoutPage()
      }
    } else {
      swal("Select a date range to continue.", "", "error")
      return false;
    }
  };

  navToCheckoutPage = () => {
    this.props.navigation.navigate("ProductCheckout", {
      "id":this.state.product_id,
      "type":this.state.type
    });
  }

  getTabLabel = () => {
    return this.state.type === "Car" ? "Car details" : "Room details"
  };
  // Customizable Area End
}
