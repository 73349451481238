// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Grid,
    Typography,
    OutlinedInput,
    Button,
    Divider,
    Dialog,
    IconButton
} from "@material-ui/core"

import AppHeader from "../../blocks/notifications/src/TopHeader.web";
import Footer from "./Footer.web";
import Loader from "./Loader.web";
import CustomStepper from "./CustomStepper.web";

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import { calenderIcon, editPenIcon, starIcon } from "../../blocks/reservations/src/assets";

import PreTripReservationFlowController, {
    Props,
  } from "../../blocks/reservations/src/PreTripReservationFlowController";


class PreTripCommonSidebar extends PreTripReservationFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
        <AppHeader activeLink="Reservation" navigation={this.props.navigation} />

            {this.state.loading ? <Loader loading /> :
                <Wrapper>
                    <Grid container spacing={3}>
                        <Grid item md={8}>
                            <Box>
                                {this.props.children}
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Box className="bgGray">
                                <Box className="boxDiv">
                                    <Typography className="startStripText">Your Trip Starts in : <span className="colorText">{this.state.tripStartTime}</span></Typography>
                                    {this.props.pageTitle && this.props.pageTitle === "checkOut" ?
                                      <Box style={{ display: "flex", gap: 10 }}>
                                        <Button fullWidth className="modifyBtn" startIcon={<img src={editPenIcon} />}>Modify Trip</Button>
                                        <Button fullWidth data-test-id="checkOutBtn" onClick={this.props.sveCheckoutImages} className="checkOutBtn">Start Checkin</Button>
                                      </Box> :
                                      <Box style={{ display: "flex", gap: 10 }}>
                                        <Button fullWidth className="modifyBtn" startIcon={<img src={editPenIcon} />}>Modify Trip</Button>
                                        <Button fullWidth data-test-id="checkOutBtn" className="checkOutBtn" onClick={this.openCheckOutModal}>Start Checkout</Button>
                                      </Box>
                                    }
                                </Box>
                                <Box className="boxDiv">
                                    <Typography className="summaryText">Reservation Summary</Typography>
                                    <Divider style={{ margin: "10px 0" }} />

                                    <Box style={{ margin: "15px 0" }}>
                                        <Typography className="labelText">Pick-up Location</Typography>
                                        <OutlinedInput readOnly disabled fullWidth className="inputFields" value={this.state.homeReservationSummary.pickUpLocation} />
                                    </Box>

                                    <Box style={{ margin: "15px 0" }}>
                                        <Typography className="labelText">Check-in</Typography>
                                        <OutlinedInput readOnly disabled fullWidth endAdornment={<img src={calenderIcon} />} className="inputFields" value={this.state.homeReservationSummary.checkIn} />
                                    </Box>

                                    <Box style={{ margin: "15px 0" }}>
                                        <Typography className="labelText">Check-out</Typography>
                                        <OutlinedInput readOnly disabled fullWidth className="inputFields" value={this.state.homeReservationSummary.checkOut} />
                                    </Box>

                                    <Box style={{ margin: "15px 0" }}>
                                        <Typography className="labelText">Guest</Typography>
                                        <OutlinedInput readOnly disabled fullWidth endAdornment={<img src={calenderIcon} />} className="inputFields" value={this.state.homeReservationSummary.guestCount} />
                                    </Box>

                                </Box>
                                <Box className="boxDiv">
                                    <Typography className="summaryText">Trip cost breakdown</Typography>
                                    <Divider style={{ margin: "10px 0" }} />

                                    <CustomStepper steps={this.state.pricingBreakDown} />

                                    <Divider style={{ margin: "10px 0" }} />
                                    <Box className="priceBox" style={{ alignItems : "center"}}>
                                            <Typography className="totalPriceText">Total(USD)</Typography>
                                            <Typography className="totalPriceValue">{this.state.totalTripPrice}</Typography>
                                    </Box>
                                </Box>

                                <Box className="boxDiv">
                                    <Box style={{ display: "flex", gap: 10 }}>
                                        <img src={this.state.hostDetails.hostImg} style={{ width: 58, height: 58, borderRadius: '100%' }} alt="" />
                                        <Box>
                                            <Typography>
                                                {this.state.hostDetails.hostName}
                                            </Typography>
                                            <Typography> {this.state.hostDetails.hostTrips} trips <span style={{ marginLeft: 5 }}> Host since {this.state.hostDetails.hostDuration}</span></Typography>
                                        </Box>
                                    </Box>

                                    <Box style={{ display: "flex", gap: 10, margin: "10px 0" }}>
                                        <Button fullWidth color="primary" variant="outlined" style={{ textTransform: "none", borderRadius: "12px" }}>View Profile</Button>
                                        <Button fullWidth variant="outlined" style={{ textTransform: "none", borderRadius: "12px" }}>Contact</Button>

                                    </Box>

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Wrapper>
            }


            <DialogWrapper
              open={this.state.checkOutModal}
              onClose={this.closeCheckOutModal}
              fullWidth
              data-test-id="closeModal"
              maxWidth="sm"
            >

              <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "10px" }}>
                <Typography className="startTripHeader">Start Reservation</Typography>
                <IconButton style={{ marginRight: 20 }} edge="end" color="inherit" data-test-id="closeIcon" onClick={this.closeCheckOutModal} aria-label="close">
                  <CloseIcon style={{ color: "#64748B" }} />
                </IconButton>
              </Box>
              <Box textAlign={"center"} style={{ margin: "20px 0" }}>
                <Typography className="startTripInfo">
                Your stay is starting soon. To start the reservation, please 
                </Typography>
                <Typography className="startTripInfo">
                follow the check-in steps.
                </Typography>
                <Button className="checkOutBtnModal" onClick={this.navigateToCheckout} data-test-id="navToCheckout">Start Checkout</Button>
              </Box>
            </DialogWrapper>
            

         <Footer />

      </div>
    );
    // Customizable Area End
  }
}

export default PreTripCommonSidebar;

const Wrapper  = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .bgGray": {
        background: "#EAECF0",
        padding: "20px",
      },
      "& .startStripText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '28px',
        textAlign: 'left',
        color: "#23395D"
      },
      "& .colorText": {
        color: "#3173E1",
      },
      "& .boxDiv": {
        padding: "20px",
        background: "white",
        borderRadius: 12,
        marginBottom: "20px"
      },
      "& .modifyBtn": {
        height: "40px",
        border: "1px solid #D0D5DD",
        borderRadius: 30,
        marginTop: "15px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        textAlign: 'left',
        color: "#475467"
      },
      "& .checkOutBtn": {
        height: "40px",
        fontFamily: 'Plus Jakarta Sans',
        marginTop: "15px",
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        borderRadius: 30,
        textAlign: 'left',
        color: "white",
        background: "#3173E1"
      },
      "& .inputFields": {
        height: "40px",
        borderRadius: "30px",
        padding: "10px 14px",
        color : "#98A2B3"
      },
      "& .MuiOutlinedInput-input": {
        padding: 0
      },
      "& .summaryText": {
        ontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#475467'
      },
      "& .labelText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'left',
        color: '#667085'
      },
      "& .priceBox": {
        display: "flex",
        justifyContent: "space-between",
        margin: "10px 0"
      },
      "& .headerOneText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'left',
        color: '#344054',
        paddingBottom: "5px"
      },
      "& .headerTwoText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 400,
        fontSize: '12px',
        textAlign: 'left',
        color: '#667085'
      },
      "& .breadcrumbText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '14px',
        textAlign: 'left',
        color: '#64748B',
        cursor: "pointer"
      },
      "& .breadcrumbsBox": {
        display: "flex",
        gap: "10px",
        marginBottom: "30px",
        alignItems: "center"
      },
      "& .catalogeName": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
        textAlign: 'left',
        color: '#23395D'
      },
      "& .reviewText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
        color: '#1D2939'
      },
      "& .totalPriceText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
        color: '#23395D'
      },
      "& .totalPriceValue" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#23395D'
      }
})

const DialogWrapper = styled(Dialog)({
  "& .startTripHeader": {
    color: "#23395D",
    fontFamily: 'Plus Jakarta Sans',
    marginLeft: "200px",
    fontSize: '24px',
    fontWeight: 600,
  },
  "& .startTripInfo": {
    color: "#475467",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 500,
  },
  "& .checkOutBtnModal": {
    height: "40px",
    fontFamily: 'Plus Jakarta Sans',
    marginTop: "30px",
    fontSize: '14px',
    fontWeight: 600,
    textTransform: "none",
    borderRadius: 30,
    textAlign: 'left',
    color: "white",
    background: "#3173E1",
    width: "200px"
  },
})

// Customizable Area End