export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const editIcon = require("../assets/editIcon.png")
export const verifiedIcon = require("../assets/verifiedIcon.png")
export const userIcon = require("../assets/userIcon.png")
export const pdfIcon = require("../assets/pdfIcon.png")
export const infoIcon = require("../assets/infoIcon.png")
export const visaIcon = require("../assets/visaIcon.png")
export const editBlueIcon = require("../assets/editBlue.svg")
export const checkBoxIcon = require("../assets/checkBoxIcon.svg")
export const checkedIcon = require("../assets/checkedIcon.svg")
export const changePhotoIcon = require("../assets/changePhoto.svg")
export const editBlackIcon = require("../assets/editBlack.svg")
export const deleteGrayIcon = require("../assets/deleteGray.svg")
export const faqIcon = require("../assets/supportCenter/faqIcon.svg")
export const dollarIcon = require("../assets/supportCenter/dollarIcon.svg")
export const safetyIcon = require("../assets/supportCenter/safetyIcon.svg")
export const ticketIcon = require("../assets/supportCenter/ticketIcon.svg")
export const arrowIcon = require("../assets/supportCenter/arrowIcon.svg")
export const rightIcon = require("../assets/rightIcon.svg")
export const fileIcon = require("../assets/group_file.png")
export const carIcon = require("../assets/group_car.png")
export const homeIcon = require("../assets/group_home.png")






