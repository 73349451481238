// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "chatgpt";
exports.labelBodyText = "chatgpt Body";

exports.btnExampleTitle = "CLICK ME";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT";
exports.getChatGptMsgApiEndPoint="bx_block_privatechat/open_ai/gpt_questions"
exports.sendChatGptMsgApiEndPoint="bx_block_privatechat/open_ai/gpt_questions"

// Customizable Area End
