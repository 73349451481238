// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import { HostInfo, HomeReservationSummary, CatalogeSummary, MainInstructionHeader, SubInstructionHeader, InstructionData, PriceBreakDown } from "../../../components/src/TypeInterfaces.web";
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children? : any,
  pageTitle? : string,
  sveCheckoutImages? : () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  userId : string,
  loading: boolean,
  bookedSlotId: string,
  catalogueId : string,
  conversationId: string,
  hostDetails : HostInfo,
  homeReservationSummary : HomeReservationSummary,
  tripStartTime : string,
  catalogeDetails : CatalogeSummary,
  tabValue : number,
  checkInArray : InstructionData[],
  houseRuleArray : InstructionData[],
  locationArray : InstructionData[],
  accessMethodArray : InstructionData[],
  parkingArray : InstructionData[],
  wifiArray : InstructionData[],
  otherPropertyArray : InstructionData[],
  localRecommandedArray : InstructionData[],
  pricingBreakDown : PriceBreakDown[],
  totalTripPrice : string
  checkOutModal : boolean 
  catalougesImages : any[],
  unreadMsgCount : number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PreTripReservationFlowController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBookedSlotApiCallId : string = ""
  getTripCostBreakdownApiCallId : string = ""
  getTripInfoApiCallId : string = ""
  getChatMsgApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        userId: "",
        loading: true,
        bookedSlotId : "",
        catalogueId : "",
        conversationId : "",
        hostDetails : this.initialHostInfo,
        homeReservationSummary : this.initialHomeSummary,
        catalogeDetails : this.initialCatalougeInfo,
        tripStartTime : "",
        tabValue : 0,
        checkInArray : [],
        houseRuleArray : [],
        locationArray: [],
        accessMethodArray: [],
        parkingArray: [],
        wifiArray: [],
        otherPropertyArray: [],
        localRecommandedArray: [],
        pricingBreakDown : this.initialPriceSteps,
        totalTripPrice : "$0",
        checkOutModal : false,
        catalougesImages : [],
        unreadMsgCount : 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

        switch (apiRequestCallId) {
            case this.getBookedSlotApiCallId:
                return this.handleResponseForGetBookedSlotApi(responseJson)
            case this.getTripInfoApiCallId:
                return this.handleGetTripInfoApiResponse(responseJson)
            case this.getTripCostBreakdownApiCallId:
                return this.handleTripCostBreakDownResponse(responseJson)
            case this.getChatMsgApiCallId:
                return this.handleResponseForGetMessagegChatCountApi(responseJson)
        }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    const bookedSlotId = this.props.navigation.getParam("bookSlotId") 
    this.getBookedSlotData(bookedSlotId)
    this.setState({ bookedSlotId : bookedSlotId}) 
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
      if(prevState.catalogueId !== this.state.catalogueId) {
         this.getTripInfoData()
      }

      if(prevState.bookedSlotId !== this.state.bookedSlotId) {
        this.getTripCostBreakdownData()
      }

      if(prevState.conversationId !== this.state.conversationId) {
         this.getChatCount()
      }
  }

  handleResponseForGetBookedSlotApi = (responseJson: any) => {

      if(responseJson && responseJson.catalogue && responseJson.catalogue.data) {
          const modifiedObj = responseJson.catalogue.data.attributes
          const reservationStartDate = moment(responseJson.catalogue.data.attributes.start_time).format('MMM Do, YY, h:mm A')
          const reservationEndDate = moment(responseJson.catalogue.data.attributes.end_time).format('MMM Do, YY, h:mm A')
          const tripStartTime = this.handleStartTripTime(modifiedObj.start_time)
          const conversationId = responseJson.catalogue.data.attributes.conversation && truthyValue(responseJson.catalogue.data.attributes.conversation.id)

      const hostDetails = {
        hostName : truthyValue(modifiedObj.hosted_by.data.attributes.hosted_by.full_name),
        hostImg :truthyValue(modifiedObj.hosted_by.data.attributes.host_profile_image.url),
        hostDuration:truthyValue(modifiedObj.hosted_by.data.attributes.hosted_by.joined),
        hostTrips :truthyValue(modifiedObj.hosted_by.data.attributes.hosted_by.host_trip_completed)
      }

      const homeReservationSummary = {
        checkIn : reservationStartDate,
        checkOut : reservationEndDate,
        guestCount : "2",
        pickUpLocation : modifiedObj.address.length > 0 ? `${truthyValue(modifiedObj.address[0].address)},${truthyValue(modifiedObj.address[0].country)}` : ""
      }

      const catalogeDetails = {
          catalougeId: truthyValue(modifiedObj.catalogue_id),
          catalougeName: truthyValue(modifiedObj.catalogue_name),
          catalougeImg: this.handleCatalougeImageUrl(responseJson.catalogue.data),
          reviews:truthyValue(modifiedObj.rating.reviews_count),
          rating: truthyValue(modifiedObj.rating.average_rating)
      }

      const catalogueId = truthyValue(modifiedObj.catalogue_id)

      const catalougesImages = truthyValue(modifiedObj.images)

      this.setState({ hostDetails, homeReservationSummary, tripStartTime, catalogeDetails, loading : false, catalogueId, conversationId, catalougesImages})
    } else {
      this.setState({ loading: false})
    }
  }

  handleResponseForGetMessagegChatCountApi = (responseJson: any) => {
    if (responseJson && responseJson.unread_messages_count) {
      const count = truthyValue(responseJson.unread_messages_count);
      this.setState({ unreadMsgCount: count })
    } else {
      this.setState({ unreadMsgCount: 0 })
    }
  }

  handleGetTripInfoApiResponse = (responseJson : any) => {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {

        const checkInArray = this.handleModifiedArrayFromResponse(responseJson.data, this.subInstructionHeader.checkInDateTimeHeader)
        const houseRulesArray = this.handleModifiedArrayFromResponse(responseJson.data,this.subInstructionHeader.houseRuleHeader)
        const locationDetailsArray = this.handleModifiedArrayFromResponse(responseJson.data, this.subInstructionHeader.locationDetailsHeader)
        const accessMethodDetailsArray = this.handleModifiedArrayFromResponse(responseJson.data, this.subInstructionHeader.accessMethodHeader)
        const parkingDetailsArray = this.handleModifiedArrayFromResponse(responseJson.data, this.subInstructionHeader.partkingInstructionHeader)
        const wifiDetailsArray = this.handleModifiedArrayFromResponse(responseJson.data, this.subInstructionHeader.wifiInstructionHeader)
        const propertyDetailsArray = this.handleModifiedArrayFromResponse(responseJson.data, this.subInstructionHeader.otherPropertyDetailsHeader)
        const recomandedDetailsArray = this.handleModifiedArrayFromResponse(responseJson.data, this.subInstructionHeader.localRecommandedHeader)

        const filteredCheckInArray = checkInArray.map((item: any) => {
            return {
                instructionID: item.id,
                cardTitle: `${truthyValue(item.attributes.title)} at ${truthyValue(item.attributes.description)}`
            }
        })

        const filteredHouseRulesArray = houseRulesArray.map((item: any) => {
            return {
                instructionID: item.id,
                cardTitle: truthyValue(item.attributes.description)
            }
        })

        const filteredLocationDetailsArray = locationDetailsArray.map((item: any) => {
            return {
                instructionID: item.id,
                cardTitle: truthyValue(item.attributes.description)
            }
        })

        const accessMethodDetails =  accessMethodDetailsArray.map((item: any) => {
            return {
                instructionID: item.id,
                cardTitle: truthyValue(item.attributes.description)
            }
        })

        const parkingDetails =  parkingDetailsArray.map((item: any) => {
            return {
                instructionID: item.id,
                cardTitle: truthyValue(item.attributes.description)
            }
        })

        const wifiDetails =  wifiDetailsArray.map((item: any) => {
            return {
                instructionID: item.id,
                cardTitle: truthyValue(item.attributes.description)
            }
        })

        const propertyDetails =  propertyDetailsArray.map((item: any) => {
            return {
                instructionID: item.id,
                cardTitle: truthyValue(item.attributes.description)
            }
        })

        const recomandedDetails =  recomandedDetailsArray.map((item: any) => {
            return {
                instructionID: item.id,
                cardTitle: truthyValue(item.attributes.description)
            }
        })

        this.setState({ 
            checkInArray : filteredCheckInArray,
            houseRuleArray : filteredHouseRulesArray,
            locationArray : filteredLocationDetailsArray,
            accessMethodArray : accessMethodDetails,
            parkingArray : parkingDetails,
            wifiArray : wifiDetails,
            otherPropertyArray : propertyDetails,
            localRecommandedArray : recomandedDetails
        })

    } else {
        this.setState({ loading : false})
    }
  }

  handleTripCostBreakDownResponse = (responseJson: any) => {
    if(responseJson && responseJson.trip_cost_and_trip_cost_break_down) {
        const receivedData = responseJson.trip_cost_and_trip_cost_break_down

        const filteredPriceData: PriceBreakDown[] = [
          {
            label: 'Rental Price',
            details: `$${truthyValue(receivedData.per_day)} / Night`,
            subDetails: `(x${truthyValue(receivedData.number_of_days)} Night)`,
            amount: `$${truthyValue(receivedData.rental_price)}`,
          },
          {
            label: `Discount`,
            details: 'Extended Trip Discount',
            subDetails: `(${truthyValue(receivedData.discounts_day)}%)`,
            amount: `$${truthyValue(receivedData.discounts_value)}`,
          },
          {
            label: 'Trip Payment',
            details: `Refundable Deposit $${truthyValue(receivedData.refundable_deposit)}`,
            subDetails: "",
            amount: `$${truthyValue(receivedData.total_price_due)}`,
          },
        ]; 

        const totalPrice =` $${truthyValue(receivedData.total_price_due)}`

        this.setState({ pricingBreakDown : filteredPriceData, totalTripPrice : totalPrice})
    } else {
      this.setState({ pricingBreakDown : this.initialPriceSteps})
    }
  }

  getChatCount = () => {
    const header = {
      'token': localStorage.getItem("userToken")
    }

    this.getChatMsgApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.getChatCountApiEndpoint}/${this.state.conversationId}`,
      method: configJSON.apiTypeGet,
    })
  }

  getBookedSlotData = ( bookedSlotId : number) => {
    const header = {
        'token': localStorage.getItem("userToken")
    }

    this.getBookedSlotApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: `${configJSON.getReservationDetailApiEndPoint}/${bookedSlotId}`,
        method: configJSON.apiTypeGet
    })

  }

  getTripInfoData = () => {
    const header = {
        'token': localStorage.getItem("userToken")
    }

    this.getTripInfoApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.getInstructionApiEndpoint(this.state.catalogueId),
        method: configJSON.apiTypeGet
    })

  }

  getTripCostBreakdownData = () => {
    const header = {
        'token': localStorage.getItem("userToken")
    }

    this.getTripCostBreakdownApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: `${configJSON.getPriceBreakdownApiEndPoint}?id=${this.state.bookedSlotId}`,
        method: configJSON.apiTypeGet
    })

  }

  initialHostInfo : HostInfo = {
    hostName : "",
    hostImg : "",
    hostDuration: "",
    hostTrips : ""
  }

  initialHomeSummary : HomeReservationSummary = {
    checkIn : "",
    checkOut : "",
    guestCount : "",
    pickUpLocation : ""
  }

  initialCatalougeInfo : CatalogeSummary = {
    catalougeId : "",
    catalougeName : "",
    catalougeImg : "",
    reviews : "",
    rating : ""
  }

  initialPriceSteps: PriceBreakDown[] = [
    {
      label: 'Rental Price',
      details: '$1,400 / Night',
      subDetails: "(x3 Night)",
      amount: '$0',
    },
    {
      label: '3+ Day Discount',
      details: 'Extended Trip Discount',
      subDetails: "(5%)",
      amount: '$0',
    },
    {
      label: 'Trip Payment',
      details: 'Refundable Deposit $500',
      subDetails: "",
      amount: '$0',
    },
  ];

    mainInstructionHeader: MainInstructionHeader = {
        checkInInstructionHeader: "Check-in Instructions",
        housePoliciyHeader: "House Policies"
    }

    subInstructionHeader: SubInstructionHeader = {
        checkInDateTimeHeader: "Check-In Date and Time",
        houseRuleHeader: "House Rules",
        locationDetailsHeader: "Location Details",
        accessMethodHeader: "Access Method",
        partkingInstructionHeader: "Parking Instructions",
        wifiInstructionHeader: "Wi-Fi Connection Details",
        otherPropertyDetailsHeader: "Other Property Details",
        localRecommandedHeader: "Local Recommendations"
    } 

  navigateToReservationPage = () => {
    this.props.navigation.navigate("Reservation")
  }

  handleTabChanges = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ tabValue: value})
  }

  handleStartTripTime = (startTime: string) => {
        const currentTime = moment();
        const startMoment = moment(startTime);
        const difference = startMoment.diff(currentTime, 'hours');
        const days = Math.floor(difference / 24);

        if (difference < 48) {
            return `${difference} Hours.`
        } else {
            return `${days} Days`
        }
  }

  handleCatalougeImageUrl = (item : any) => {
        // imageUrl
        let imgUrl : string = ""
        if(item.attributes && item.attributes.images.length > 0 && item.attributes.images[0].url ) {
          imgUrl = item.attributes.images[0].url
        }
    
        return imgUrl 
  }

  handleModifiedArrayFromResponse = (array : any, filterVal : string) => {
    const modifiedArr = array
        .flatMap((instruction: any) => instruction.attributes.sub_instructions.data)
        .filter((subInstruction: any) => subInstruction.attributes.name === filterVal)
        .flatMap((subInstruction: any) => subInstruction.attributes.sections.data);

   return modifiedArr
}

    closeCheckOutModal = () => {
        this.setState({ checkOutModal: false })
    }

    openCheckOutModal = () => {
        this.setState({ checkOutModal: true })
    }

    navigateToCheckout = () => {
        this.props.navigation.navigate("PreTripFlowCheckout", { bookSlotId : this.state.bookedSlotId})
    }

    setMsgCount = (count : number) => {
      this.setState({ unreadMsgCount : count })
    }

  // Customizable Area End
}

// Customizable Area End