// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import { toast } from "react-toastify"
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface UserData {
    fullName : string,
    joined: string,
    profileImg : string
}

interface S {
  // Customizable Area Start  
  loading : boolean,
  verificationStatus : string,
  verificationSteps : number,
  userData : UserData,
  licenceFrontDoc : File | null,
  licenceBackDoc : File | null,
  selfieDoc : File | null,
  reUploadedDoc : File | null,
  isDisplayed : boolean,
  licenceExpiryDate : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ApprovalStatusController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  licenceFrontImgRef: React.RefObject<HTMLInputElement>
  licenceBackImgRef: React.RefObject<HTMLInputElement>
  selfieImgRef: React.RefObject<HTMLInputElement>
  getUserApprovalApiCallId : string = ""
  saveUserApprovalApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      loading: true,
      verificationStatus: this.allVerificationStatus.not_verified,
      verificationSteps: 1,
      userData : {
        fullName : "",
        joined: "",
        profileImg: ""
      },
      selfieDoc : null,
      licenceFrontDoc : null,
      licenceBackDoc : null,
      reUploadedDoc: null,
      isDisplayed : true,
      licenceExpiryDate : ""
    };
    this.licenceFrontImgRef = React.createRef()
    this.licenceBackImgRef = React.createRef()
    this.selfieImgRef = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getUserApprovalApiCallId:
          return this.handleUserAprrovalStatusResponse(responseJson)
        case this.saveUserApprovalApiCallId:
          return this.handleUserUpdateAprrovalStatusResponse(responseJson)
        default:
          break;
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  
  async componentDidMount(): Promise<void> {
      this.getUserApprovalStatusData()
  }

  getUserApprovalStatusData = () => {
    const header = {
        'token': localStorage.getItem("userToken")
    }

    this.getUserApprovalApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.userApprovalStatusApiEndPoint,
        method: configJSON.apiMethodTypeGet
    })
  }

  updateAccountVerification = () => {
    if (this.state.licenceFrontDoc === null || this.state.licenceBackDoc === null || this.state.selfieDoc == null) {
      toast.error("Please upload all documents to continue")
      return false
    }

    const header = {
      'token': localStorage.getItem("userToken")
    }

    this.setState({ loading: true })

    const formData = new FormData()

    formData.append("back_license_image", this.state.licenceBackDoc)
    formData.append("front_license_image", this.state.licenceFrontDoc)
    formData.append("face_image", this.state.selfieDoc)

    this.saveUserApprovalApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.userApprovalStatusApiEndPoint,
      method: configJSON.apiMethodTypePut,
      body: formData
    })


  }

  handleUserAprrovalStatusResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const responseObj = responseJson.data.attributes
      const verificationStatus = responseObj.status
      const expiresDate = this.handleExpiredValue(truthyValue(responseObj.expires_at))
      const userInfoObj = responseObj.account.data
      const userData = {
        fullName: truthyValue(userInfoObj.attributes.full_name),
        joined: truthyValue(userInfoObj.attributes.joined),
        profileImg: truthyValue(userInfoObj.attributes.profile_image_url.url),
      }

      this.setState({ userData, loading: false, verificationStatus, verificationSteps : this.dynamicVerificationSteps(verificationStatus), licenceExpiryDate : expiresDate })
    } else {
      this.setState({ loading: false })
    }
  }

  handleUserUpdateAprrovalStatusResponse = (responseJson: any) => {
    if (responseJson && responseJson.message) {
      toast.success("Documents uploaded successfully")
      this.goToStep2()
      this.setState({ loading: false, licenceBackDoc : null, licenceFrontDoc : null, selfieDoc : null })
    } else {
      this.setState({ loading: false })
    }
  }

   allVerificationStatus = {
     not_verified : "unverified",
     verified : "verified",
     in_progress : "pending",
     need_review : "expired_license",
     denied : "denied"
   }

  dynamicVerificationSteps = (value: string) => {
      switch (value) {
        case this.allVerificationStatus.not_verified:
          return 1
        case this.allVerificationStatus.in_progress:
          return 2
        case this.allVerificationStatus.verified:
          return 3
        case this.allVerificationStatus.need_review:
          return 4
        case this.allVerificationStatus.denied:
          return 5
        default:
          return 1
      }
  }

  handleChipLabel = (value: string) => {
    switch (value) {
      case this.allVerificationStatus.not_verified:
        return "Unverified"
      case this.allVerificationStatus.in_progress:
        return "Inprogress"
      case this.allVerificationStatus.verified:
        return "Verified"
      case this.allVerificationStatus.denied:
        return "Denied"
      case this.allVerificationStatus.need_review:
        return "Needs Review"
      default:
        return ""
    }
  }

   handleChipCSS = (value : string) => {
       switch (value) {
           case this.allVerificationStatus.not_verified:
               return "unVerifiedChip"
           case this.allVerificationStatus.in_progress:
               return "progressChip"
           case this.allVerificationStatus.verified:
               return "verifiedChip"
           case this.allVerificationStatus.denied:
               return "deniedChip"
           case this.allVerificationStatus.need_review:
               return "progressChip"
       }
   }

    handleChipNoteText = (value: string) => {
        switch (value) {
            case this.allVerificationStatus.not_verified:
                return "Verification required for rentals"
            case this.allVerificationStatus.in_progress:
                return ""
            case this.allVerificationStatus.verified:
                return ""
            case this.allVerificationStatus.denied:
                return "Driver License Expired"
            case this.allVerificationStatus.need_review:
                return "Driver License Expired"
        }
    }

    toggleText = () => {
      this.setState({ isDisplayed : false})
    }    

    goToStep2 = () => {
        this.setState({ verificationSteps : 2, verificationStatus : this.allVerificationStatus.in_progress})
    }

    goToStep1 = () => {
      this.setState({ verificationSteps : 1})
  }

    handleFrontLicenceRefCall = () => {
        if (this.licenceFrontImgRef.current) {
            this.licenceFrontImgRef.current.click();
        }
    }

    handleBackLicenceRefCall = () => {
      if (this.licenceBackImgRef.current) {
          this.licenceBackImgRef.current.click();
      }
  }

    handleFrontLicenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            this.setState({ licenceFrontDoc: event.target.files[0] });
        }
    };

    handleBackLicenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
          this.setState({ licenceBackDoc: event.target.files[0] });
      }
  };

    handleSelfieRefCall = () => {
        if (this.selfieImgRef.current) {
            this.selfieImgRef.current.click();
        }
    }

    handleSelfieChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        this.setState({ selfieDoc: event.target.files[0] });
      }
    };

    handleExpiredValue = (value: string) => {
      return value !== null ? moment(value).format('DD/MM/YY') : ""
    }

    clearAllFields = () => {
      this.setState({
        licenceFrontDoc: null,
        licenceBackDoc: null,
        selfieDoc: null,
        reUploadedDoc: null,
        verificationSteps: 2,
        isDisplayed: false
      })
    }

  // Customizable Area End
}

// Customizable Area End