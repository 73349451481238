// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Typography,
    Button,
    Grid
} from "@material-ui/core"

import AppHeader from "../../../blocks/notifications/src/TopHeader.web"
import Footer from "../../../components/src/Footer.web";
import Loader from "../../../components/src/Loader.web";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { protectionImg } from "./assets";

import ProtectionPlanController, {
    Props,
  } from "./ProtectionPlanController";

class ProtectionPlan extends ProtectionPlanController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
        <AppHeader navigation={this.props.navigation} activeLink="Dashboard"/>

        <ProtectionPlanWrapper>
                <Button
                    onClick={this.navToBackPage}
                    data-test-id="backBtn"
                    className="backPress"
                    startIcon={<ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />}>
                    Back
                </Button>
                <Typography className="headerText">Protection Plan</Typography>

                {this.state.loading ? <Loader loading={true} /> :
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                {this.state.protectionPlanData.map(item =>
                                    <Box className={`mainBox ${item.planId === this.state.selectedPlanId ? "activeBox" : "nonActiveBox"}`} key={item.planId} data-test-id="planId" onClick={() => this.changeActiveBox(item.planId)}>
                                        <Box>
                                            <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                                                <img src={protectionImg} style={{ height: 19, width: 19 }} />
                                                <Typography className="planText">{item.planTitle}</Typography>
                                            </Box>
                                            <Box style={{ marginTop: "15px" }}>
                                                <Typography className="noteText">Host Take : <span className="colorText">{item.hostTakeTitle}</span></Typography>
                                                <Typography className="noteText">Deductible : <span className="colorText">{item.deductTitle}</span></Typography>
                                                <Typography className="noteText">Coverage : <span className="colorText">{item.coverageTitle}</span></Typography>
                                                <Typography className="noteText">Damages : <span className="colorText">{item.damagesTitle}</span></Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <ArrowForwardIosIcon style={{ color: "#23395D" }} />
                                        </Box>
                                    </Box>

                                )}

                                <Button onClick={this.saveProtectionPlan} style={{ backgroundColor : this.state.selectedPlanId === "" ? '#ccc' : '#3173E1' }} disabled={this.state.selectedPlanId === ""} className="savePlanBtn" data-test-id="saveBtn">Save</Button>
                                
                            </Grid>
                        </Grid>
                    </Box>
                }

                
        </ProtectionPlanWrapper>

        <Footer />
         
      </div>
    );
    // Customizable Area End
  }
}

export default ProtectionPlan;

const ProtectionPlanWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .headerText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '30px',
        fontWeight: 600,
        color: '#101828',
        margin: "20px 0"
    },
    "& .backPress": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '14px',
        color: '#667085',
        textTransform: "none",
        background: "white",
    },
    "& .mainBox": {
        padding : 25,
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px",
        cursor: "pointer"
    },
    "& .planText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .colorText" : {
        fontWeight: 600,
        color: '#0F73EE',
    },
    "& .noteText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
        color: '#23395D',
    },
    "& .activeBox" : {
        boxShadow: "none",
        borderRadius: "12px",
        border: "1px solid #0F73EE",
        background: "#EEF2FA"
    },
    "& .nonActiveBox" : {
        boxShadow: "0px 4px 5px 0px #0000000D",
        borderRadius: "12px",
        border: "1px solid #E7E7E7"
    },
    "& .savePlanBtn" : {
        height: 40,
        fontSize: '14px',
        color: 'white',
        textTransform: "none",
        fontWeight: 600,
        background: "#3173E1",
        borderRadius: "12px",
        fontFamily: 'Plus Jakarta Sans',
        width: 90,
        margin: "20px 0"
      },
})

// Customizable Area End