// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import { toast } from "react-toastify"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface ProtectionPlanData {
    planId : string,
    planTitle : string,
    hostTakeTitle : string,
    damagesTitle : string,
    coverageTitle : string,
    deductTitle : string,
}

interface S {
  // Customizable Area Start  
  loading : boolean
  protectionPlanData : ProtectionPlanData[],
  selectedPlanId: string,
  catalougeId : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProtectionPlanController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProtectionPlanApiCallId : string = ""
  getCatalougesApiCallId : string = ""
  saveProtectionPlanApiCallId :  string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        loading : true,
        protectionPlanData: [],
        selectedPlanId : "",
        catalougeId : ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
         case this.getProtectionPlanApiCallId :
             return this.handleGetProtectionPlanResponse(responseJson)
          case this.getCatalougesApiCallId :
              return this.handleGetCatalogeResponse(responseJson)
          case this.saveProtectionPlanApiCallId :
              return this.handleSaveProtectionPlanResponse(responseJson)
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
      const catalougeId = this.props.navigation.getParam("subId")
      this.setState({ catalougeId })
      this.getProtectionPlan()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
     if(prevState.catalougeId !== this.state.catalougeId) {
       this.getCatalougeData()
     }
  }

  handleGetCatalogeResponse = (responseJson : any) => {
    if (responseJson && responseJson.catalogue && responseJson.catalogue.data) {
      const responseObject = responseJson.catalogue.data.attributes
      const planId = responseObject.protection_plan !== null ? truthyValue(responseObject.protection_plan.data.id) : ""

      this.setState({
         selectedPlanId : planId
      })


    } else {
      this.setState({ selectedPlanId: "" })
    }
  }

  handleGetProtectionPlanResponse = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      const planData = responseJson.data.map((arrayVal: any) => {
        return {
          planId: truthyValue(arrayVal.id),
          planTitle: truthyValue(arrayVal.attributes.name),
          hostTakeTitle: truthyValue(arrayVal.attributes.host_take),
          damagesTitle: truthyValue(arrayVal.attributes.damages),
          coverageTitle: truthyValue(arrayVal.attributes.coverage),
          deductTitle: truthyValue(arrayVal.attributes.deductible),
        }
      })
      this.setState({ loading: false, protectionPlanData: planData })
    } else {
      this.setState({ loading: false, protectionPlanData: [], selectedPlanId: "" })
    }
  }

  handleSaveProtectionPlanResponse = (responseJson : any) => {
    if (responseJson && responseJson.catalogue && responseJson.catalogue.data) {
        toast.success("Plan updated successfully")
        this.setState({ loading : false})
    } else {
      this.setState({ selectedPlanId: "", loading : false })
    }
  }

  navToBackPage = () => {
    this.props.navigation.goBack()
  }

  getProtectionPlan = () => {
    const header = {
        "token": localStorage.getItem("userToken")
      };
      
      this.getProtectionPlanApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.getProtectionPlansApiEndpoint,
        method: configJSON.apiMethodTypeGet
      })
  }

  getCatalougeData = () => {
    const headerWeb = {
        "token": localStorage.getItem("userToken")
      };
      
      this.getCatalougesApiCallId = apiCalling({
        header: JSON.stringify(headerWeb),
        endPoint: `${configJSON.getCatalougeInfoApiEndpoint}/${this.state.catalougeId}`,
        method: configJSON.apiMethodTypeGet
      })
  }

  saveProtectionPlan = () => {

    this.setState({ loading : true})

    const header = {
      "token": localStorage.getItem("userToken")
    };

    const formData = new FormData()
    formData.append("protection_plan_id", this.state.selectedPlanId)
    formData.append("catalogue_id", this.state.catalougeId)


    this.saveProtectionPlanApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.saveProtectionPlanApiEndpoint,
      method: configJSON.apiMethodTypePut,
      body: formData
    })
  }

  changeActiveBox = (planId : string) => {
    this.setState({ selectedPlanId : planId})
  }



  // Customizable Area End
}

// Customizable Area End