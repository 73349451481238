import React from 'react';
import { Box, Typography, Divider, styled, TextField, Button } from '@material-ui/core';
import { DateRange } from 'react-date-range';
import CustomStepper from './CustomStepper.web';

import ListingSettingsController, {
  Props,
} from "../../blocks/catalogue/src/ListingSettingsController";

class PricingCard extends ListingSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  steps = [
    {
      label: 'Rental Price',
      details: '$1,400 / Night',
      subDetails: "(x3 Night)",
      amount: '$0',
    },
    {
      label: 'Stay Fees',
      details: 'Extended Trip Discount',
      subDetails: "(5%)",
      amount: '$0',
    },
    {
      label: 'House Taxes',
      details: 'Refunded by Oct 28',
      subDetails: "",
      amount: '$0',
    },
  ];

  render() {
    // Customizable Area Start
    
    return (
      <div>
        <Wrapper>
          <Box className='bgTransparent' style={{ padding: 0, margin: "10px 0" }}>
            <DateRange
              editableDateInputs={false}
              showMonthArrow
              ranges={this.state.availableDates}
            />

          </Box>
          <Box className='bgTransparent'>
            <Typography className='headerText'>Pricing</Typography>
            <Divider style={{ margin: "10px 0" }} />
            <Typography className='earningText'>Your Earnings</Typography>
            <Box className='earningBox'>
              $0
            </Box>
            <Box className='pricingBox'>
              <CustomStepper steps={this.steps} />
            </Box>
            <Divider style={{ marginBottom: "10px" }} />
            <Box className='receiveBox'>
              <Typography className='title1'>Total Received</Typography>
              <Box className='priceBox'>
                $0
              </Box>
            </Box>
          </Box>
          <Box className='bgTransparent' style={{ margin: "10px 0" }}>
            <Typography className='headerText'>Discounts</Typography>
            <Divider style={{ margin: "10px 0" }} />

            <Typography className='durationText'>Duration Discounts</Typography>
            <Typography className='durationSubText'>Encourage guests to book longer trips</Typography>
            <Divider style={{ margin: "10px 0" }} />

            {this.state.discountArray.map((pricingItem: any, index : number) =>
              <div>
                <Box className='flexBox' key={`discount-${index}`}>
                  <Box>
                    <Typography className='title1'>{pricingItem.title}</Typography>
                    <Typography className='title2'>Recommended: {pricingItem.recommended}%</Typography>
                  </Box>
                  {pricingItem.isEdit ?
                    <Box className='priceBox'>
                      <TextField
                        value={pricingItem.percentage}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDiscountChange(pricingItem.id, event.target.value)}
                        data-test-id="eventChange"
                        className='textField'
                      />
                    </Box> :
                    <Box className='priceBox' data-test-id="discountBox" onClick={() => this.handleDiscountClick(pricingItem.id)}>
                      {pricingItem.percentage}%
                    </Box>
                  }
                  
                  
                </Box>

                { pricingItem.isEdit && <Box style={{ display: "flex", gap: 20, justifyContent: "end" }}>
                  <Button className='cancelBtn' data-test-id="cancelBtn" onClick={() => this.handleDiscountClick(pricingItem.id)}>Cancel</Button>
                  <Button className='saveDiscountBtn' data-test-id="saveBtn" onClick={this.handleSaveUpdateCondition}>Save</Button>
                </Box>}
              </div>
            )}

          </Box>

        </Wrapper>
      </div>
    );
    // Customizable Area End
  }
}

const Wrapper = styled(Box)({
    padding: "20px",
    background: "#EAECF0",
    borderRadius: 12,
    "& .headerText" : {
      fontSize: '16px',
      fontWeight: 600,
      fontFamily: 'Plus Jakarta Sans',
      color: '#23395D',
    },
    "& .earningText": {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Plus Jakarta Sans',
      color: '#667085'
    },
    "& .bgTransparent": {
      background: "white",
      border: "1px solid #E7E7E7",
      borderRadius: 12,
      padding: "10px"
    },
    "& .earningBox": {
      background: "#F2F4F7",
      padding: "10px",
      color: '#98A2B3',
      marginTop: 5,
      borderRadius: 8
    },
    "& .durationSubText" : {
      fontSize: '12px',
      fontWeight: 500,
      fontFamily: 'Plus Jakarta Sans',
      color: '#B0B0B0'
    },
    "& .durationText" : {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Plus Jakarta Sans',
      color: "#23395D"
    },
    "& .priceBox" : {
      background: "#EAECF0",
      padding: "10px",
      color: "#23395D",
      width: "90px",
      fontWeight: 600,
      textAlign: "center",
      borderRadius: 8,
      cursor: "pointer"
    },
    "& .title1" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 500,
      color: "#23395D"
    },
    "& .title2" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '12px',
      fontWeight: 500,
      color: "#B0B0B0"
    },
    "& .flexBox" : {
      display: "flex",
      justifyContent: "space-between", 
      alignItems: "center",
      margin: "15px 0"
    },
    "& .pricingBox .MuiStepConnector-vertical": {
      marginLeft: 8,
      padding: 0
    },
    "& .receiveBox" : {
      display : "flex", 
      alignItems: "center", 
      justifyContent: "space-between",
      marginBottom: 10
    },
    "& .cancelBtn" : {
      borderRadius: '12px',
      color: '#475467',
      height: '40px',
      width: "90px",
      border: "1px solid #D0D5DD",
      backgroundColor: 'white',
      fontWeight: 600,
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      textTransform: "none"
    },
    "& .saveDiscountBtn" : {
      borderRadius: '12px',
      color: 'white',
      height: '40px',
      width: "90px",
      fontWeight: 600,
      backgroundColor: '#3173E1',
      textTransform: "none",
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
    },
    "& .textField .MuiInputBase-input" : {
      textAlign :"center" 
    }
})

export default PricingCard;
