// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import { toast } from "react-toastify";
import React from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface ReportsCategory {
  reportId: number,
  reportTitle: string,
  reportSubTitle: string,
  reportType: string
}

interface ReportedCategory {
  reportedId: number,
  reportedDescription: string,
  reportedImages: ImageArray[],
  reportedType: string,
  reportedBookSlotId : string
}

interface ImageArray {
  id: number;
  filename: string;
  url: string;
  type: string;
}

interface S {
  // Customizable Area Start  
  bookingId : string,
  reportsCatogories : ReportsCategory[],
  selectedIssue : string,
  isIssueSelected : boolean,
  thanksModal : boolean,
  issuesPhotos : File[],
  uploadedIsuesPhotos : string[],
  issueDescription : string,
  reportedCatogory : ReportedCategory[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class RenterIssuesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  saveReportIssueApiCallId : string = ""
  getReportIssueApiCallid : string = ""
  imageRefs: React.RefObject<HTMLInputElement>
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        bookingId : "",
        reportsCatogories : this.reportedCategories,
        selectedIssue : "",
        isIssueSelected : false,
        thanksModal : false,
        uploadedIsuesPhotos : [],
        issuesPhotos : [],
        issueDescription : "",
        reportedCatogory: [],
    };
    this.imageRefs = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getReportIssueApiCallid :
          return this.handleGETResponse(responseJson)
        case this.saveReportIssueApiCallId :
           return this.handlePOSTResponse(responseJson)
        default:
           break
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    const bookedSlotId = this.props.navigation.getParam("bookSlotId")
    this.setState({ bookingId: bookedSlotId })
    this.getReportIssueData()
  }

  getReportIssueData = () => {
    const header = {
      'token': localStorage.getItem("userToken")
    }

    this.getReportIssueApiCallid = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.getRenterReportedIssueApiEndPoint,
      method: configJSON.apiTypeGet
    })

  }

  saveReportIssueData = () => {

    if(this.state.uploadedIsuesPhotos.length === 0) {
       toast.error("Please upload at least one image")
       return false
    }

    const header = {
      'token': localStorage.getItem("userToken"),
    }

    const formData = new FormData()

    formData.append("renter_issue[booked_slot_id]", this.state.bookingId)
    formData.append("renter_issue[issue_type]", this.state.selectedIssue)
    formData.append("renter_issue[description]", this.state.issueDescription)
    this.state.issuesPhotos.forEach(value => {
      formData.append("renter_issue[images][]", value);
    });


    this.saveReportIssueApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: configJSON.saveRenterReportIssueApiEndPoint,
      method: configJSON.apiTypePost,
      body : formData
    })
  }

  handleGETResponse = (responseJson : any) => {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      const reportedArray: ReportedCategory[] = responseJson.data.map((item: any) => {
        return {
          reportedId: truthyValue(item.attributes.id),
          reportedDescription: truthyValue(item.attributes.description),
          reportedImages: truthyValue(item.attributes.images),
          reportedType: truthyValue(item.attributes.issue_type),
          reportedBookSlotId: truthyValue(item.attributes.booked_slot_id)
        }
      })

      this.setState({ reportedCatogory : reportedArray})
    } else {
      this.setState({ reportedCatogory: [] })
    }

  } 

  handlePOSTResponse = (responseJson : any) => {
    if(responseJson && responseJson.data) {
      this.setState({ thanksModal : true})
   } else {
     this.setState({ thanksModal : false})
   }
  }

  reportedCategories: ReportsCategory[] = [
    {
      reportId: 1,
      reportTitle: "Maintenance Needs",
      reportSubTitle: "Problems with the apartment's infrastructure or systems that require repair or maintenance, such as leaking faucets, malfunctioning heating or cooling systems, or electrical issues like flickering lights.",
      reportType: "maintenance_needs"
    },
    {
      reportId: 2,
      reportTitle: "Cleanliness",
      reportSubTitle: "The apartment is not clean upon arrival, with issues such as dirty floors, stained carpets, unclean appliances, or an overall lack of hygiene.",
      reportType: "cleanliness"
    },
    {
      reportId: 3,
      reportTitle: "Damage",
      reportSubTitle: "The user notices damage to the apartment's structure or furnishings that was not disclosed in the listing, such as scratches on the walls, dents in the appliances, or broken furniture.",
      reportType: "damage"
    },
    {
      reportId: 4,
      reportTitle: "Safety Concerns",
      reportSubTitle: "Issues that compromise the safety of the apartment's occupants, such as broken locks on doors or windows, loose handrails on stairs, or inadequate lighting in common areas.",
      reportType: "safety_concerns"
    },
    {
      reportId: 5,
      reportTitle: "Structural Problems",
      reportSubTitle: "Defects or damage to the building itself, including cracks in the walls or ceiling, water damage, mold or mildew growth, or signs of pest infestation.",
      reportType: "structural_problems"
    },
    {
      reportId: 6,
      reportTitle: "Utilities and Services",
      reportSubTitle: "Problems with utilities or services provided to the apartment, such as interruptions in water, electricity, or gas service, issues with garbage disposal or recycling services, or problems with heating or cooling systems.",
      reportType: "utilities_and_services"
    }
  ];

  handleSelctedIssue = (reportType : string) => {
    this.setState({ selectedIssue : reportType })
  }

  navToBackButton = () => {
       this.props.navigation.goBack()
  }

  backToStepOne = () => {
    this.setState({ isIssueSelected : false})
  }

  finishBtnClick = () => {
    this.setState({ isIssueSelected : false, thanksModal : false, selectedIssue : ""})
    this.getReportIssueData()
  }

  openThanksModal = () => {
    this.setState({ thanksModal : true})
  }

  closeThanksModal = () => {
    this.setState({ thanksModal : false})
  }

  onContinueClick = () => {
    if(this.state.selectedIssue === "") {
      toast.error("Please select issue")
      return false   
    }

    this.setState({ isIssueSelected : true})
  }

  handleImagesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);
      const fileURLs = Array.from(files).map((file) => URL.createObjectURL(file));

      this.setState((prevState) => ({
        issuesPhotos: [...prevState.issuesPhotos, ...newFiles],
        uploadedIsuesPhotos: [...prevState.uploadedIsuesPhotos, ...fileURLs]
      }));
    }

  };

  handleDeleteImages = (index: number) => {
    this.setState((prevState) => {
      const updatedFiles = [...prevState.issuesPhotos];
      const updatedPreviews = [...prevState.uploadedIsuesPhotos];

      updatedFiles.splice(index, 1);
      updatedPreviews.splice(index, 1);

      return {
        issuesPhotos: updatedFiles,
        uploadedIsuesPhotos: updatedPreviews,
      };
    });
  };

  handleRefsCall = () => {
    if (this.imageRefs.current) {
      this.imageRefs.current.click();
    }
  }

  handleChangeForIssueDescription = (event : React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ issueDescription : event.target.value})
  }

  handleReportedIssueCheck = () => {
    if(this.state.reportedCatogory.length > 0) {
       const modifiedArray = this.state.reportedCatogory.filter((item) => item.reportedBookSlotId.toString() === this.state.bookingId.toString() && item.reportedType === this.state.selectedIssue)
       return modifiedArray.length > 0 ? true : false
    } else {
       return false
    }
  }
  


  // Customizable Area End
}

// Customizable Area End