// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Dialog,
    Input,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from "@material-ui/core"

import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

import { chatBotIcon, msgIcon } from "./assets";
import { sendIcon } from "../../../blocks/chat/src/assets";

import ChatgptScreenController, {
    Props,
} from "./ChatgptScreenController";


class ChatgptScreen extends ChatgptScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start

        return (
            <div>
                <ChatModal
                    open={this.props.open}
                    onClose={this.props.onClose}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle style={{ borderBottom: "1px solid #EAEAEA"}}>
                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box className="innerHeaderBox">
                                <img className="chatBotImg" alt="chatBotImg" src={chatBotIcon} />
                                <Box>
                                    <Typography className='headerTextOne'>Hi, This is Ava</Typography>
                                    <Typography className='headerTextTwo'>Your Travel Companion</Typography>
                                </Box>

                            </Box>
                            <IconButton edge="end" data-test-id="closeBtn" onClick={this.props.onClose}>
                                <HighlightOffOutlinedIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>

                    <DialogContent className="heightControl">
                        <Box style={{ margin : "20px 0"}}>

                            {this.state.chatGptResponse.length > 0 && this.state.chatGptResponse.map(response =>
                                <Box key={response.questionId}>

                                    <Box className="userBox">
                                        <Box className="userChatBox">
                                            <Typography className="userMsgText">
                                                {response.gptQuestion}
                                            </Typography>
                                        </Box>
                                        <img className="chatBotProfile" alt="chatBotImg" src={this.state.userProfileImg} />
                                    </Box>
                                    <Typography className="timeText" style={{ textAlign: "right" }}>{response.gptQuestionTime}</Typography>

                                    <Box className="chatBotBox">
                                        <img className="chatBotProfile" alt="chatBotImg" src={chatBotIcon} />
                                        <Box>
                                            <Box className="firstChatBox">
                                                <Typography className="firstMsgText">
                                                    {response.gptAnswer}
                                                </Typography>
                                            </Box>
                                            <Typography className="timeText">{response.gptAnswerTime}</Typography>
                                        </Box>
                                    </Box>

                                </Box>
                                
                            )}

                        </Box>

                    </DialogContent>

                    <Box className="chatQuestionsBox">
                        <Box className="chatQuestionInput">
                            <Input
                                startAdornment={<img src={msgIcon} />}
                                endAdornment={<img src={sendIcon} data-test-id="sendIcon" onClick={this.sendQuestionToGpt} style={{ cursor: "pointer"}} alt="send" />}
                                placeholder="Ask anything to Ava"
                                className="chatQuestionText"
                                data-test-id="sendMsg"
                                value={this.state.questionToGpt}
                                onChange={this.handleAskQuestionChangeChange}
                                onKeyDown={this.handleEnterBtn}
                                fullWidth
                                disableUnderline
                            />
                        </Box>
                    </Box>

                    
                </ChatModal>
            </div>
        );
        // Customizable Area End
    }
}

export default ChatgptScreen;

const ChatModal = styled(Dialog)({
    "& .MuiPaper-rounded" : {
        borderRadius : "24px !important",
    },
    "& .heightControl" : {
        maxHeight: 'calc(100vh - 300px)',
        overflowY: 'auto', 
        overflowX: "hidden",
        scrollbarWidth : "none",
        background : "#EFF2F8"
    },
    "& .innerHeaderBox" : {
        display : "flex",
        gap : 20
    },
    "& .headerTextOne" : {
        fontWeight: 600,
        color: "#23395D",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
    },
    "& .headerTextTwo" : {
        fontWeight: 600,
        color: "#23395D",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "18px",
    },
    "& .chatQuestionsBox" : {
        padding : "20px",
        background : "#EFF2F8"
    },
    "& .chatQuestionText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        textAlign: 'left',
        color: '#475467',
        border: "1px solid #3173E1",
        padding : 10,
        borderRadius : 40
    },
    "& .chatQuestionText .MuiInputBase-input": {
        margin: "0 10px"
    },
    "& .chatBotBox" : {
        display : "flex",
        gap : "10px",
        margin : "10px 0"
    },
    "& .chatBotProfile" : {
        height : 40,
        width : 40
    },
    "& .firstChatBox" : {
        background : "#3173E1",
        padding : 12,
        borderTopRightRadius : "16px",
        borderBottomRightRadius : "16px",
        borderBottomLeftRadius : "16px",
        width : "65%"
    },
    "& .firstMsgText" : {
        fontWeight: 400,
        color: "#FFFFFF",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
    },
    "& .userBox" : {
        display : "flex",
        gap : "10px",
        justifyContent: "end",
        margin : "10px 0"
    },
    "& .userChatBox" : {
        background : "#FFFFFF",
        padding : 12,
        borderTopLeftRadius : "16px",
        borderBottomRightRadius : "16px",
        borderBottomLeftRadius : "16px",
        maxWidth : "65%"
    },
    "& .userMsgText" : {
        fontWeight: 400,
        color: "#4E4F6C",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
    },
    "& .timeText" : {
        fontWeight: 400,
        color: "#98A2B3",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
    }
})
// Customizable Area End