Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.getHomeCarList="bx_block_catalogue/get_car_home_listings"
exports.getSearchedApiListCatalogue="bx_block_catalogue/get_search_catalogues"
exports.getCatalogeCountApiEndPoint="bx_block_catalogue/get_catalogues_count"
exports.loginApiEndPoints="bx_block_login/logins"
exports.signUpApiEndPoints="account_block/accounts"
exports.becomeHostApiEndPoint="bx_block_profile/become_a_host"
exports.getUsersCatalougeApiEndpoint="bx_block_catalogue/catalogues"
exports.verifyOTPApiEndpoint="account_block/accounts/confirm_otp"
exports.addPaymentMethodApiEndPoint="bx_block_stripe_integration/card_informations"
exports.getHomeSubListingApiEndPoint="bx_block_categories/sub_categories"
exports.createHomeApiEndpoint="bx_block_catalogue/catalogues"
exports.getAdvanceFilterApiEndpoint="bx_block_filter_items/filtering"
exports.getCatogoriesApiEndPoint="bx_block_categories/categories"
exports.getHostReservationApiEndPoint="bx_block_calendar/get_hosts_rental_requests_by_statuses"
exports.getUpcomingReservationApiEndPoint="bx_block_calendar/host_booked_slots_request"
exports.changeReservationStatusApiEndPoint="bx_block_calendar/change_request_status"
exports.getReservationInfoApiEndPoint="bx_block_calendar/booked_slots"
exports.getRenterProfileApiEndPoint="bx_block_calendar/renter_profile"
exports.getUserProfileApiEndPoint="account_block/accounts/get_user"
exports.saveImagesApiEndPoint="bx_block_calendar/booking_checkout_photos"
exports.earningPayoutDataEndPoints= "bx_block_stripe_integration/payment_methods/get_payouts"
exports.getChatCountApiEndPoint="bx_block_privatechat/conversations"
exports.seeMoreText="See more"
exports.avgRating= "AVG. Rating"
exports.allReview="See all reviews"
exports.allLessReview="See less reviews"
exports.seeLessText= "See less"
exports.reviewDataEndPoints= "bx_block_catalogue/get_host_catalogues_reviews"
exports.getAllClaimsApiEndPoint="bx_block_calendar/claims"
exports.searchClaimsApiEndPoint="bx_block_calendar/search_claims"
exports.getPayoutScheduleApiEndPoint="bx_block_stripe_integration/payouts"
exports.updatePayOutTypeApiEndPoint="account_block/accounts/update_payout_type"
exports.sendReplyEndPoints =(replyId) =>  `bx_block_catalogue/reviews/${replyId}/replies`
exports.locationDistanceText="Location & Distance"
exports.fuelPolicy="Fuel Policy";
exports.saveBtn="Save";
exports.discardBtn= "Discard";
exports.setPrice= "Set price"
exports.deliveryLocations = "Delivery locations"
exports.getCatalougeInfoApiEndpoint="bx_block_catalogue/catalogues"
exports.updateCatalougeInfoApiEndpoint="bx_block_catalogue/catalogues"
exports.getProtectionPlansApiEndpoint="bx_block_pricingengine2/protection_plans"
exports.getTripHistoryApiEndPoint="bx_block_catalogue/get_trip_history"
exports.getLocationData = (catalogueId) => `bx_block_catalogue/catalogues/${catalogueId}`
exports.instructionApiEndpoint = (catalogueId) => `bx_block_catalogue/catalogues/${catalogueId}/guest_instruction`
exports.extraAddOnText = "Extras & Addons"
exports.modalHeadText= "Location & Distance"
exports.addOnName= "Name of Addons/ Extras"
exports.putPickupLocationEndpoint = "bx_block_address/update_catalogue_address"
exports.getExtraAddEndPoint = "bx_block_catalogue/extra_addons"
exports.createExtraAddEndPoint = "bx_block_catalogue/extra_addons"
exports.editExtraAddEndPoint = (editId) =>  `bx_block_catalogue/extra_addons/${editId}`
exports.tripHeadText = "Trip Preferences"
exports.postTripCheckOutEndPoint =(bookedSlotId) =>  `bx_block_calendar/booked_slots/${bookedSlotId}/check_in_out_detail_by_host`
exports.preTripCheckOutEndPoint =(bookingId) =>  `bx_block_calendar/booked_slots/${bookingId}/check_in_out_detail_by_host`
exports.getTripImagesApiEndPoint = (bookedSlotId, tripType) => `bx_block_calendar/booked_slots/${bookedSlotId}/check_in_out_detail_by_host?trip_type=${tripType}`
exports.getHostScheduleApiEndPoint = "bx_block_calendar/get_host_schedule"
exports.getDiscountsInfoApiEndPoint = "bx_block_coupon_cg/get_catalogue_discounts"
exports.saveUpdateDiscountsApiEndPoint= "bx_block_coupon_cg/discounts"
exports.getTaxInfoApiEndPoint=(year) => `bx_block_analytics9/tax_informations?year=${year}`
exports.tripPreferencesApiEndPoint = "bx_block_catalogue/trip_preferences"
exports.getPriceBreakdownApiEndPoint="bx_block_calendar/trip_cost_breakdown"
exports.getCompletedTripApiEndPoint = (bookingId) => `bx_block_calendar/booked_slots/${bookingId}/post_trip`
exports.getReservationDetailApiEndPoint="bx_block_calendar/booked_slots"
exports.saveReportIssueApiEndPoint = "bx_block_calendar/host_issues"
exports.saveProtectionPlanApiEndpoint= "bx_block_catalogue/add_protection_plan"
exports.saveDistanceIncludedApiEndPoint = "bx_block_catalogue/catalogues"
exports.updateAddressAipEndPoint = "bx_block_address/update_catalogue_address"
exports.getAvailableDatesApiEndPoint = "bx_block_calendar/available_catalogue_slots"
exports.createPayoutApiEndPoint= "bx_block_stripe_integration/payouts"
// Customizable Area End