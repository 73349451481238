// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify"
import { apiCalling, truthyValue } from "../../../components/src/asset";
import React from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface ReportedCatogory {
    reportId : number,
    reportTitle : string,
    reportSubTitle : string,
    reportType : string
}

interface S {
  // Customizable Area Start  
  reportedCatogory : ReportedCatogory[],
  selectedIssue : string,
  reportedIssue : boolean,
  userId : string,
  bookingId : string,
  hostIssuesImages : File[],
  uploadedHostImages : string[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReportIssueController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  imageRef : React.RefObject<HTMLInputElement>
  saveReportIssueApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        reportedCatogory : this.reportedCatogoriesData,
        selectedIssue : "",
        reportedIssue : false,
        userId: "",
        bookingId : "",
        hostIssuesImages : [],
        uploadedHostImages : []
    };
    this.imageRef = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.saveReportIssueApiCallId) {
          this.handleHostIssuesApiResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    const bookSlotId = this.props.navigation.getParam("bookingId")
    this.setState({ bookingId: bookSlotId })
  }
  
  reportedCatogoriesData :ReportedCatogory[] = [
    {
        reportId : 1,
        reportTitle: "Smoking violation",
        reportSubTitle : "Available up to 72 hours after trip end",
        reportType: "smoking_violation"
    },
    {
        reportId : 2,
        reportTitle: "Cleaning violation",
        reportSubTitle : "Available up to 72 hours after trip end",
        reportType: "cleaning_violation"
    },
    {
        reportId : 3,
        reportTitle: "Late return: Vehicle not returned",
        reportSubTitle : "Available up to 48 hours after trip end",
        reportType: "late_check_out"
    },
    {
        reportId : 4,
        reportTitle: "Late return: Vehicle returned",
        reportSubTitle : "Available up to 48 hours after trip end",
        reportType: "late_return"
    },
  ]
   
  handleHostIssuesApiResponse = (responseJson: any) => {
    if (responseJson && responseJson.data ) {
       toast.success("Issue reported successfully.")
       this.redirectToBackPage()
    } else {
      const errorVal = truthyValue(responseJson.error)
      toast.error(errorVal)
    }
  }

    redirectToBackPage = () => {
        this.props.navigation.goBack()
    }

    handleCatogoriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedIssue: event.target.value })
    }

    backtoReportPage =  () => {
        this.setState({ reportedIssue : false})
    }

    onToNextReportStep = () => {
        if(this.state.selectedIssue === "") {
           toast.error("Please select violation issue")
           return false   
        }

        this.setState({ reportedIssue : true})
    }

    handleIsuueTitle = () => {
        const findTitle = this.state.reportedCatogory.find(item => item.reportType === this.state.selectedIssue)?.reportTitle
        return findTitle
    }

    handleDeletePhotos = (index: number) => {
      this.setState((prevState) => {
        const updatedFiles = [...prevState.hostIssuesImages];
        const updatedPreviews = [...prevState.uploadedHostImages];
  
        updatedFiles.splice(index, 1);
        updatedPreviews.splice(index, 1);
  
        return {
          hostIssuesImages: updatedFiles,
          uploadedHostImages: updatedPreviews,
        };
      });
    };

    handleHostIssuesPhotosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files) {
        const newFiles = Array.from(files);
        const fileURLs = Array.from(files).map((file) => URL.createObjectURL(file));
  
        this.setState((prevState) => ({
          hostIssuesImages: [...prevState.hostIssuesImages, ...newFiles],
          uploadedHostImages: [...prevState.uploadedHostImages, ...fileURLs]
        }));
      }
  
    };

    handleRefCall = () => {
      if (this.imageRef.current) {
        this.imageRef.current.click();
      }
    }

    saveReportAnIssueData = () => {

      if(this.state.uploadedHostImages.length === 0) {
         toast.error("Please upload atleast one photo")
         return false
      }

      const header = {
        'token': localStorage.getItem("userToken")
      }

      const formData = new FormData()

      formData.append("host_issue[booked_slot_id]",this.state.bookingId)
      formData.append("host_issue[issue_type]", this.state.selectedIssue)
      this.state.hostIssuesImages.forEach(value => {
        formData.append("host_issue[images][]", value);
      });


      this.saveReportIssueApiCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.apiMethodTypePost,
        endPoint: configJSON.saveReportIssueApiEndPoint,
        body : formData
      })
    }


  // Customizable Area End
}

// Customizable Area End