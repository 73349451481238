Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.deleteApiMethodType = "DELETE";
exports.createTicketEndPoint = "bx_block_ticketsystem/tickets";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TicketSystem";
exports.labelBodyText = "TicketSystem Body";

exports.btnExampleTitle = "CLICK ME";

exports.screenTitle = "Ticket Form";
exports.screenSubtitle = "Please describe the issue you are facing";

exports.labelName = "Name*"
exports.name = "Name"
exports.labelEmail = "Email*"
exports.email = "Email"
exports.labelSubject = "Subject*"
exports.subject = "Subject"
exports.labelDescription = "Description*"
exports.description = "Description"

exports.required = "Required"
exports.submitBtnText = "Submit";
exports.getTicketCatogoryApiEndPoint = "bx_block_ticketsystem/ticket_categories"
exports.getTicketsApiEndPoint = "bx_block_ticketsystem/tickets"
exports.saveTicketsApiEndPoint = "bx_block_ticketsystem/tickets"
exports.getChatInfoApiEndPoint = "bx_block_ticketsystem/tickets"
exports.sendMsgChatApiEndPoint = "bx_block_ticketsystem/ticket_messages"
// Customizable Area End