// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import { toast } from "react-toastify"
import { CardDetails, SavedCardDetails } from "../../../components/src/TypeInterfaces.web";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface CardInfoErrors {
    "card_holder_name": boolean,
    "state":boolean,
    "city": boolean,
    "zip_code": boolean,
    "address": boolean,
    "country": boolean
}
interface CardInfo {
    "card_number": string,
    "card_holder_name": string,
    "expiry_date": string,
    "security_code": string,
    "street": string,
    "city": string,
    "state":string,
    "zip_code": string,
    "country": string
}

interface S {
  // Customizable Area Start  
  openAddCardModal: boolean,
  openUpdateCardModal : boolean
  cardDetails : CardInfo,
  cardDetailsErrors : CardInfoErrors,
  savedCards : SavedCardDetails[],
  loading : boolean,
  savedCardInfo : SavedCardDetails
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HostDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCardInfoApiCallId : string = ""
  addCardInfoApiCallId : string =""
  defaultCardApiCallId : string = "";
  editCardInfoApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        openAddCardModal : false,
        cardDetails : {
            "card_number": "",
            "card_holder_name": "",
            "expiry_date": "",
            "security_code": "",
            "street": "",
            "city": "",
            "state": "",
            "zip_code": "",
            "country": ""   
        },
        cardDetailsErrors : {
            "card_holder_name": false,
            "address": false,
            "city": false,
            "state": false,
            "zip_code": false,
            "country": false
        },
        savedCards: [],
        loading: true,
        openUpdateCardModal : false,
        savedCardInfo : this.initialSavedCardInfo
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getCardInfoApiCallId : 
           return this.getCardApiResponse(responseJson)
        case this.addCardInfoApiCallId : 
           return this.addCardApiResponse(responseJson)
        case this.defaultCardApiCallId : 
           return this.defaultCardApiResponse(responseJson)
        case this.editCardInfoApiCallId :
            return this.updateCardApiResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
      this.getCardInfo()
  }
  
  addCardApiResponse = (responseJson : any) => {
    if(responseJson && responseJson.data) {
        this.closeAddCardModal()
        toast.success("Card Saved Successfully")
        this.getCardInfo()
    } else if(responseJson && responseJson.error ) {
        let errMsg = ""
        if(responseJson.error && responseJson.error.card_number) {
           errMsg = responseJson.error.card_number[0]
           toast.error(errMsg)
        } 
        errMsg = responseJson.error.message
        toast.error(errMsg)
    }
     else {
        toast.error('Something went wrong')
    }

  }

  getCardApiResponse = (responseJson : any) => {
    if(responseJson && responseJson.payment_methods && responseJson.payment_methods.data) {
        const cardArray = responseJson.payment_methods.data

        const modifiedArray = cardArray.map((item : any) => {
          return {
            card_id: truthyValue(item.id),
            card_holder_name: truthyValue(item.attributes.card_holder_name) !== null ? truthyValue(item.attributes.card_holder_name) : "",
            card_number: truthyValue(item.attributes.last4),
            exp_month: truthyValue(item.attributes.exp_month),
            exp_year: truthyValue(item.attributes.exp_year),
            is_primary: truthyValue(item.attributes.is_primary),
            email: truthyValue(item.attributes.email) !== null ? truthyValue(item.attributes.email) : "" ,
            address: truthyValue(item.attributes.address_line1),
            country: truthyValue(item.attributes.country),
            state: truthyValue(item.attributes.state),
            city: truthyValue(item.attributes.city),
            zip_code: truthyValue(item.attributes.postal_code),
            card_brand : "visa"
          }
        })
        this.setState({ savedCards : modifiedArray, loading: false})
    } else {
        this.setState({ savedCards : [], loading: false})
    }
  }

  defaultCardApiResponse = (responseJson : any) => {
    if(responseJson && responseJson.data) {
        this.getCardInfo()
        toast.success("Default Card Updated Succeffully")
    } else {
        toast.success("Something went wrong!. Please Try again later")
    }
  }

  updateCardApiResponse = (responseJson : any) => {
    if(responseJson && responseJson.data) {
      toast.success("Card Updated Succeffully")
      this.closeUpdateCardModal()      
      this.getCardInfo()
    } else {
        toast.success("Something went wrong!. Please Try again later")
    }
  }

  oepnAddCardModal = () => {
    this.setState({ openAddCardModal : true})
  }

  openUpdateCardModal = (event:React.MouseEvent<HTMLButtonElement>, cardInfo : SavedCardDetails) => {
    event.stopPropagation()
    this.setState({ openUpdateCardModal : true, savedCardInfo : cardInfo})
  }

  closeUpdateCardModal = () => {
    this.setState({ openUpdateCardModal : false, savedCardInfo : this.initialSavedCardInfo})
  }

  closeAddCardModal = () => {
    this.setState({ openAddCardModal : false })
  }

  getCardInfo = () => {
    const header = {
        'token': localStorage.getItem("userToken")
      }

    this.getCardInfoApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypeGet,
        endPoint: configJSON.getCardInfoApiEndPoint
    })
  }

  editCardInfo = () => {
    if(!this.handleAllValidationOnCardSave()) {
      return false;
   }


    const header = {
      'token': localStorage.getItem("userToken")
    };

    const formData = new FormData()

    formData.append("id", this.state.savedCardInfo.card_id)
    formData.append("card[card_holder_name]", this.state.savedCardInfo.card_holder_name)
    formData.append("card[address_line1]", this.state.savedCardInfo.address)
    formData.append("card[city]", this.state.savedCardInfo.city)
    formData.append("card[state]", this.state.savedCardInfo.state)
    formData.append("card[postal_code]", this.state.savedCardInfo.zip_code)
    formData.append("card[country]", this.state.savedCardInfo.country)

    this.editCardInfoApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePut,
      endPoint: configJSON.updateCardApiEndpoint,
      body: formData
    })
  };

  saveCardInfo = (payload : CardDetails) => {

    const header = {
        'token': localStorage.getItem("userToken")
    }

    const formData = new FormData()

    formData.append("card[card_token]", payload.card_token)
    formData.append("card[address_line1]", payload.street)
    formData.append("card[city]", payload.city)
    formData.append("card[state]", payload.state)
    formData.append("card[postal_code]", payload.zip_code)
    formData.append("card[country]", payload.country)

    this.addCardInfoApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypePost,
        endPoint: configJSON.saveCardInfoApiEndPoint,
        body: formData
    })
  }

  defaultCardInfo = (cardId : string) => {

    this.setState({ loading : true})
    const header = {
        'token': localStorage.getItem("userToken")
      }

    const formData = new FormData()

    formData.append("id", cardId)

    this.defaultCardApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypePut,
        endPoint: configJSON.defaultCardApiEndPoint,
        body: formData
    })
  }

  handleChnageOfAddCard = (event : React.ChangeEvent<HTMLInputElement>) => {
    const formValue = event.target.value
    const formName = event.target.name

    if(formValue.trim() === "") {
        this.setState({ cardDetailsErrors: { ...this.state.cardDetailsErrors, [formName] : true }})
    } else {
        this.setState({ cardDetailsErrors: { ...this.state.cardDetailsErrors, [formName] : false }})
    }

    this.setState({ savedCardInfo: { ...this.state.savedCardInfo, [formName] : formValue }})
    
  }

  handleAllValidationOnCardSave = () => {
    let checkValidation = {
      "card_holder_name": false,
      "address": false,
      "city": false,
      "state": false,
      "zip_code": false,
      "country": false
      };
  
      if (this.state.savedCardInfo.card_holder_name === "") {
        checkValidation.card_holder_name = true;
      } if (this.state.savedCardInfo.address === "") {
        checkValidation.address = true;
      } if (this.state.savedCardInfo.city === "") {
        checkValidation.city = true;
      } if (this.state.savedCardInfo.state === "") {
        checkValidation.state = true;
      } if (this.state.savedCardInfo.zip_code === "") {
        checkValidation.zip_code = true;
      } if (this.state.savedCardInfo.country === "") {
        checkValidation.country = true;
      }
  
      this.setState({ cardDetailsErrors : checkValidation})
  
      return Object.values(checkValidation).every((value) => value === false);
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (
      !(
        (event.key >= '0' && event.key <= '9') ||
        event.key === 'Backspace' ||
        event.key === 'Delete' ||
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight' ||
        event.key === 'Tab'
      )
    ) {
      event.preventDefault();
    }
  }

    handleErrorFields = (checkKey: boolean, errMsg: string) => {
        return checkKey && <div className="textLabel" style={{ color: "red", marginBottom: "7px" }}>{errMsg}</div>
    }

     initialSavedCardInfo: SavedCardDetails = {
      card_id: "",
      card_holder_name: "",
      card_number: "",
      exp_month: "",
      exp_year: "",
      is_primary: false,
      email: "",
      address: "",
      country: "",
      state: "",
      city: "",
      zip_code: "",
      card_brand: ""
  };

  // Customizable Area End
}

// Customizable Area End