// Customizable Area Start
import React from "react";

import {
    Box,
    styled,
    Typography,
    TextField,
    Button,
    OutlinedInput,
    IconButton
} from "@material-ui/core"


import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Loader from "../../../components/src/Loader.web";
import Footer from "../../../components/src/Footer.web";
import GoogleAutoComplete from "../../../components/src/GoogleAutoComplete.web";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClearIcon from '@material-ui/icons/Clear';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

import { apiKey } from "../../../components/src/asset";
import { editIcon } from "./assets";


import LocationDistancesController, {
    Props
} from "./LocationDistancesController.web";
export default class LocationDistances extends LocationDistancesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderAddressSteps = () => {
        switch (this.state.editAddressSteps) {
            case 1 :
                return this.renderSavedAddress()
            case 2 : 
                return this.renderNewAddress()
            case 3 : 
                return this.renderSetPrice()
        }
    }

    renderSavedAddress = () => {
        return (
            <Box className="locationBox">
                <Box>
                    <Typography className="mainAddressText">{this.state.catalogesAddress.address},{this.state.catalogesAddress.city},{this.state.catalogesAddress.country}, {this.state.catalogesAddress.pincode}</Typography>
                    <Typography className="subAddressText">Default pickup & return location </Typography>
                </Box>
                <img data-test-id="addressEdit" onClick={() => this.setAddressStep(2)} style={{ height: 20, width: 20, cursor: "pointer" }} src={editIcon} />
            </Box>
        )
    }

    renderNewAddress = () => {
        return (
            <Box className="locationBorderBox">
                <GoogleAutoComplete data-test-id="placesComponent" locationFields={this.handleAddressInfoFromPlacesApi} />
            </Box>
        )
    }

    renderSetPrice = () => {
        return (
            <Box className="outerBoxStep3">
                <Box className="flexBoxStep3">
                    <Box style={{ display : "flex", gap : 10}}>
                        <QueryBuilderIcon className="iconBox" />
                        <Typography className="priceText" noWrap>{this.state.newLocatiopn.location}</Typography>
                    </Box>
                    <IconButton data-test-id="closeIcon" onClick={this.discardClick}>
                        <ClearIcon className="iconBox" />
                    </IconButton>
                </Box>

                <Typography className="priceText">Set Price</Typography>
                <OutlinedInput
                    className="priceTextField"
                    fullWidth
                    value={this.state.newPrice}
                    onChange={this.handleSetPrice}
                    data-test-id="setPrice"
                />

                <Box className="btnPriceBox">
                    <Button onClick={this.discardClick} data-test-id="discardBtn" className='discardBtn'>Discard</Button>
                    <Button onClick={this.saveLocation} data-test-id="saveLocationBtn" className='saveBtn'>Save</Button>
                </Box>

            </Box>
        )
    }

    renderCarLocationSection = () => {
        return (
            <Box>

                <Typography className="subHeaderTextLocation">Car location</Typography>

                {this.renderAddressSteps()}

                <Typography className="subHeaderTextLocation">Delivery locations</Typography>

                {this.initialDeliveryLocationArray.map(item => 
                    
                    <Box className="locationBox" key={item.id}>
                    <Box style={{ display: "flex", gap: 10 }}>
                        <img src={item.icon} />
                        <Box>
                            <Typography className="mainAddressText">{item.title}</Typography>
                            <Typography className="subAddressText">{item.subTitle}</Typography>
                        </Box>
                    </Box>
                    
                    <img style={{ height: 20, width: 20, cursor: "pointer" }} src={editIcon} />
                </Box>
                )}

                <Typography className="subHeaderTextLocation">Distance Included</Typography>

                <Box className="distanceBox">
                    {this.state.distanceArray.map(item =>
                        <Box className="locationBox" key={item.id}>
                            <Typography className="mainAddressText">{item.title}</Typography>
                            {item.isEdit ?
                                <Box className="editBox">
                                    <TextField
                                        value={item.value}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDistanceChange(item.id, event.target.value)}
                                        data-test-id="eventChange"
                                        className='textField'
                                        InputProps={{ disableUnderline : true}}
                                    />

                                </Box>
                                :

                                <Box style={{ display: "flex", gap: 10 }}>
                                    <Typography className="valText">${item.value}</Typography>
                                    <img data-test-id="editImgBtn" onClick={() => this.handleDistanceClick(item.id)} style={{ height: 20, width: 20, cursor: "pointer" }} src={editIcon} />
                                </Box>
                            }
                        </Box>

                    )}
                    <Box className="btnBox">
                        <Button className='discardBtn' onClick={this.saveDistanceAndMileage} data-test-id="saveBtn">Discard</Button>
                        <Button className='saveBtn' onClick={this.saveDistanceAndMileage} data-test-id="saveBtn">Save</Button>
                    </Box>
                </Box>
                
            </Box>
        )
    }

    renderHomeLocationSection = () => {
        return (
            <Box>
                <Box>
                    <iframe
                        width="100%"
                        height="300"
                        frameBorder="0"
                        style={{ borderRadius: 12 }}
                        referrerPolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${this.state.catalogesAddress.latitude},${this.state.catalogesAddress.longitude}`}
                        allowFullScreen
                    ></iframe>
                </Box>

                <Typography className="subHeaderTextLocation">Home location</Typography>

                {this.renderAddressSteps()}
            </Box>
        )
    }


    render() {
        // Customizable Area Start

        return (
            <div>
                <AppHeader activeLink="Dashboard" navigation={this.props.navigation} />
                {this.state.loading ? <Loader loading /> :

                    <CustomBox>
                        <Box className="LocationSection">
                            <Box className="backSection" onClick={this.handleBackButton} data-test-id="back-button">
                                <ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} className="backIcon" />
                                <Typography className="backText">Back</Typography>
                            </Box>
                            <Box className="backSectionIcon">
                                <ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} className="backIcon" />
                            </Box>

                            <Typography className="headerTextLocation">Location & Distance</Typography>

                            {this.checkCatalogeType() ? this.renderCarLocationSection() : this.renderHomeLocationSection()}
                        </Box>
                    </CustomBox>
                }

                <Footer />
            </div>);
        // Customizable Area End
    }
}
const CustomBox = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .LocationSection": {
        width: "75%",
        "@media(max-width:600px)": {
            width: "90%"
        }
    },
    "& .backSection": {
        display: 'flex',
        alignItems: "center",
        color: "#667085",
        gap: "6px",
        cursor : "pointer",
        "@media(max-width: 600px)": {
            display: "none"
        }
    },
    "& .backSectionIcon": {
        display: "none",
        "@media(max-width: 600px)": {
            display: "block"
        }
    },
    "& .backIcon": {
        width: "20px",
        height: "20px"
    },
    "& .backText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",     
    },
    "& .headerTextLocation" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '30px',
        fontWeight: 600,
        color: '#101828',
        margin: "20px 0"
    },
    "& .subHeaderTextLocation" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#23395D',
        margin: "20px 0"
    },
    "& .locationBox": {
        border: "1px solid #D0D5DD",
        borderRadius : "12px",
        display : "flex",
        justifyContent : "space-between",
        padding : "25px",
        alignItems : "center",
        margin : "15px 0"
    },
    "& .mainAddressText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '18px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .subAddressText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: '#94A3B8',
    },
    "& .valText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .saveBtn": {
        height: 50,
        fontSize: '16px',
        color: 'white',
        textTransform: "none",
        fontWeight: 600,
        background: "#3173E1",
        borderRadius: "12px",
        fontFamily: 'Plus Jakarta Sans',
        padding: "11px 16px",
        width : "160px"
    },
    "& .distanceBox": {
        padding: 24,
        borderRadius: "12px",
        border: "1px solid #EAECF0",
    },
    "& .btnBox" : {
        display : "flex",
        gap : "15px",
        justifyContent : "end",
        margin: "20px 0"
    },
    "& .editBox" :{
        background: "#fff",
        padding: "10px",
        color: "#23395D",
        width: "100px",
        fontWeight: 600,
        textAlign: "center",
        borderRadius: 8,
        cursor: "pointer",
        border: "1px solid #E7E7E7"
    },
    "& .discardBtn" : {
        height: 50,
        fontSize: '16px',
        color: '#7B7B7B',
        textTransform: "none",
        fontWeight: 600,
        background: "white",
        borderRadius: "12px",
        fontFamily: 'Plus Jakarta Sans',
        padding: "14px 16px",
        width : "160px",
        border : "1px solid #979797"
    },
    "& .locationBorderBox": {
        border: "1px solid #D0D5DD",
        borderRadius : "12px",
        padding : "15px",
        alignItems : "center",
        margin : "15px 0"
    },
    "& .outerBoxStep3" : {
        border: "1px solid #E7E7E7",
        boxShadow: "0px 4px 10px 0px #00000014",
        padding : 15,
        borderRadius : 16
    },
    "& .flexBoxStep3" : {
        display : "flex",
        justifyContent : "space-between"
    },
    "& .iconBox" : {
        color : "#23395D",
        height : 20,
        width: 20
    },
    "& .btnPriceBox" : {
        display : "flex",
        gap : "15px",
        margin: "10px 0"
    },
    "& .priceTextField" : {
        fontFamily: 'Plus Jakarta Sans',
        height: 40,
        fontSize: "14px",
        color: "#475467",
        borderRadius: "12px",
        fontWeight: 600
    },
    "& .priceText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: '#23395D',
    }

})

// Customizable Area End