// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";

import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from "../../../components/src/asset";
import { TicketCatogory, TicketsData } from "../../../components/src/TypeInterfaces.web";
import { toast } from "react-toastify"
import moment from "moment"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  createTicketModal : boolean
  loading : boolean,
  ticketCatogories : TicketCatogory[],
  availableTickets : TicketsData[],
  previousTickets : TicketsData[],
  ticketTitle : string,
  selectedTicketCatogry : string,
  ticketMessage : string
  showError : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TicketSuportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTicketCatogoryApiCallId : string = ""
  saveTicketApiCallId : string = ""
  getTicketApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      createTicketModal : false,
      ticketCatogories : [],
      selectedTicketCatogry : "",
      ticketTitle: "",
      ticketMessage : "",
      availableTickets : [],
      previousTickets : [],
      loading : true,
      showError : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getTicketCatogoryApiCallId :
           return  this.handleGetTicketCatogoryResponse(responseJson)
        case this.getTicketApiCallId :
           return this.handleGetTicketResponse(responseJson)
        case this.saveTicketApiCallId :
            return this.handleSaveTicketResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getTicketCatogoryData()
    this.getTicketData()
  }

  getTicketData = () => {
    const header = {
      'token': localStorage.getItem("userToken")
    }

    this.getTicketApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getTicketsApiEndPoint
    })
  }

  getTicketCatogoryData = () => {
    const header = {
      'token': localStorage.getItem("userToken")
    }

    this.getTicketCatogoryApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getTicketCatogoryApiEndPoint
    })
  }

  saveTicketData = () => {

    if (this.state.ticketTitle.trim() === "" || this.state.ticketMessage.trim() === "" || this.state.selectedTicketCatogry.trim() === "") {
        this.setState({ showError : true})
        return false
    }

    this.setState({ loading : true})

      const header = {
        'token': localStorage.getItem("userToken")
      }

      const formBody = new FormData()

      formBody.append("ticket[title]", this.state.ticketTitle)
      formBody.append("ticket[message]", this.state.ticketMessage)
      formBody.append("ticket[ticket_category_id]", this.state.selectedTicketCatogry)

      
  
      this.saveTicketApiCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.postApiMethodType,
        endPoint: configJSON.saveTicketsApiEndPoint,
        body : formBody
      })
  }

  handleGetTicketCatogoryResponse = (responseJson : any) => {
     if(responseJson && responseJson.data && responseJson.data.length > 0) {
       const receivedArr = responseJson.data.map((item: any) => {
         return {
           id: truthyValue(item.id),
           label: truthyValue(item.attributes.name)
         }
       })

       this.setState({ ticketCatogories : receivedArr})
     }
  }

  handleGetTicketResponse = (responseJson : any) => {
    if(responseJson && responseJson.data && responseJson.data.length > 0) {
      const availableTickets = responseJson.data.filter((item : any) => item.attributes.status === this.ticketStatus.opneStatus).map((item: any) => {
        return {
          ticketId: truthyValue(item.id),
          ticketTitle: truthyValue(item.attributes.title),
          ticketCatogory:truthyValue(item.attributes.ticket_category.data) !== null ? truthyValue(item.attributes.ticket_category.data.attributes.name) : "",
          ticketStatus: truthyValue(item.attributes.status),
          ticketMessage: truthyValue(item.attributes.message),
          ticketTime: this.formateCreatedTime(truthyValue(item.attributes.created_at))
        }
      })

      const previousTickets = responseJson.data.filter((ticketItem : any) => ticketItem.attributes.status !== this.ticketStatus.opneStatus).map((ticketItem: any) => {
        return {
          ticketId: truthyValue(ticketItem.id),
          ticketTitle: truthyValue(ticketItem.attributes.title),
          ticketCatogory: truthyValue(ticketItem.attributes.ticket_category.data) !== null ? truthyValue(ticketItem.attributes.ticket_category.data.attributes.name) : "",
          ticketStatus: truthyValue(ticketItem.attributes.status),
          ticketMessage: truthyValue(ticketItem.attributes.message),
          ticketTime: this.formateCreatedTime(truthyValue(ticketItem.attributes.created_at))
        }
      })

      this.setState({ availableTickets, previousTickets, loading : false })
    } else {
       this.setState({ loading : false})
    }
 }

  handleSaveTicketResponse = (responseJson : any) => {
    if (responseJson && responseJson.data) {
      this.handleCloseTicketModal()
      this.getTicketData()
      toast.success("Ticket Created Successfully")
    } else {
      this.handleCloseTicketModal()
      toast.error("Something went wrong try again later.")
      this.setState({ loading : false})
    }
 }

  ticketStatus = {
     opneStatus : "open",
     closedStatus : "resloved"
  }

  handleOpenTicketModal = () => {
    this.setState({ createTicketModal: true})
  }

  handleCloseTicketModal = () => {
    this.setState({ 
      createTicketModal: false,
      ticketMessage : "",
      ticketTitle : "",
      selectedTicketCatogry : "",
      showError : false
    })
  }

  navigateToSupportCenter = () => {
    this.props.navigation.navigate("SupportCenterSettings")
  }

  navToChatPage = (id : string) => {
     this.props.navigation.navigate("SupportChat", { chatId : id})
  }

  handleTicketCatogoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const inputValue = event.target.value as string
    this.setState({
      selectedTicketCatogry: inputValue
    })

  }

  handleTicketTitleTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ticketTitle : event.target.value})
  }

  handleTicketMessageTexField = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ticketMessage : event.target.value})
  }

  formateCreatedTime = (timeStamp : string) => {
    const formattedTime = moment(timeStamp).calendar(null, {
      sameDay: '[Today at] h:mma',
      nextDay: '[Tomorrow at] h:mma',
      nextWeek: 'dddd [at] h:mma',
      lastDay: '[Yesterday at] h:mma',
      lastWeek: '[Last] dddd [at] h:mma',
      sameElse: 'MM/DD/YYYY'
    });

    return formattedTime
  }

  // Customizable Area End
}

// Customizable Area End