export const cartImage= require("../assets/CartImage.png");
export const starIcon= require("../assets/Stars.png");
export const HeartIcon= require("../assets/heart.png");
export const ArrowLeft= require("../assets/arrow-left.png");
export const ArrowRight= require("../assets/image_.png");
export const BedIcon= require("../assets/BedIcon.png");
export const LocationIcon= require("../assets/LocationIcon.png");
export const ShowerIcon= require("../assets/ShowerIcon.png");
export const cardViewIcon= require("../assets/cardViewIcon.svg");
export const dropDown= require("../assets/dropDown.svg");
export const filterIcon= require("../assets/filterIcon.svg");
export const mapViewIcon= require("../assets/mapViewIcon.svg");
export const sortIcon= require("../assets/sort.svg");
export const ModalCloseIcon=require("../assets/ModalCloseIcon.svg");
export const FuelIcon= require("../assets/FuelType.svg");
export const SeatOption=require("../assets/SeatOption.svg");
export const AddDates= require("../assets/AddDates.svg");
export const Guest= require("../assets/Guest.svg");
export const CarTab=require("../assets/car-tab.svg");
export const HomeTab= require("../assets/home-tab.svg");
export const Location=require("../assets/location.svg");
export const Search=require("../assets/search.svg");
export const bookingIcon = require("../assets/bookingIcon.svg")
export const upcomingIcons = require("../assets/icon4.svg")
export const settingIcon = require("../assets/icon2.svg")
export const helpcareIcon = require("../assets/icon3.svg")
export const homeLogoIcon = require("../assets/homeLogo.svg")
export const fullImgLogo = require("../assets/fullSvg.svg")
export const hostBtnLogo = require("../assets/becomeHostBtn.svg")
export const list1 = require("../assets/list1.svg")
export const list2 = require("../assets/list2.svg")
export const list3 = require("../assets/list3.svg")
export const list4 = require("../assets/list4.svg")
export const listHomeIcon = require("../assets/homeIconList.svg")
export const listCarIcon = require("../assets/carIconList.svg")
export const bgImageIcon = require("../assets/bgImage.png")
export const payoutIcon = require("../assets/payOut.png")
export const bluetoothIcon = require("../assets/bluetooth.svg")
export const appleIcon = require("../assets/apple.svg")
export const keyIcon = require("../assets/keyless.svg")
export const seatIcon = require("../assets/seat.svg")
export const androiadIcon = require("../assets/androiad.svg")
export const cameraIcon = require("../assets/camera.svg")
export const bikeIcon = require("../assets/bike.svg")
export const alarmIcon = require("../assets/alarm.svg")
export const imageIcons = require("../assets/image_camera.svg")
export const congratsIcon = require("../assets/congrats.png")
export const deleteIcon = require("../assets/deleteIcon.svg")
export const messageIcon = require("../assets/messageIcon.png")
export const cardIcon = require("../assets/cardIcon.png")
export const skipVectorIcon = require("../assets/skipVector.png")
export const handIcon = require("../assets/handIcon.png")
export const scanDL = require("../assets/scanDL.png")
export const scanFC = require("../assets/scanFC.png")
export const house1 = require("../assets/house1.png")
export const house2 = require("../assets/house2.png")
export const house3 = require("../assets/house3.png")
export const house4 = require("../assets/house4.png")
export const house5 = require("../assets/house5.png")
export const house6 = require("../assets/house6.png")
export const addHomeIcons1 = require("../assets/addHomeIcons/icon1.svg")
export const addHomeIcons2 = require("../assets/addHomeIcons/icon2.svg")
export const addHomeIcons3 = require("../assets/addHomeIcons/icon3.svg")
export const addHomeIcons4 = require("../assets/addHomeIcons/icon4.svg")
export const addHomeIcons5 = require("../assets/addHomeIcons/icon5.svg")
export const addHomeIcons6 = require("../assets/addHomeIcons/icon6.svg")
export const addHomeIcons7 = require("../assets/addHomeIcons/icon7.svg")
export const addHomeIcons8 = require("../assets/addHomeIcons/icon8.svg")
export const addHomeIcons9 = require("../assets/addHomeIcons/icon9.svg")
export const addHomeIcons10 = require("../assets/addHomeIcons/icon10.svg")
export const addHomeIcons11 = require("../assets/addHomeIcons/icon11.svg")
export const plusMinusIcon = require("../assets/pmIcons.svg")
export const editIcon = require("../assets/editIcon.png")
export const carLocationIcon = require("../assets/carLocationIcon.png")
export const homeLocationIcon = require("../assets/homeLocationIcon.png")
export const headerTextIcon = require("../assets/headerText.png")
export const receivedIcon = require("../assets/receivedIcon.png")
export const timelineIcon = require("../assets/timelineImg.png")
export const carImageIcon = require("../assets/hostReservationsIcons/carImage.png")
export const homeImageIcon = require("../assets/hostReservationsIcons/homeImage.png")
export const skipImageIcon = require("../assets/hostReservationsIcons/skipIcon.svg")
export const chatBoxIcon = require("../assets/hostReservationsIcons/chatBox.svg")
export const flightIcon = require("../assets/hostReservationsIcons/flightIcon.svg")
export const addPhotosIcon = require("../assets/hostReservationsIcons/addPhotosIcon.svg")
export const filterReservationIcon = require("../assets/hostReservationsIcons/filterIcon.svg")
export const editFileIcon = require("../assets/hostReservationsIcons/editFileIcon.svg")
export const earningGraph = require("../assets/earningGraph.png")
export const rateIcon = require("../assets/rateIcon.png")
export const reload = require("../assets/reload_button.png")
export const download = require("../assets/download_button.png")
export const greenTick = require("../assets/green_tick.png")
export const lightTick = require("../assets/light_tick.png")
export const arrowRight = require("../assets/arrow.png")
export const carPic = require("../assets/car.png")
export const homePic = require("../assets/home.png")
export const carUrus = require("../assets/carUrus.png")
export const carSedan = require("../assets/carSedan.png")
export const house = require("../assets/house.png")
export const msgIcon = require("../assets/msgIcon.png")
export const emojiIcon = require("../assets/emojIcon.png")
export const replyIcon = require("../assets/replyIcon.png")
export const payoutModuleIcon = require("../assets/hostSettings/payOutSchedule.png")
export const traHistoryModuleIcon = require("../assets/hostSettings/transactionHistory.png")
export const taxInfoModuleIcon = require("../assets/hostSettings/taxInfo.png")
export const claimsModuleIcon = require("../assets/hostSettings/claims.png")
export const upIcon = require("../assets/hostSettings/upIcon.png")
export const downIcon = require("../assets/hostSettings/downIcon.png")
export const filterButton = require("../assets/hostSettings/filterButton.png")
export const editFile = require("../assets/editField.png")
export const deliveryPlane = require("../assets/deliveryPlane.png")
export const deliveryHome = require("../assets/deliveryHome.png")
export const deliveryRail = require("../assets/deliveryRail.png")
export const editFileBlack = require("../assets/editFieldBlack.png")
export const instructionIcon = require("../assets/listingSettings/instructionIcon.svg")
export const locationIcon = require("../assets/listingSettings/locationIcon.svg")
export const protectionIcon = require("../assets/listingSettings/protectionIcon.svg")
export const protectionImg = require("../assets/listingSettings/protection.png")
export const addIcon = require("../assets/addIcon.png")
export const tripEndIcon = require("../assets/hostReservationsIcons/tripEnd.svg")
export const downloadIcon = require("../assets/downloadIcon.svg")



