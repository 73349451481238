// Customizable Area Start
import React from "react";
import CommonReservationInfo from "../../../components/src/CommonReservationInfo.web"
import Chat from "../../../blocks/chat/src/ReservationChat.web"
import { AntSwitch, CustomSlider } from "../../../components/src/FilterModal.web";

import {
    Box,
    styled,
    Tabs,
    Tab,
    Typography,
    Button,
    Grid,
    OutlinedInput,
} from "@material-ui/core"

import { flightIcon, tripEndIcon, imageIcons, deleteIcon } from "./assets";
import LocalGasStationOutlinedIcon from '@material-ui/icons/LocalGasStationOutlined';
import { defaultProfileIcon } from "../../../components/src/asset";

import HostPostTripController, {
    Props,
  } from "./HostPostTripController";

class HostPostTrip extends HostPostTripController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderImagePreview = () => {
    return (
        <Box style={{ margin: "25px 0" }}>
            <Grid container spacing={3}>
                {this.state.imagesUrlArr.map((url, index) => (
                    <Grid item md={4} sm={6} xs={12} key={index} style={{ position: "relative" }}>
                        <img data-test-id="deleteUploadedPhoto" onClick={() => this.deleteImages(index)} style={{ position: "absolute", top: 30, right: 30, cursor: "pointer" }} src={deleteIcon} alt="deleteIcon" />
                        <img key={index} src={url} alt={`preview-${index}`} style={{ height: '130px', width: "100%", borderRadius: 16 }} />
                    </Grid>
                ))}
            </Grid>

            <Box style={{ padding: 10, border: "1px dashed #3173E1", borderRadius: 12, textAlign: 'center', margin: "10px 0" }}>
                <Typography className="addMoreText"> Add More <span data-test-id="addMoreText" onClick={() => this.handleRefs()} style={{ color: "#3173E1", textDecoration: "underline", cursor: "pointer" }}>Photo(s)</span> </Typography> </Box>
            <input type="file" data-test-id={"addMorePhotos"} onChange={this.handleImgChange} ref={this.imageRefs} multiple style={{ display: "none" }} />

        </Box>
    )
  }

  renderUploadImagesSection = () => {
      return (
          <Box className="imagesPostTripBox">
              {this.state.isPhotoUploaded ?
                  <Box style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                      <img src={imageIcons} alt="img" />
                      <Button data-test-id="addImg" onClick={() => this.handleTogglePreview()} variant="outlined" style={{ borderRadius: 20, textTransform: "none", margin: "10px 0", color: "#3173E1" }}> Add Photos </Button>
                  </Box>
                  :
                  <Box style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                      <Typography className="addMoreText">Drag and drop or  <span style={{ color: "#3173E1", textDecoration: "underline", cursor: "pointer", fontWeight: 600 }} data-test-id="uploadImages" onClick={() => this.handleRefs()}> browse </span> files</Typography>
                      <input type="file" data-test-id={"imagesChange"} onChange={this.handleImgChange} ref={this.imageRefs} multiple style={{ display: "none" }} />
                  </Box>
              }
          </Box>
      )
  }

  renderInfoPhase = () => {
    return(
        <Box>
            <Box style={{ position : "relative"}}>
                <Tabs
                    className="mainTabsDiv"
                    value={this.state.tabValue}
                    data-test-id="tabs"
                    onChange={this.handleTabChange}
                >
                    <Tab label={this.getTabText()} className="tabLabelText"></Tab>
                    <Tab label="Messages" className="tabLabelText"></Tab>
                </Tabs>

                <Box className="countMessageBox">
                    <Typography className="countMessagesText">{this.state.msgCount}</Typography>
                </Box>
            </Box>

            {this.state.tabValue === 0 && 
                <Box>
                    <Box className="squareBox">
                        <Box style={{ display: "flex", gap: 15, alignItems: "center" }}>
                            <img src={flightIcon} />
                            <Box>
                                <Typography className="textInfo1">LAX International Airport, Terminal 5</Typography>
                                <Typography className="textInfo2">Flight: AA 2570</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box className="squareBox">
                        <Box>
                            <Typography className="textInfo1">Trip add-ons</Typography>
                            <Typography className="textInfo2">Extras requested by renter</Typography>
                        </Box>
                        {this.state.catalougeType === "Car" ?
                            <Box>
                                <Typography className="infoColorText">Pre-Paid Fuel</Typography>
                            </Box>
                            :
                            <Box>
                                <Typography className="infoColorText">$0</Typography>
                                <Typography className="textInfo3">+$0 deposit</Typography>
                            </Box>

                        }

                    </Box>

                    <Box className="squareBox">
                        <Box>
                            <Typography className="textInfo1">Trip cost</Typography>
                            <Typography className="textInfo2">Click for breakdown</Typography>
                        </Box>
                        <Box>
                            <Typography className="infoColorText">$0</Typography>
                            <Typography className="textInfo3">+$0 deposit</Typography>
                        </Box>
                    </Box>

                    <Box className="squareBox">
                        <Box>
                            <Typography className="textInfo1">Total miles included</Typography>
                            <Typography className="textInfo2">$3.50 / each additional mi</Typography>
                        </Box>
                        <Box>
                            <Typography className="infoColorText">$0</Typography>
                            <Typography className="textInfo3">+$0 deposit</Typography>
                        </Box>
                    </Box>

                    <Box className="squareBox">
                        <Typography className="textInfo1">Your Trip Ends in: <span className="infoColorText"> {this.state.tripEndDuration} Days </span></Typography>
                        <Button className="modifyBtn">Modify Trip</Button>
                    </Box>

                    <Box className="squareBox bgSquareBox">
                        <Box style={{ display: "flex", gap: "10px", alignItems: "center"}}>
                            <img src={tripEndIcon} alt="tripEndIcon" />
                            <Box>
                                <Typography className="textInfo1">End Trip</Typography>
                                <Typography className="textInfo2">Your trip is ending soon. To end the trip please follow the check-in steps</Typography>
                            </Box>
                        </Box>
                        <Button className="modifyBtn" data-test-id="startCheckInBtn" onClick={this.changeImgPhase} style={{ width : 160, background: "#EDF4FF"}}>Start Check-In</Button>
                    </Box>
                </Box>
            }

            {this.state.tabValue === 1 &&
                <Box>
                    <Chat
                        navigation={this.props.navigation}
                        id="hostPostTrip"
                        receiverId={this.state.conversationId}
                        updateCount={(count) => this.setUnReadCountFn(count)}
                    />
                </Box> 
            }
        </Box>
    )
  }

  renderImagesPhase = () => {
    return(
        <Box>
            <Box>
                <Typography className="newImgText">Add new photos</Typography>
                <Typography className="newImgSubText">Please take at least 10 photos to document the car's condition. Include interior, exterior, rims, odometer and fuel level photos.</Typography>
            </Box>

            {this.state.imagesUrlArr.length === 0 ? this.renderUploadImagesSection() : this.renderImagePreview()}

            <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
                <Button data-test-id="backBtn" onClick={this.backInfoPhase} className="backPostTripBtn">Back</Button>
                <Button data-test-id="nextBtn" onClick={this.changeEndPhase} className="nextPostTripBtn">Continue</Button>
            </Box>
        </Box>
    )
  }

  renderEndTripPhase = () => {
    return(
        <Box>

            <Box className="mileageBox">
                <Typography className="labelText" style={{ marginBottom: "8px" }}>Mileage Odometer</Typography>
                <OutlinedInput
                    className="milegeTextField"
                    placeholder="Enter Odometer"
                    value={this.state.odometerVal}
                    data-test-id="odometerVal"
                    onChange={this.handleOdometerTextField}
                    fullWidth
                />
            </Box>

            <Box>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Box className="fuleBox">
                            <Box className="whiteBgBox">
                                <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                    <LocalGasStationOutlinedIcon style={{ height: 20, width: 20 }} />
                                    <Typography className="fuelText">Fuel Level </Typography>
                                </Box>
                                <Box className="sliderBox">
                                    <CustomSlider
                                        step={0.5}
                                        defaultValue={0}
                                        marks
                                        min={0}
                                        max={5}
                                        valueLabelDisplay="auto"
                                        style={{ marginTop: "45px", marginBottom: "25px" }}
                                        value={this.state.fuelLevel}
                                        data-test-id="fuelLevel"
                                        onChange={this.handleFuelLevelChange}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        <Box className="driverBox">
                            <Box className="grayBgBox">
                                 <img src={defaultProfileIcon} className="profileImgStyle" />
                                 <Box>
                                    <Typography className="nameText">Full Name:</Typography>
                                    <Typography className="nameText">DOB:</Typography>
                                    <Typography className="nameText">License #:</Typography>
                                 </Box>
                                 <Box>
                                    <Typography className="valueText">Emmanuel Ackers</Typography>
                                    <Typography className="valueText">04/16/1997</Typography>
                                    <Typography className="valueText">A2624152</Typography>
                                 </Box>
                            </Box>
                            <Box className="btnBox">
                                <Button fullWidth className="denyBtn">Deny</Button>
                                <Button fullWidth className="confirmBtn">Confirm</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                
            </Box>

            <Box className="mileageBox">
                  <Typography className="labelText" style={{ marginBottom: "8px"}}>Provide Keys</Typography>
                  <Box className="keyBox">
                        <Typography className="labelText" style={{ fontSize: "14px"}}>Renter has recieved the Car Keys</Typography>
                        <AntSwitch value={this.state.keyProvided}  onChange={this.handleKeySwitch} data-test-id="keySwitch"/>
                  </Box>
               </Box>


            <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
                <Button data-test-id="backBtn" onClick={this.backImgPhase} className="backPostTripBtn">Back</Button>
                <Button data-test-id="nextBtn" className="nextPostTripBtn" onClick={this.saveCheckoutInfo}>End Trip</Button>
            </Box>
        </Box>
    )
  }

    handleAllPhase = () => {
        switch (this.state.renderSection) {
            case this.tripSection.infoPhase:
                return this.renderInfoPhase()
            case this.tripSection.photosPhase:
                return this.renderImagesPhase()
            case this.tripSection.endPhase:
                return this.renderEndTripPhase()
        }
    }

  render() {
    // Customizable Area Start
    
    return (
      <div>
        <CommonReservationInfo
          navigation={this.props.navigation}
          id="HostPostTrip"
          pageTitle={this.state.pageTitle}
        >
          <PostTripWrapper>
            {this.handleAllPhase()}
          </PostTripWrapper>
        </CommonReservationInfo>
      </div>
    );
    // Customizable Area End
  }
}

export default HostPostTrip;

const PostTripWrapper = styled(Box)({
    "& .squareBox" : {
        padding: "20px",
        borderRadius: "12px",
        alignItems: "center",
        border: "1px solid #DADBDE",
        justifyContent: "space-between",
        display: "flex",
        margin:"25px 0"
    }, 

    "& .textInfo1" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .textInfo2" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '12px',
        color: '#2A5591',
    },
    "& .textInfo3" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        color: '#94A3B8',
        fontSize: '12px',
    },
    "& .bgSquareBox" : {
        background: "#EDF4FF",
        border: "none !important"
    }, 
    "& .newImgText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '30px',
        color: '#23395D'
    },
      "& .newImgSubText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#475467'
    },
    "& .nextPostTripBtn" : {
        width: '150px',
        height: '48px',
        textTransform: "none",
        gap: '8px',
        backgroundColor: '#3173E1',
        borderRadius: '12px',
        margin: "15px 0",
        border: "1px solid #D0D5DD",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: 'white',
        padding: '14px 16px',
      },
      "& .backPostTripBtn" : {
        width: '150px',
        borderRadius: '12px',
        backgroundColor: 'white',
        padding: '14px 16px',
        textTransform: "none",
        gap: '8px',
        height: '48px',
        color: '#475467',
        border: "1px solid #D0D5DD",
        margin: "15px 0",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
      },
      "& .imagesPostTripBox" : {
        height: "300px",
        borderRadius: "12px",
        border: "1px dashed #3173E1",
        display: "flex",
        margin: "20px 0"
      },
      "& .addMoreText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#475467'
      },
      "& .labelText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .mileageBox" : {
        margin: "15px 0"
    },
    "& .milegeTextField" : {
        height: "45px",
        borderRadius: "12px",
        border: "1px solid #D0D5DD"
    },
    "& .keyBox": {
        padding: "15px 20px",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "12px",
        border: "1px solid #EAECF0",
        alignItems: "center"
    },
    "& .fuleBox" : {
        boxShadow: "0px 4px 5px 0px #0000000D",
        border: "1px solid #DDE0E6",
        borderRadius: "12px",
        padding: "10px"
    },
    "& .fuelText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 700,
        color: '#23395D'
    },
    "& .driverBox" : {
        boxShadow: "0px 4px 5px 0px #0000000D",
        border: "1px solid #DDE0E6",
        borderRadius: "12px",
        padding: "10px",
    },
    "& .grayBgBox" : {
        background: "#E3ECFC",
        borderRadius: "12px",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "20px"
    },
    "& .whiteBgBox" : {
        background: "white",
        borderRadius: "12px",
        padding: "20px"
    },
    "& .profileImgStyle" : {
        height: 50,
        width: 50,
        borderRadius: "50%"
    },
    "& .nameText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: '#23395D'
    },
    "& .valueText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#23395D'
    },
    "& .btnBox" : {
        display : "flex",
        gap : "10px",
        margin: "10px 0"
    },
    "& .denyBtn" : {
        borderRadius: '12px',
        backgroundColor: 'white',
        textTransform: "none",
        gap: '8px',
        height: '40px',
        color: '#3173E1',
        border: "1px solid #3173E1",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
    },
    "& .confirmBtn" : {
        borderRadius: '12px',
        height: '40px',
        backgroundColor: '#3173E1',
        textTransform: "none",
        gap: '8px',
        color: 'white',
        border: "1px solid #3173E1",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
    },
    "& .sliderBox" : {
        padding: "0 15px"
    },
    "& .countMessageBox": {
        position: "absolute",
        background: "#EAECF0",
        borderRadius: "16px",
        padding: "2px 10px",
        left: "300px",
        top: "12px"
      },
      "& .countMessagesText": {
          fontSize: '14px',
          color: '#344054',
          fontWeight: 500,
          fontFamily: 'Plus Jakarta Sans',
      },
})

// Customizable Area End