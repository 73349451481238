// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.itemListApiContentType = "application/json";
exports.urlGetItemList = "bx_block_order_reservations/reservation_services"
exports.itemListApiMethodType = "GET";

exports.urlSecureReservationList = "bx_block_order_reservations/account_orders";
exports.secureReservationApiMethodType = "POST";

exports.urlGetReservationHistory = "bx_block_order_reservations/account_orders"
exports.getReservationHistoryApiMethodType = "GET";

exports.urlEditReservation = "bx_block_order_reservations/account_orders/"
exports.editReservationApiMethodType = "PUT";

exports.urlCancelReservation = "bx_block_order_reservations/account_orders/"
exports.cancelReservationApiMethodType = "DELETE";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Reservations";
exports.labelBodyText = "Reservations Services List";
exports.txtInputPlaceholder = "quantity";
exports.totalPrice = "Total Price";
exports.edit = "Edit";
exports.delete = "Cancel";
exports.reservationStatus = "Reservation Status";

exports.btnExampleTitle = "Secure Reservation";
exports.btnEditTitle = "Edit";
exports.btnCancelTitle = "Cancel";
exports.btnHistoryExampleTitle = "Go To Reservation History";
exports.textquantityplaceholder = "quantity";

exports.txtOrderId = "Order id";
exports.orderPlacedDate = "Order Date";
exports.sortBy = "Sort By";
exports.search = "Search";
exports.accountsAPiEndPoint = "bx_block_login/logins"
exports.contentType="application/json"
exports.apiTypeGet="GET"
exports.apiTypePost="POST"
exports.upcomingReservationsApiEndPoint="bx_block_calendar/upcoming_booked_trip"
exports.getReservationHistoryApiEndPoint="bx_block_calendar/renter_completed_bookings"
exports.getReservationDetailApiEndPoint="bx_block_calendar/booked_slots"
exports.saveCheckoutApiEndPoint="bx_block_calendar/booking_checkout_photos"
exports.getUserProfileApiEndPoint="account_block/accounts/get_user"
exports.getRenterProfileApiEndPoint="bx_block_calendar/renter_profile"
exports.getChatCountApiEndpoint="bx_block_privatechat/conversations"
exports.satrtTripApiEndPoint="bx_block_calendar/start_trip"
exports.getPriceBreakdownApiEndPoint="bx_block_calendar/trip_cost_breakdown"
exports.getInstructionApiEndpoint = (catalogueId) => `bx_block_catalogue/catalogues/${catalogueId}/guest_instruction`
exports.renterCheckInApiEndPoint = (bookedSlotId) => `bx_block_calendar/booked_slots/${bookedSlotId}/checkin_detail_by_renter`
exports.renterCheckOutApiEndPoint = (bookedSlotId) => `bx_block_calendar/booked_slots/${bookedSlotId}/checkout_detail`
exports.getReferalApiEndPoint = "bx_block_stripe_integration/payment_methods/get_referals"
exports.saveReviewsApiEndPoint = "bx_block_catalogue/reviews"
exports.getRenterReportedIssueApiEndPoint = "bx_block_calendar/renter_issues"
exports.saveRenterReportIssueApiEndPoint = "bx_block_calendar/renter_issues"

// Customizable Area End
