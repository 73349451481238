import React from "react";
import PostHomeRenterController, {
    Props
} from "./PostHomeRenterController.web";
import {
    Box,
    styled,
    Typography,
    Button,
    Grid,
    Tabs, 
    Tab,
    Divider,
    TextField,
    Stepper,
    Step,
    StepLabel,
    StepContent
 } from "@material-ui/core"
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


const images = require("./assets")

export default class AccountCreation extends PostHomeRenterController{
    constructor(props: Props){
        super(props)
    }

    render() {
        const { getCheckoutDetails } = this.state
        return(
            <MainDiv>
                <AppHeader navigation={this.props.navigation} activeLink="Dashboard" />

                <Grid className="mainPostRenterGrid">
                    <Grid container className="bookedHomeScreen">
                        <Box data-test-id="goback" className="backSection" onClick={this.handleBack}>
                            <ArrowBackIosIcon className="backIcon" />
                            <Typography className="backText">Back</Typography>
                        </Box>
                        <Typography className="bookedTripText">
                            Booked Trip
                        </Typography>

                        <Box className="imageDescBox">
                            <img src={images.Home} className="homeImage"/>
                            <Box className="homeNameBox">
                                <Typography className="homeName">
                                    Home Name
                                </Typography>
                                <Box className="ratingAndReviewBox">
                                    <img src={images.starIcon} />
                                    <Typography className="ratingText">4.8</Typography>
                                    <Typography className="reviewsText">44 reviews</Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box className="tabBox">
                            <Tabs
                                className="mainTabs"
                                value={this.state.tabValue}
                                onChange={this.handleTabChange}
                                data-test-id="tabsChange"
                            >
                                <Tab label="Home Details" className="tabText"></Tab>
                                <Tab label="Messages" className="tabText"></Tab>
                                <Tab label="Receipt" className="tabText"></Tab>
                            </Tabs>
                        </Box>

                        {this.state.tabValue === 0 && 
                        <>
                        <Box className="tripCostBox">
                            <Box>
                                <Typography className="tripCostHeading">Trip cost</Typography>
                                <Typography className="clickForBreakdownText">Click for breakdown</Typography>
                            </Box>
                            <Typography className="tripCostvalue">$840</Typography>
                        </Box>
                        <Box className="tripCostBox">
                            <Typography className="tripEndText">Your Trip Ends in: <span className="numberOfDays">2 Days</span></Typography>
                            <Button className="modifyTripBtn">Modify Trip</Button>
                        </Box>
                        <Box className="tripCostBoxInfo">
                            <Box className="infoiconBox">
                                <img src={images.infoIcon} />
                                <Box>
                                    <Typography className="endTripText">End Trip</Typography>
                                    <Typography className="tripEndingMessage">Your trip is ending soon. To end the trip please follow the check-out steps</Typography>
                                </Box>
                            </Box>
                            <Button className="startCheckoutButton">Start Check-Out</Button>
                        </Box>
                        </>
                        } 
                    </Grid>

                    <div className="mainLeftBar">
                        <Box className="firstSection">
                            <Box className="tripEndBox">
                                <Typography className="tripEndTextLeft">
                                    Your trip ends soon: <span className="tripEndHours">2 hours</span>
                                </Typography>
                                <Box className="tripBtnBox">
                                    <Button className="modifyTripLeftBtn"><img src={images.modifyTrip} className="modifyEditImage"/>Modify Trip</Button>
                                    <Button className="endTripBtn">End Trip</Button>
                                </Box>
                            </Box>
                            <Box className="reservationSummaryBox">
                                <Typography className="reservationSummaryText">Reservation Summary</Typography>
                                <Divider className="dividerLeft" />
                                <Typography className="pickUpLocationText">Pick-up Location</Typography>
                                <TextField 
                                    className="pickUpLocationField"
                                    placeholder="New York, NY"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                />

                                <Typography className="pickUpLocationText">Check - in</Typography>
                                <TextField 
                                    className="pickUpLocationField"
                                    placeholder="Oct 12th, 23, 10:30am"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    type="date"
                                />

                                <Typography className="pickUpLocationText">Check - out</Typography>
                                <TextField 
                                    className="pickUpLocationField"
                                    placeholder="Oct 12th, 23, 10:30am"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    type="date"
                                />

                                <Typography className="pickUpLocationText">Guest</Typography>
                                <TextField 
                                    className="pickUpLocationField"
                                    placeholder="2"
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                />
                            </Box>
                            <Box className="tripCostBreakdownBox">
                            <Typography className="reservationSummaryText">Trip cost breakdown</Typography>
                            <Divider className="dividerLeft" />
                                <Stepper activeStep={3} orientation="vertical" className="stepperTransaction">
                                    <Step>
                                        <StepLabel>
                                            <Box className="stepLabelBox">
                                                <Box>
                                                    <Typography className="stepLabelHeading">
                                                        Rental Payment
                                                    </Typography>
                                                    <Typography className="rentalDuration">
                                                        $1,400 / Night <span className="rentalTime">(x3 Night)</span>
                                                    </Typography>
                                                </Box>
                                                <Box className="rentalPrice">
                                                    $4,200
                                                </Box>
                                            </Box>
                                        </StepLabel>
                                        <StepContent></StepContent>
                                    </Step>
                                    <Step>
                                        <StepLabel>
                                            <Box className="stepLabelBox">
                                                <Box>
                                                    <Typography className="stepLabelHeading">
                                                        Stay Fees
                                                    </Typography>
                                                    <Typography className="rentalDuration">
                                                        Extended Trip Discount <span className="rentalTime">(5%)</span>
                                                    </Typography>
                                                </Box>
                                                <Box className="rentalPrice">
                                                    $210
                                                </Box>
                                            </Box>
                                        </StepLabel>
                                        <StepContent></StepContent>
                                    </Step>
                                    <Step>
                                        <StepLabel>
                                            <Box className="stepLabelBox">
                                                <Box>
                                                    <Typography className="stepLabelHeading">
                                                        House Tax
                                                    </Typography>
                                                    <Typography className="rentalDuration">
                                                        Refunded by <span className="rentalTime">Oct 28</span>
                                                    </Typography>
                                                </Box>
                                                <Box className="rentalPrice">
                                                    $500
                                                </Box>
                                            </Box>
                                        </StepLabel>
                                        <StepContent></StepContent>
                                    </Step>
                                </Stepper>
                                <Divider className="dividerLeft" />
                                <Box className="total">
                                    <Typography className="totalReceived">
                                        Total (USD)
                                    </Typography>
                                    <Typography className="totalPrice">
                                        $840
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        {/* <Box className="secondSection">
                            second section
                        </Box> */}
                    </div>
                </Grid>
            </MainDiv>
        )
    }
};

const MainDiv = styled("div")({
    "& .mainPostRenterGrid": {
        display: "flex !important",
        gap: "20px",
        padding: "20px",
        flexDirection: "row"
    },
    "& .bookedHomeScreen": {
        width: "80%",
        display: "flex",
        flexDirection: "column",
        padding: "25px"
    },
    "& .mainLeftBar": {
        width: "20%",
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    },
    "& .firstSection": {
        backgroundColor: "#F9FAFB",
        padding: "10px"
    },
    "& .tripEndBox": {
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "20px"
    },
    "& .tripEndTextLeft": {
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: "28px",
        color: '#23395D',
        marginBottom: "16px"
    },
    "& .tripBtnBox": {
        display: "flex",
        gap: "12px",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    "& .modifyTripLeftBtn": {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 16px 10px 16px",
        borderRadius: "30px",
        border: '1px solid #D0D5DD',
        textTransform :"capitalize"
    },
    "& .endTripBtn": {
        backgroundColor: "#C3240F",
        padding: "10px 16px 10px 16px",
        borderRadius: "30px",
        textTransform :"capitalize",
        color: "white"
    },
    "& .reservationSummaryBox": {
        padding: "14px",
        backgroundColor: "white",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "14px",
        marginBottom: "20px"
    },
    "& .tripCostBreakdownBox": {
        padding: "14px",
        backgroundColor: "white",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "14px",
        marginBottom: "20px"
    },
    "& .stepperTransaction": {
        backgroundColor:"#F5F5F6",
        borderRadius: "10px",
        marginBottom: "16px",
        "& .MuiStepConnector-lineVertical": {
          minHeight: "50px !important"
        },
        "& .MuiStepConnector-line": {
          borderColor: "#3173E1 !important"
        },
        "& .MuiStepContent-root": {
          marginTop: "0px !important"
        },
        "& .MuiStepConnector-vertical": {
          padding: "0px !important"
        }
      },
      "& .stepLabelBox": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      },
      "& .stepLabelHeading": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "20.16px",
        color:"#23395D",
      },
      "& .rentalDuration": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "15.12px",
        color:"#B0B0B0",
      },
      "& .rentalTime": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "15.12px",
        color:"#3173E1",
      },
      "& .rentalPrice": {
        fontFamily: "Montserrat !important",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "19.5px",
        color:"#3173E1",
      },
      "& .total": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      },
      "& .totalReceived": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "20.16px",
        color:"#23395D",
      },
      "& .totalPrice": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "22px",
        fontWeight: 600,
        lineHeight: "27.72px",
        color:"#23395D",
      },
    "& .reservationSummaryText": {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: "24px",
        color: '#23395D',
    },
    "& .pickUpLocationText": {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: "20px",
        color: '#667085',
    },
    "& .pickUpLocationField": {
        borderRadius: "30px",
        backgroundColor: "#F2F4F7",
        color :"#98A2B3",
        padding: "10px 14px 10px 14px",
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: "24px",
    },
    "& .tripEndHours": {
        fontFamily: 'Inter',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: "28px",
        color: '#3173E1',
    },
    "& .secondSection": {
        backgroundColor: "#F9FAFB",
        padding: "10px"
    },
    "& .backSection": {
        display: 'flex',
        alignItems: "center",
        color: "#667085",
        gap: "6px",
        "@media(max-width: 600px)": {
            display: "none"
        },
        marginBottom: "32px"
    },
    "& .backIcon": {
        width: "20px",
        height: "20px"
    },
    "& .backText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px"
    },
    "& .bookedTripText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: "38px",
        color: '#23395D',
        marginBottom: "24px"
    },
    "& .imageDescBox": {
        display: "flex",
        gap: "20px",
        marginBottom: "32px"
    },
    "& .homeImage": {
        borderRadius: "12px"
    },
    "& .homeName": {
        fontFamily: 'Inter',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: "38px",
        color: '#23395D',
    },
    "& .ratingAndReviewBox": {
        display: "flex",
        gap: "8px"
    },
    "& .ratingText": {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: "24px",
        color: '#23395D',
    },
    "& .reviewsText": {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: "24px",
        color: '#23395D',
    },
    "& .mainTabs" : {
        borderBottom: "1px solid #E2E8F0"
    },
    "& .mainTabs .Mui-selected" :{
          color : "#3173E1",
          borderBottom: "none"
    },
    "& .mainTabs .MuiTabs-indicator" : {
          backgroundColor : "#3173E1"
    },
    "& .tabText" : {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textTransform: "none",
        color: "#475467"
    },
    "& .tripCostHeading": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "17.64px",
        color: "#23395D"
    },
    "& .clickForBreakdownText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "15.12px",
        color: "#94A3B8"
    },
    "& .tripCostvalue": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "17.64px",
        color: "#3173E1"
    },
    "& .tabBox": {
        marginBottom: "20px"
    },
    "& .tripCostBox": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px 24px 16px 24px",
        border: "1px solid #EAECF0",
        borderRadius: "12px",
        marginBottom: "20px"
    },
    "& .modifyTripBtn": {
        border: "1px solid #3173E1",
        borderRadius: "8px",
        color: "#3173E1",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "17.64px",
        textTransform: "capitalize"
    },
    "& .tripEndText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "17.64px",
    },
    "& .numberOfDays": {
        color: "#3173E1"
    },
    "& .tripCostBoxInfo": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px 24px 16px 24px",
        border: "1px solid #EAECF0",
        borderRadius: "12px",
        marginBottom: "20px",
        backgroundColor: "#EDF4FF"
    },
    "& .infoiconBox": {
        display: "flex",
        gap: "11px"
    },
    "& .endTripText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "17.64px",
        color: "#23395D"
    },
    "& .tripEndingMessage": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "17.64px",
        color: "#94A3B8"
    },
    "& .startCheckoutButton": {
        border: "1px solid #3173E1",
        borderRadius: "8px",
        color: "#3173E1",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "17.64px",
        textTransform: "capitalize"
    }
})