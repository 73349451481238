// Customizable Area Start
import React from "react";
import {
    Box,
    Grid,
    Typography,
    styled,
    Divider,
    OutlinedInput,
} from "@material-ui/core"
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import ProfileSidebar from "../../../components/src/ProfileSidebar.web";
import ProfileHeader from "../../../components/src/ProfileHeader.web";
import { searchIcon } from "../../../blocks/reservations/src/assets";


import SupportCenterController, {
    Props,
  } from "./SupportCenterController";
import { arrowIcon } from "./assets";


class SupportCenter extends SupportCenterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
            <AppHeader navigation={this.props.navigation} />

            <SupportCenterWrapper>

                <ProfileHeader />

                <Grid container spacing={3}>
                    <Grid item className="mainGrid">
                        <Box style={{ margin: "30px 0" }} className="mainBoxSection">
                            <ProfileSidebar activeKey={5} navigation={this.props.navigation} />
                        </Box>
                    </Grid>
                    <Grid item md={9} className="mainBoxSecondary">
                        <Box style={{ margin: "30px 0" }}>
                            <Box className="paddingBox">
                                <Typography className="mainText">Support Center</Typography>
                                <Typography className="subText">Get emails to find out what's going on when you're not online. You can turn them off anytime.</Typography>
                                <Divider style={{ margin: "20px 0" }} />

                                <OutlinedInput
                                    className="searchTextBox"
                                    placeholder="Search here"
                                    startAdornment={<img src={searchIcon} />}
                                    onChange={(event) => this.searchFunction(event.target.value)}
                                    data-test-id="searchText"
                                    fullWidth
                                />

                                {this.state.supportCenterArr.map(arrValue =>
                                    <Box className="mainBoxCenter" key={arrValue.id} data-test-id="navToPage" onClick={() => this.navigateToPage(arrValue.pageToNavigate)}>
                                        <Box>
                                            <Box className="iconBox">
                                                <img src={arrValue.ticketIcon} />
                                                <Typography className="headingText"> {arrValue.headerText}</Typography>
                                            </Box>
                                            <Typography className="headingSubText">{arrValue.subHeader1} <span className="colorSubText">{arrValue.subHeader2}</span></Typography>
                                        </Box>
                                        <img src={arrowIcon} />
                                    </Box>
                                )}
                            </Box>



                        </Box>
                    </Grid>
                </Grid>
            </SupportCenterWrapper>

            <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default SupportCenter;

const SupportCenterWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    "& .mainBox": {
      "@media(max-width:960px)": {
        display: "flex",
      }
    },
    "& .mainText": {
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 600,
        color: "#101828",
        fontSize: "18px",
        marginBottom: "4px",
    },
    "& .paddingBox": {
        padding: "0 30px",
    },
    "& .subText": {
        fontFamily: "Plus Jakarta Sans",
        color: "#475467",
        fontSize: "16px",
        fontWeight: 400,
    },
    "& .searchTextBox" : {
        height: 50,
        padding: "10px 16px",
        color: "#475467",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: "14px",
        borderRadius: "30px",
        margin: "15px 0",
        fontWeight: 600
      },
      "& .mainBoxSection":{
        "@media(max-width:960px)":{
          display:"flex",
          "& .MuiGrid": {
            maxWidth:"100% !important"
          }
        }
      },
      "& .mainBoxSecondary": {
          width: "100% !important"
      },
      "& .mainGrid":{
        "@media(max-width:960px)":{
          width:"100% !important",
          overflowX: "scroll",
        }
      },
      "& .searchTextBox .MuiOutlinedInput-input" : {
        padding : 10,
      },
      "& .mainBoxCenter" : {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
        border: '1px solid #EAECF0',
        borderRadius: '12px',
        alignItems: 'center',
        margin: "20px 0",
        cursor: "pointer",
      },
      "& .iconBox" : {
        display: "flex",
        gap: 10,
        alignItems: 'center',
        marginBottom: "8px",
      },
      "& .headingText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        color: '#101828'
      },
      "& .headingSubText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#475467'
      },
      "& .colorSubText" : {
        fontWeight: 500,
        color: '#3173E1'
      }

      
})
// Customizable Area End