import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCalling} from '../../../components/src/asset';
import { getStorageData } from "../../../framework/src/Utilities";

export interface Props {
    navigation: any;
}

interface CheckoutDetails {
    data: {
        id: string;
        type: string;
        attributes: {
            mileage_odometer: string;
            fuel_level: string;
            issues: string;
            is_key_provided: boolean;
            images: Array<{
                url: string;
            }>;
            drivers: {
                data: Array<unknown>;
            };
        };
    };
};

interface S {
    getCheckoutDetails: CheckoutDetails;
    tabValue: number
}

interface SS {}

export default class PostHomeRenterController extends BlockComponent<Props, S, SS> {
    getCheckoutDetailsApiCallid: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
    
        this.state = {
            getCheckoutDetails: {
                data: {
                    id: "",
                    type: "",
                    attributes: {
                        mileage_odometer: "",
                        fuel_level: "",
                        issues: "",
                        is_key_provided: false,
                        images: [],
                        drivers: {
                            data: []
                        }
                    }
                }
            },
            tabValue: 0
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      }

      async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId != null) {
                if(apiRequestCallId === this.getCheckoutDetailsApiCallid){
                    this.setState({
                        getCheckoutDetails: responseJson
                    })
                }
            }
        }
        // Customizable Area End
    }
    
    async componentDidMount() {
        this.getAllCheckoutDetails()
    };

    handleBack = () => {
        this.props.navigation.goBack()
    };

    handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
        this.setState({ tabValue: value})
    };

    getAllCheckoutDetails = async () => {
        const reservationDetails = await getStorageData("reservationDetails")
        const header = {
            "Content-Type": "application/json",
            "token": localStorage.getItem("userToken")
          };
      
          this.getCheckoutDetailsApiCallid = apiCalling({
            header: JSON.stringify(header),
            method: "GET",
            endPoint: `bx_block_calendar/booked_slots/${reservationDetails}/checkout_detail`
          })
    };
}