// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, defaultProfileIcon, truthyValue } from '../../../components/src/asset'
import { TicketsData, ChatMessage } from "../../../components/src/TypeInterfaces.web";
import moment from "moment";
import { toast } from "react-toastify"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  loader : boolean,
  supportChatId : string,
  selectedTicket : TicketsData
  chatMessage : ChatMessage[]
  adminImage : string,
  userImage : string,
  sendTexMsg : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TicketSupportChatController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTicketInfoApiCallId : string = ""
  getAllTicketsInfoApiCallId : string = ""
  sendMsgToSupportApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        loader : true,
        supportChatId : "",
        selectedTicket : this.initialTickets,
        chatMessage : [],
        adminImage : defaultProfileIcon,
        userImage : this.checkUserProfileIcon(),
        sendTexMsg : "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
         case this.getAllTicketsInfoApiCallId :
            return this.responseForAllTicketsApi(responseJson)
         case this.getTicketInfoApiCallId :
            return this.responseForChatApi(responseJson)
         case this.sendMsgToSupportApiCallId :
            return this.responseForSendMessageTicketsApi(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    const chatId = this.props.navigation.getParam("chatId")
    this.setState({ supportChatId : chatId})
    this.getAllTickets()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.supportChatId !== this.state.supportChatId) {
        this.getChatInfoData()
    }
  }

  getAllTickets = () => {
    const header = {
      'token': localStorage.getItem("userToken")
    }

    this.getAllTicketsInfoApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getTicketsApiEndPoint
    })
  }

  getChatInfoData = () => {
    const header = {
      'token': localStorage.getItem("userToken")
    }

    this.getTicketInfoApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.getChatInfoApiEndPoint}/${this.state.supportChatId}`
    })
  }

  sendMsgToSupport = () => {
    if (this.state.sendTexMsg.trim() === "") {
        toast.error("Please enter message")
        return false
    }

    const header = {
        'token': localStorage.getItem("userToken")
    }

    const formData = new FormData()
    formData.append("ticket_message[ticket_id]", this.state.supportChatId)
    formData.append("ticket_message[body]", this.state.sendTexMsg)

    this.sendMsgToSupportApiCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.postApiMethodType,
        endPoint: configJSON.sendMsgChatApiEndPoint,
        body: formData
    })
}

  responseForChatApi = (responseJson : any) => {
      if(responseJson && responseJson.data && responseJson.data.length > 0) {
        const chatArray = responseJson.data.map((item: any) => {
          return {
            chatId: truthyValue(item.id),
            chatMessage: truthyValue(item.attributes.body),
            chatTime: moment(truthyValue(item.attributes.created_at)).fromNow(),
            isSupportMsg: truthyValue(item.attributes.admin_message)
          }
        })
         this.setState({ loader : false, chatMessage : chatArray})
      } else {
        this.setState({ loader : false})
      }
  }

  responseForAllTicketsApi = (responseJson : any) => {
    if(responseJson && responseJson.data && responseJson.data.length > 0) {
      const receivedObj = responseJson.data.find((item : any) => item.id === this.state.supportChatId)

      const newTicketObj = {
          ticketId: truthyValue(receivedObj.id),
          ticketTitle: truthyValue(receivedObj.attributes.title),
          ticketCatogory: truthyValue(receivedObj.attributes.ticket_category.data) !== null ? truthyValue(receivedObj.attributes.ticket_category.data.attributes.name) : "",
          ticketStatus: truthyValue(receivedObj.attributes.status),
          ticketMessage: truthyValue(receivedObj.attributes.message),
          ticketTime: this.formateTime(truthyValue(receivedObj.attributes.created_at))
      }

       this.setState({ selectedTicket : newTicketObj})
   }
  }

  responseForSendMessageTicketsApi = (responseJson : any) => {
    if (responseJson && responseJson.data) {
      toast.success("Message Sent Successfully")
      this.getChatInfoData()
      this.setState({ sendTexMsg: "" })
    } else {
      toast.error("Something went wrong try again later.")
      this.setState({ sendTexMsg: "", loader: false })
    }
  }

  ticketChatStatus = {
    openStatus : "open",
    closeStatus : "resolved"
  }

  initialTickets : TicketsData = {
      ticketId : "",
      ticketCatogory : "",
      ticketTitle : "",
      ticketMessage : "",
      ticketTime : "",
      ticketStatus : ""
  }

  navToSupportCenter = () => {
    this.props.navigation.navigate("SupportCenterSettings")
  }

  navToTicketCenter = () => {
    this.props.navigation.navigate("TicketCenterSettings")
  }

  formateTime = (timeStamp : string) => {
    const formattedTime = moment(timeStamp).calendar(null, {
      sameDay: '[Today at] h:mma',
      nextDay: '[Tomorrow at] h:mma',
      nextWeek: 'dddd [at] h:mma',
      lastDay: '[Yesterday at] h:mma',
      lastWeek: '[Last] dddd [at] h:mma',
      sameElse: 'MM/DD/YYYY'
    });

    return formattedTime
  }

  handleChatTicketStatus = (status : string) => {
    return status === this.ticketChatStatus.openStatus ? true : false
  }

   checkUserProfileIcon = () => {
    const userImg = localStorage.getItem("userImg")
    return !userImg || userImg === "" ? defaultProfileIcon : userImg
  }

  handleTextMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ sendTexMsg: event.target.value })
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.sendMsgToSupport()
    }
  };


  // Customizable Area End
}

// Customizable Area End