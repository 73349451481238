// Customizable Area Start
import React from "react";
import {
    Box,
    Grid,
    Typography,
    styled,
    Divider,
    OutlinedInput,
    Accordion, 
    AccordionDetails, 
    AccordionSummary
} from "@material-ui/core"
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import ProfileSidebar from "../../../components/src/ProfileSidebar.web";
import ProfileHeader from "../../../components/src/ProfileHeader.web";
import { searchIcon } from "../../../blocks/reservations/src/assets";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';




import FaqsController, {
    Props,
  } from "./FaqsController";


class Faqs extends FaqsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
        <div>
            <AppHeader navigation={this.props.navigation} />

            <FAQsWrapper>

                <ProfileHeader />

                <Grid container spacing={3}>
                    <Grid item className="mainGrid">
                        <Box style={{ margin: "30px 0" }} className="mainBoxStyle">
                            <ProfileSidebar activeKey={5} navigation={this.props.navigation} />
                        </Box>
                    </Grid>
                    <Grid item md={9} className="mainBoxSecondary">
                        <Box style={{ margin: "30px 0" }}>
                            <Box className="paddingBoxFaq">

                                <Box className="breadcrumbsFaqBox">
                                    <Typography data-test-id="breadcrumbText" className="breadcrumbFaqText" onClick={this.navigateToSupportCenterPage}>Support Center</Typography>
                                    <ArrowForwardIosIcon style={{ height: 14, color: "#64748B" }} fontSize="small" />
                                    <Typography className="breadcrumbFaqText"><span className="colorFaqText">FAQs</span></Typography>
                                </Box>

                                <Typography className="mainTextFaq">Frequently Asked Questions</Typography>
                                <Typography className="subTextFaq">We're here to help! Our support agents are available 24/7 to assist you!</Typography>
                                <Divider style={{ margin: "20px 0" }} />

                                <OutlinedInput
                                    className="searchTextBoxFaq"
                                    placeholder="Search here"
                                    startAdornment={<img src={searchIcon} />}
                                    data-test-id="searchText"
                                    onChange={(event) => this.searchFAQs(event.target.value)}
                                    fullWidth
                                />

                                {this.state.faqsData.map(arrValue =>
                                <Box style={{ margin : "15px 0"}} key={arrValue.id}>
                                    <Accordion className="mainFaqsBox" >
                                       <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                            <Box>
                                                <Typography className="faqQuestionText">{arrValue.question}</Typography>
                                            </Box>
                                       </AccordionSummary>
                                       <AccordionDetails>
                                            <Box>
                                                <Typography className="faqAnswerText">
                                                  {arrValue.answer}
                                                </Typography>
                                            </Box>
                                       </AccordionDetails>
                                   </Accordion>
                                </Box>
                                )}
                                   

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </FAQsWrapper>

            <Footer />

        </div>
    );
    // Customizable Area End
  }
}

export default Faqs;

const FAQsWrapper = styled(Box)({
    display: "flex",
    maxWidth: "1220px",
    margin: "auto",
    flexDirection: "column",
    flexWrap: "wrap",
    "& .mainBox": {
        "@media(max-width:960px)": {
            display: "flex",
        }
    },
    "& .mainBoxStyle": {
        "@media(max-width:960px)": {
            display: "flex",
            "& .MuiGrid": {
                maxWidth: "100% !important"
            }
        }
    },
    "& .mainBoxSecondary": {
        width: "100% !important"
    },
    "& .mainGrid": {
        "@media(max-width:960px)": {
            width: "100% !important",
            overflowX: "scroll",
        }
    },
    "& .mainTextFaq": {
        fontFamily: "Plus Jakarta Sans",
        color: "#101828",
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "4px",
    },
    "& .paddingBoxFaq": {
        padding: "0 30px",
    },
    "& .subTextFaq": {
        color: "#475467",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 400,
    },
    "& .searchTextBoxFaq" : {
        height: 50,
        margin: "15px 0",
        padding: "10px 16px",
        fontFamily: 'Plus Jakarta Sans',
        color: "#475467",
        borderRadius: "30px",
        fontSize: "14px",
        fontWeight: 600
      },
      "& .searchTextBoxFaq .MuiOutlinedInput-input" : {
        padding : 10,
      },
      "& .breadcrumbsFaqBox": {
        display: "flex",
        gap: "10px",
        marginBottom: "30px",
        alignItems: "center"
    },
    "& .breadcrumbFaqText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        textAlign: 'left',
        fontWeight: 600,
        color: '#64748B',
        cursor: "pointer"
    },
    "& .colorFaqText" : {
        color: "#3173E1",
      },
    "& .mainFaqsBox" : {
        padding: "10px",
        boxShadow: "none",
        border: "1px solid #EAECF0",
        borderRadius: "12px",
    },
    "& .faqQuestionText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#101828',
        cursor: "pointer"
    }, 
     "& .faqAnswerText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#475467',
        cursor: "pointer",
        maxWidth: "92%",
        textAlign: "justify"
    }
})

// Customizable Area End