// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from "../../../components/src/asset";
import { ExtraAddOnData } from "../../../components/src/TypeInterfaces.web"
import { toast } from "react-toastify";

export const configJSON = require("./config");
export interface CatalogueItem {
    id: number;
    title: string;
    price: string;
    check: boolean;
    catalogue_id: number;
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  openEditModal:boolean;
  extraAddOnData: ExtraAddOnData[];
  addOnName:string;
  setPrice:string;
  selectedOption:string;
  setEdit:boolean;
  editId:number| string;
  catalogueId: string;
  loading : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ExtraAddOnController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getExtraAddApiCallID:string="";
  postAddExtraAddApiCallID:string ="";
  putAddExtraAddApiCallID:string =""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      openEditModal:false,
      extraAddOnData:[],
      addOnName:"",
      setPrice:"",
      selectedOption:"",
      setEdit:false,
      editId:0,
      catalogueId: "",
      loading : true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getExtraAddApiCallID:
          return this.handleGetExtraAddonResponse(responseJson)
        case this.postAddExtraAddApiCallID:
          return this.handleSaveExtraAddonResponse(responseJson)
        case this.putAddExtraAddApiCallID:
          return this.handlePutExtraAddonResponse(responseJson)
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
   async componentDidMount(){
    const catalougeId = this.props.navigation.getParam("subId")
    this.setState({ catalogueId: catalougeId})
    this.getExtraAddApi()
  }

  handleGetExtraAddonResponse = (responseJson : any) => {
    if(responseJson && responseJson.length > 0) {
      const responseArr = responseJson.filter((item : any) => item.catalogue_id.toString() === this.state.catalogueId).map((itemValue : any) => {
          return {
            id: truthyValue(itemValue.id) ,
            title: truthyValue(itemValue.title),
            price: this.handleAddOnAndPriceType(truthyValue(itemValue.price)).priceType,
            check: truthyValue(itemValue.check),
            addonType : this.handleAddOnAndPriceType(truthyValue(itemValue.price)).addonType,
            catalogue_id: truthyValue(itemValue.catalogue_id)
          }
      })

      this.setState({ extraAddOnData : responseArr, loading : false})

    } else {
       this.setState({ extraAddOnData : [], loading : false})
    }
  }

  handleSaveExtraAddonResponse = (responseJson : any) => {
    if(responseJson && !responseJson.errors) {
      this.getExtraAddApi()
      this.setState({openEditModal:false, loading : false})
      toast.success("Details Added Successfully")
    } else {
       this.setState({ openEditModal:false, loading : false})
    }
  }

  handlePutExtraAddonResponse = (responseJson : any) => {
    if(responseJson && responseJson.message) {
      this.getExtraAddApi()
      this.setState({openEditModal:false})
      toast.success("Details Updated Successfully")
    } else {
       this.setState({ openEditModal:false, loading : false})
    }
  }

  handleModal = () => {
    this.setState({openEditModal:true, addOnName:"", setPrice:"", selectedOption:"", setEdit:false})
  }
  handleCloseEditModal = () => {
    this.setState({openEditModal: false, addOnName:"", setPrice:"", selectedOption:""})
  }
  getExtraAddApi = () => {
    const header = {
      token: localStorage.getItem("userToken"),
    };
    this.getExtraAddApiCallID = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.getExtraAddEndPoint,
    });
  }
  handleAddOnName = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({addOnName : event.target.value})
  }
  handleSetPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      this.setState({ setPrice: value });
    }
  };
  handleSelectTrip = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedOption: event.target.value });
  };
  postAddExtraAddOn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(this.state.setEdit){
      this.handleEditExtraAddOn()
    }
    else{
      this.createExtraAddonAddition()
    }
  }
  createExtraAddonAddition = () => {

    this.setState({ loading : true})
    
    const {setPrice, addOnName, selectedOption} = this.state
    const header = {
      token: localStorage.getItem("userToken"),
    };
    const formdata = new FormData();
formdata.append("extra_addon[title]", addOnName);
formdata.append("extra_addon[price]", `${setPrice}/${selectedOption}`);
formdata.append("extra_addon[check]", "true");
formdata.append("extra_addon[catalogue_id]", this.state.catalogueId);

    this.postAddExtraAddApiCallID = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePost,
      endPoint: configJSON.createExtraAddEndPoint,
      body: formdata
    });
  }
  handleOpenEditModal = (data: ExtraAddOnData) => {
    this.setState({ 
      openEditModal: true, 
      setEdit: true, 
      addOnName: data.title, 
      setPrice: data.price, 
      selectedOption : data.addonType,
      editId: data.id 
    })
  }
  handleEditExtraAddOn = () => {

    this.setState({ loading : true})

    const {editId, setPrice, addOnName, selectedOption} = this.state
    const header = {
      token: localStorage.getItem("userToken"),
    };
    const formdata = new FormData();
    formdata.append("extra_addon[title]", addOnName);
    formdata.append("extra_addon[price]", `${setPrice}/${selectedOption}`);

    this.putAddExtraAddApiCallID = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePut,
      endPoint: configJSON.editExtraAddEndPoint(editId),
      body: formdata
    });
  }

  handleBackButton = () => {
    this.props.navigation.goBack()
  }

   handleAddOnAndPriceType = (inputVal: string) => {
    const parts = inputVal.split('/');
    return {
      addonType: parts.length > 1 ? parts[1] : '',
      priceType: parts[0]
    };
  };

  handleDisabledFn = () => {
      const { addOnName, setPrice, selectedOption } = this.state

      if(addOnName.trim() === "" || setPrice.trim() === "" || selectedOption.trim() === "") {
         return true 
      } else {
         return false
      }
  }
  // Customizable Area End
}

// Customizable Area End