// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, defaultProfileIcon, truthyValue } from '../../../components/src/asset'
import { GptResponse } from "../../../components/src/TypeInterfaces.web";
import moment from "moment"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  onClose: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  openChatGptModal : boolean
  userProfileImg : string
  questionToGpt : string
  chatGptResponse : GptResponse[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ChatgptScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getChatGptMessagesApiCallId : string = ""
  sendChatGptMessagesApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        openChatGptModal : false,
        userProfileImg : this.checkUserProfileImg(),
        questionToGpt : "",
        chatGptResponse : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
         case this.getChatGptMessagesApiCallId :
             return this.handleGetChatGptApiResponse(responseJson)
         case this.sendChatGptMessagesApiCallId :
            return this.handleSendMsgApiResponse(responseJson)
         default:
            break;
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

    async componentDidMount(): Promise<void> {
        this.getChatGptData()
    }

    handleGetChatGptApiResponse = (responseJson: any) => {
        if (responseJson && responseJson.gpt_questions && responseJson.gpt_questions.data && responseJson.gpt_questions.data.length > 0) {
            const receivedArray = responseJson.gpt_questions.data

            const filterdGptResponse = receivedArray.map((item : any) => {
                return {
                    questionId: truthyValue(item.id),
                    gptQuestion: truthyValue(item.attributes.body),
                    gptAnswer: this.handleNullValueCheck(truthyValue(item.attributes.answer)) ? truthyValue(item.attributes.answer.data.attributes.body) : "",
                    gptQuestionTime: moment(truthyValue(item.attributes.created_at)).fromNow(),
                    gptAnswerTime: this.handleNullValueCheck(truthyValue(item.attributes.answer)) ? moment(truthyValue(item.attributes.answer.data.attributes.created_at)).fromNow() : ""
                }
            })

            this.setState({ chatGptResponse : filterdGptResponse})

        } else {
            this.setState({ chatGptResponse : []})
        }
    }

    handleSendMsgApiResponse = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.getChatGptData()
            this.setState({ questionToGpt: "" })
        } else {
            this.setState({ questionToGpt: "" })
        }
    }
 
    getChatGptData = () => {
        const header = {
            "token": localStorage.getItem("userToken")
        };

        this.getChatGptMessagesApiCallId = apiCalling({
            header: JSON.stringify(header),
            endPoint: configJSON.getChatGptMsgApiEndPoint,
            method: configJSON.apiMethodTypeGet
        })
    }

    sendQuestionToGpt = () => {

        if(this.state.questionToGpt.trim() === "") {
            return false
        }

        const header = {
            "token": localStorage.getItem("userToken")
        };

        const formData = new FormData()
        
        formData.append("question[body]", this.state.questionToGpt)

        this.sendChatGptMessagesApiCallId = apiCalling({
            header: JSON.stringify(header),
            endPoint: configJSON.sendChatGptMsgApiEndPoint,
            method: configJSON.apiMethodTypePost,
            body: formData
        })
    }

    checkUserProfileImg = () => {
        const userImg = localStorage.getItem("userImg")
        return !userImg || userImg === "" ? defaultProfileIcon : userImg
    }

    handleAskQuestionChangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ questionToGpt: event.target.value })
    }

    handleEnterBtn = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            this.sendQuestionToGpt()
        }
    };

    handleNullValueCheck = (value : any) => {
        return value !== null ? true : false
    }

    

  // Customizable Area End
}

// Customizable Area End