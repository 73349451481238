// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from "../../../components/src/asset";
import moment from "moment"


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children? : any,
  pageTitle? : string,
  // Customizable Area End
}

interface RenterInfo {
    renterName : string
    tripsCompleted: number,
    renterSince: string,
    renterProfile? : string
}

interface ReviewInfo {
    rating : number
    totalReviews: number,
}

type ReservationInfo = {
    catalougeName: string;
    reviewInfo: ReviewInfo;
    startDate: string;
    startMonthTime: string
    endDate: string;
    endMonthTime: string
    imgUrl : string,
    duration : string
};

interface PriceBreakDown {
  label: string,
  details: string,
  subDetails: string,
  amount: string,
}

interface S {
  // Customizable Area Start 
  catalougeId: string | number,
  catalougeType: string,
  loading : boolean
  tabNo: number,
  reservationInfo : ReservationInfo,
  renterProfile : RenterInfo,
  renterId : string,
  conversationId : string,
  msgCount : number,
  pricingBreakDown : PriceBreakDown[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HostReservationDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getReservationDetailApiCallId : string = ""
  getRenterProfileApiCallId : string =""
  getMessageChatApiCallId : string = ""
  getPriceBreakDownApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        catalougeId: "",
        catalougeType: "",
        loading: true,
        tabNo: 0,
        reservationInfo: this.reservationInfo,
        renterProfile:  {
          renterName: "",
          tripsCompleted: 0,
          renterSince: "",
        },
        renterId: "",
        conversationId: "",
        msgCount: 0,
        pricingBreakDown: this.initialSteps
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getReservationDetailApiCallId:
          return this.handleReservationInfoResponse(responseJson)
        case this.getRenterProfileApiCallId:
          return this.handleRenterProfileResponse(responseJson)
        case this.getMessageChatApiCallId:
          return this.handleChatCountResponse(responseJson)
        case this.getPriceBreakDownApiCallId:
          return this.handlePriceBreakDownResponse(responseJson)
        default:
          break;
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.renterId !== this.state.renterId) {
        this.getMsgChatCount()
    }
  }

  handleReservationInfoResponse = (responseJson: any) => {
    if (responseJson && responseJson.catalogue && responseJson.catalogue.data) {
      const responseObj = responseJson.catalogue.data.attributes
      const renterProfileId = responseObj.renter_id
      const catalougeId = responseObj.id
      const catalougeType = responseObj.catalogue_type
      const conversationId = responseObj.conversation && truthyValue(responseObj.conversation.id)
      const reservationObj = {
        catalougeName: truthyValue(responseObj.catalogue_name),
        reviewInfo: {
          rating: 4.3,
          totalReviews: 44,
        },
        startDate: this.handleDates(responseObj.start_time).formattedDate,
        startMonthTime:`${this.handleDates(responseObj.start_time).formattedMonth} | ${this.handleDates(responseObj.start_time).formattedTime}`,
        endDate: this.handleDates(responseObj.end_time).formattedDate,
        endMonthTime: `${this.handleDates(responseObj.end_time).formattedMonth} | ${this.handleDates(responseObj.end_time).formattedTime}`,
        imgUrl: this.handleCatalougeImageUrl(responseObj.images),
        duration: this.countDuration(responseObj.start_time).toString()
      }
      this.setState({ renterId: renterProfileId, reservationInfo: reservationObj, catalougeId, catalougeType, conversationId : conversationId },() => this.getRenterProfile(renterProfileId))
    } else {
      this.setState({ reservationInfo: this.reservationInfo, loading: false })
    }
  }

  handleRenterProfileResponse = (responseJson: any) => {
    if (responseJson && responseJson.account && responseJson.account.data) {
      const responseObj = responseJson.account.data.attributes
      const renterInfo = {
        renterName: responseObj.full_name,
        tripsCompleted: truthyValue(responseJson.render_trip_completed),
        renterSince: truthyValue(responseObj.joined),
        renterProfile : truthyValue(responseObj.profile_image_url.url)
      }
  
      this.setState({ renterProfile: renterInfo, loading : false })
    } else {
      this.setState({ loading: false })
    }
  }

  handleChatCountResponse = (responseJson: any) => {
    if(responseJson && responseJson.unread_messages_count) {
      const msgCount = truthyValue(responseJson.unread_messages_count);
      this.setState({ msgCount : msgCount})
    } else {
      this.setState({ msgCount : 0})
    }
  }

  handlePriceBreakDownResponse = (responseJson: any) => {
    if(responseJson && responseJson.trip_cost_and_trip_cost_break_down) {
        const receivedData = responseJson.trip_cost_and_trip_cost_break_down

        const modifiedData: PriceBreakDown[] = [
          {
            label: 'Rental Price',
            details: `$${truthyValue(receivedData.per_day)} / Night`,
            subDetails: `(x${truthyValue(receivedData.number_of_days)} Night)`,
            amount: `$${truthyValue(receivedData.rental_price)}`,
          },
          {
            label: `Discount`,
            details: 'Extended Trip Discount',
            subDetails: `(${truthyValue(receivedData.discounts_day)}%)`,
            amount: `$${truthyValue(receivedData.discounts_value)}`,
          },
          {
            label: 'Trip Payment',
            details: `Refundable Deposit $${truthyValue(receivedData.refundable_deposit)}`,
            subDetails: "",
            amount: `$${truthyValue(receivedData.total_price_due)}`,
          },
          {
            label: 'LuxGo Service',
            details: 'Service Fee for Platform',
            subDetails: "",
            amount: `$${truthyValue(receivedData.luxgo_service)}`,
          },
          {
            label: 'Insurance Cost',
            details: 'Full Coverage Insurance Fee',
            subDetails: "",
            amount: `$${truthyValue(receivedData.insurance_cost)}`,
          },
          {
            label: `Sales Taxes (${truthyValue(receivedData.taxes)}%)`,
            details: 'California Sales Tax Rate',
            subDetails: "",
            amount: `$${truthyValue(receivedData.sales_taxes)}`,
          }
        ]; 
        
        

        this.setState({ pricingBreakDown : modifiedData})
    } else {
      this.setState({ pricingBreakDown : this.initialSteps})
    }
  }

    reservationInfo: ReservationInfo = {
        catalougeName: "",
        reviewInfo: {
            rating: 0,
            totalReviews: 0,
        },
        startDate: "",
        startMonthTime: "",
        endDate: "",
        endMonthTime: "",
        imgUrl: "",
        duration: ""
    };
 
    async componentDidMount() {

      const catalougeId = this.props.navigation.getParam("reservationId")
      this.getReservationDetailData(catalougeId)
      this.getPriceBreakdownData(catalougeId)
    }

    initialSteps: PriceBreakDown[] = [
      {
        label: 'Rental Price',
        details: '$1,400 / Night',
        subDetails: "(x3 Night)",
        amount: '$0',
      },
      {
        label: '3+ Day Discount',
        details: 'Extended Trip Discount',
        subDetails: "(5%)",
        amount: '$0',
      },
      {
        label: 'Trip Payment',
        details: 'Refundable Deposit $500',
        subDetails: "",
        amount: '$0',
      },
      {
        label: 'LuxGo Service',
        details: 'Service Fee for Platform',
        subDetails: "",
        amount: '$0',
      },
      {
        label: 'Insurance Cost',
        details: 'Full Coverage Insurance Fee',
        subDetails: "",
        amount: '$0',
      },
      {
        label: 'Sales Taxes (7.25%)',
        details: 'California Sales Tax Rate',
        subDetails: "",
        amount: '$0',
      }
    ];

    navigateToBackPage = () => {
        this.props.navigation.goBack()
      }

    navigateToPhotosCheckout = () => {
      this.props.navigation.navigate( "HostReservationCheckout", { reservationId : this.state.catalougeId})
    }

    navigateToInstructionPage = () => {
      this.props.navigation.navigate( "InstructionPage", { reservationId : this.state.catalougeId})
    }

    chcekCatalougeTypeCondition = (keyValue: string) => {
        return keyValue === "Car" ? true : false
    }

    tabChange = (event: React.ChangeEvent<{}>, value: number) => {
        this.setState({ tabNo: value})
      }
     
    getTabLabel = () => {
        return this.state.catalougeType === "Car" ? "Car Details" : "Apartment Details"
    }

    getReservationDetailData = (reservationId : number) => {
      const header = {
        "token": localStorage.getItem("userToken")
      };
      
      this.getReservationDetailApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: `${configJSON.getReservationInfoApiEndPoint}/${reservationId}`,
        method: configJSON.apiMethodTypeGet
      })
    }

    getPriceBreakdownData = (bookSlotId : number) => {
      const header = {
        "token": localStorage.getItem("userToken")
      };
      
      this.getPriceBreakDownApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: `${configJSON.getPriceBreakdownApiEndPoint}?id=${bookSlotId}`,
        method: configJSON.apiMethodTypeGet
      })
    }

    getRenterProfile = (renterProfileId : number) => {
      const header = {
        "token": localStorage.getItem("userToken")
      };
      
      this.getRenterProfileApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: `${configJSON.getRenterProfileApiEndPoint}?id=${renterProfileId}`,
        method: configJSON.apiMethodTypeGet
      })
    }

    handleCatalougeImageUrl = (images : any) => {

      // imageUrl
  
      let imgUrl : string = ""
      if(images && images.length > 0 && images[0].url ) {
        imgUrl = images[0].url
      }
  
      return imgUrl
    } 

    handleDates = (dateString :string) => {
      const formattedDate = moment(dateString).format('DD');
      const formattedMonth = moment(dateString).format('MMMM');
      const formattedTime = moment(dateString).format('hh:mm A');
  
      return { formattedDate, formattedMonth, formattedTime }
    }

    countDuration = (startTime: string) => {
      const today = moment();

      const startDate = moment(startTime);
      const differenceInDays = startDate.diff(today, 'days');

      return differenceInDays
    }

    updateCountFn = (count : number) => {
      this.setState({ msgCount : count })
    }

    getMsgChatCount = () => {
      const header = {
        'token': localStorage.getItem("userToken")
      }
  
      this.getMessageChatApiCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.apiMethodTypeGet,
        endPoint: `${configJSON.getChatCountApiEndPoint}/${this.state.renterId}`,
      })
    }

  // Customizable Area End
}

// Customizable Area End