// Customizable Area Start
import React from "react";

import {
    Box,
    styled,
    Typography,
    Button,
    Grid,
    Tabs,
    Tab,
    Divider
} from "@material-ui/core"
import Rating from '@material-ui/lab/Rating';

import AppHeader from "../../../blocks/notifications/src/TopHeader.web"
import Footer from "../../../components/src/Footer.web";
import Loader from "../../../components/src/Loader.web"
import ReservationChat from "../../../blocks/chat/src/ReservationChat.web";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LocalGasStationOutlinedIcon from '@material-ui/icons/LocalGasStationOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DescriptionIcon from '@material-ui/icons/Description';


import { CustomSlider } from "../../../components/src/FilterModal.web";
import { starIcon, downloadIcon } from "./assets";
import { notificationIcon2 } from "../../../components/src/asset";

import TripCompletedController, {
    Props,
  } from "./TripCompletedController";


class TripCompleted extends TripCompletedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderCard = (mainHeader : string, subHeader : string, mileageTxt : boolean) => {
    return (
        <Box className="mainInstructionBox">
        <Box className="grayInstructionBox">
            <Box className="headerInstructionBox">
                <Typography className="subPolicyText">{mainHeader}</Typography>
            </Box>
        </Box>
        <Box className="whiteInstructionBox">
                {mileageTxt ?
                    <Box style={{ textAlign: "center" }}>
                        <Typography className="titleText">{subHeader}</Typography>
                    </Box>
                    :
                    <Box className="flexDiv">
                        <CheckCircleIcon className="listIcon" />
                        <Typography className="titleText">{subHeader}</Typography>
                    </Box>
                }
        </Box>

    </Box>
    )
  }

  renderDetailsTab = () => {
    return(
        <Box>
            <Grid container spacing={2} style={{ marginTop : 30, marginBottom : 10}}>
                <Grid item md={6}>
                    <Typography className="recentText">Recent guest ratings and review</Typography>
                </Grid>
                <Grid item md={6}>
                    <Typography className="viewAllText">View all</Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item md={7}>

                    {this.state.reviewInfo.reviewId !== "" ?

                        <Box className="reviewBox">
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Box style={{ display: "flex", gap: 10 }}>
                                    <img src={this.state.reviewInfo.reviewImg} className="reviewImg" />
                                    <Box>
                                        <Typography className="nameText">{this.state.reviewInfo.reviewName}</Typography>
                                        <Box style={{ display: "flex", gap: 15, alignItems: "center" }}>
                                            <Rating value={this.state.reviewInfo.reviewCount} disabled />
                                            <Typography className="timeText">{this.state.reviewInfo.reviewTime}</Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <img src={notificationIcon2} style={{ height: 46, width: 46 }} />

                            </Box>

                            <Divider className="dividerCSS" />
                            <Typography className="durationText">{this.state.reviewInfo.reviewDuration}</Typography>
                            <Divider className="dividerCSS" />

                            <Typography className="descriptionText">{this.state.reviewInfo.reviewDescription}</Typography>
                        </Box>
                        :
                        <Box className="reviewBox" textAlign={"center"}>
                            <Typography className="durationText">No reviews found</Typography>
                        </Box>
                    }

                </Grid>

                <Grid item md={5}>
                    <Box className="fuleBox">
                        <Box className="whiteBgBox">
                            <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <LocalGasStationOutlinedIcon style={{ height: 20, width: 20 }} />
                                <Typography className="fuelText">Fuel level before trip </Typography>
                            </Box>
                            <Box className="sliderBox">
                                <CustomSlider
                                    step={0.5}
                                    defaultValue={0}
                                    marks
                                    min={0}
                                    max={5}
                                    valueLabelDisplay="auto"
                                    style={{ marginTop: "20px", marginBottom: "25px" }}
                                    value={this.state.fuelLevelBefore}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box className="fuleBox">
                        <Box className="whiteBgBox">
                            <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <LocalGasStationOutlinedIcon style={{ height: 20, width: 20 }} />
                                <Typography className="fuelText">Fuel level at return</Typography>
                            </Box>
                            <Box className="sliderBox">
                                <CustomSlider
                                    step={0.5}
                                    defaultValue={0}
                                    value={this.state.fuelLevelAfter}
                                    marks
                                    min={0}
                                    max={5}
                                    valueLabelDisplay="auto"
                                    style={{ marginTop: "20px", marginBottom: "25px" }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item md={4}>
                    {this.renderCard("Cleaning Status", "Car Cleaned and Ready for Next Rental", false)}
                    {this.renderCard("Vehicle Condition Report", "No damages reported by the guest.", false)}
                </Grid>
                <Grid item md={3}>
                    {this.renderCard("Mileage Odometer Before trip", "-", true)}
                    {this.renderCard("Mileage Odometer After  trip", "-", true)}
                </Grid>
                <Grid item md={5}>
                    <Box className="iconBgBox">
                        <Box className="iconInnerBox">
                            <DescriptionIcon className="iconStyle" />
                            <Typography className="downloadText">Downloadable Trip Report</Typography>
                            <Button className="endTripBtn" endIcon={<img src={downloadIcon} />}>End Trip</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

    renderChat = () => {
        return (
            <Box>
                <ReservationChat
                    receiverId={this.state.conversationId}
                    navigation={this.props.navigation}
                    id="chatSection"
                    updateCount={(count) => this.setCointMsg(count) } />
            </Box>
        )
    }


  render() {
    // Customizable Area Start
    
    return (
      <div>

        <AppHeader navigation={this.props.navigation} activeLink="Dashboard" />
         <CompletedWrapper>
                <Grid container spacing={3}>
                    <Grid md={10}>
                        <Box>
                            <Button
                                onClick={this.redirectToBackPage}
                                startIcon={<ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />}
                                className="redirectBtn"
                                data-test-id="backBtn"
                            >
                                Back
                            </Button>
                            <Typography className="tripTextHeader">Completed Trip</Typography>

                            {this.state.loading ? <Loader loading />
                                :
                                <>
                                    <Box style={{ display: "flex", gap: 20, marginBottom: "30px", marginTop: 25 }}>
                                        <img src={this.state.catalougeDetails.catalougeImg} style={{ width: 132, height: 114, borderRadius: "12px" }} />
                                        <Box>
                                            <Typography className="catalogeHeaderName">{this.state.catalougeDetails.catalougeName}</Typography>
                                            <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                                                <img src={starIcon} />
                                                <Typography className="reviewTextLine">  {this.state.catalougeDetails.rating} <span> {this.state.catalougeDetails.reviews} reviews</span></Typography>
                                            </div>

                                        </Box>
                                    </Box>

                                    <Box style={{ position : "relative"}}>
                                        <Tabs
                                            className="tabHeaderTrip"
                                            value={this.state.tabValue}
                                            onChange={this.changeTab}
                                            data-test-id="tabs"
                                        >
                                            <Tab label="Home Details" className="tabLabelTrip"></Tab>
                                            <Tab label="Messages" className="tabLabelTrip"></Tab>
                                        </Tabs>

                                        <Box className="countBox">
                                            <Typography className="countsText">{this.state.countMsg}</Typography>
                                        </Box>
                                    </Box>

                                    {this.state.tabValue === 0 ? this.renderDetailsTab() : this.renderChat()}
                                </>
                            }
                        
                        </Box>
                    </Grid>
                </Grid>
         </CompletedWrapper>

         <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default TripCompleted;

const CompletedWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .redirectBtn": {
        fontFamily: 'Plus Jakarta Sans',
        textAlign: 'left',
        fontSize: '14px',
        fontWeight: 500,
        color: '#667085',
        lineHeight: '20px',
        textTransform: "none",
        background: "white"
    },
    "& .tripTextHeader": {
        fontSize: '30px',
        textTransform: "capitalize",
        fontWeight: 600,
        fontFamily: 'Plus Jakarta Sans',
        color: '#23395D',
        margin: "15px 0",
    },
    "& .catalogeHeaderName": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
        textAlign: 'left',
        color: '#23395D'
    },
    "& .reviewTextLine": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
        color: '#1D2939'
    },
    "& .tabLabelTrip": {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textTransform: "none",
        color: "#475467"
    },
    "& .tabHeaderTrip": {
        borderBottom: "1px solid #E2E8F0",
    },
    "& .tabHeaderTrip .Mui-selected": {
        color: "#3173E1",
        borderBottom: "none"
    },
    "& .tabHeaderTrip .MuiTabs-indicator": {
        backgroundColor: "#3173E1"
    },
    "& .viewAllText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#0F73EE'
    },
    "& .recentText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#23395D'
    },
    "& .fuleBox": {
        boxShadow: "0px 4px 5px 0px #0000000D",
        border: "1px solid #DDE0E6",
        borderRadius: "12px",
        margin : "10px 0"
    },
    "& .sliderBox": {
        padding: "0 15px"
    },
    "& .whiteBgBox" : {
        background: "white",
        borderRadius: "12px",
        padding: "20px"
    },
    "& .grayInstructionBox" : {
        background : "#F4F7FC",
        borderBottom: "1px solid #E7E7E7",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
      },
    "& .mainInstructionBox" : {
        border: "1px solid #E7E7E7",
        borderRadius: "12px",
        margin: "15px 0"
      },
      
      "& .headerInstructionBox" : {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
      }, 
      "& .whiteInstructionBox" : {
        background :"white",
        padding: "15px",
        borderRadius: "12px",  
      },
      "& .subPolicyText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .fuelText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 700,
        color: '#23395D'
    },
    "& .flexDiv" : {
        display: "flex",
        gap : "10px",
        alignItems: "center"
      },
      "& .listIcon" : {
        color: "#3173E1", 
        height: 20, 
        width: 20,
      },
    "& .titleText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#23395D',
      },
      "& .iconBgBox" : {
        boxShadow:" 0px 4px 5px 0px #0000000D",
        border: "1px solid #E7E7E7",
        borderRadius : "10px",
        padding : "30px 80px",
        margin : "15px 0"
      },
      "& .iconStyle" : {
        height : 85,
        width : 85,
        color : "#4175DF",
        marginBottom : "5px"
      },
      "& .iconInnerBox" : {
        textAlign : "center"
      },
      "& .downloadText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#23395D',
        marginBottom : "10px"
      },
      "& .endTripBtn" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#3173E1',
        lineHeight: '20px',
        textTransform: "none",
        background: "#EDF4FF",
        padding : "10px 16px",
        width : 190
      },
      "& .reviewBox" : {
        background : "#F4F7FC",
        padding : "25px",
        borderRadius : 12
      },
      "& .reviewImg" : {
        height: 44,
        width : 44,
        borderRadius: "50%"
      },
      "& .durationText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
        color : "#475467",
        margin : "15px 0"
      },
      "& .descriptionText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 400,
        color : "#475467",
        height :140,
        maxHeight :140,
        overflow : "scroll",
        scrollbarWidth : "none"
      },
      "& .dividerCSS" : {
        margin : "5px 0"
      },
      "& .nameText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color : "#23395D",
      },
      "& .timeText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        color : "#475467",
      },
      "& .countBox": {
        position: "absolute",
        borderRadius: "16px",
        padding: "2px 10px",
        background: "#EAECF0",
        left: "300px",
        top: "12px"
      },
      "& .countsText": {
          fontSize: '14px',
          color: '#344054',
          fontWeight: 500,
          fontFamily: 'Plus Jakarta Sans',
      },
})

// Customizable Area End