import React, { useState } from 'react'
import { AppBar, Button, Typography, Box, styled, Toolbar, Popper, ClickAwayListener, Dialog, IconButton, Divider, DialogContent, createTheme} from '@material-ui/core'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { makeStyles } from '@material-ui/styles';
import { defaultProfileIcon, getUserRole, notificationIcon1, notificationIcon2, notificationIcon3, notificationIcon4, truthyValue } from './asset';
import ChatgptScreen from '../../blocks/chatgpt/src/ChatgptScreen.web'


const CustomAppBar = styled(AppBar)({
    '@media(max-width: 1260px)': {
        paddingInline: 16,
        '& .logo-img': {
            width: '21px',
            height: '30px',
            objectFit: 'cover',
            objectPosition: 'left',
        }
    },
    '& .hamburger-icon': {
        display: 'none',
        cursor: 'pointer',
        '@media(max-width: 1260px)': {
            display: 'block'
        }
    },
    '& .navbar-right': {
        display: 'block',
        '@media(max-width: 1260px)': {
            display: 'none !important'
        }
    },
    '& .header-menu': {
        justifyContent: 'space-between', maxWidth: 1220, margin: 'auto', width: '100%', paddingLeft: 0, paddingRight: 0,
    },
})

const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: "0 20px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
    },
    notificationItem: {
      display: 'flex',
      marginBottom: "16px",
      justifyContent: "space-between",
      gap: "20px"
    },
    icon: {
      marginRight: "16px",
      height: 42,
      width: 42
    },
    closeButton: {
      marginLeft: "8px",
    },
    notificationTitle: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 600,
      textAlign: 'left',
      color: "#23395D",
    },
    notificationDescription : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '19.6px',
      textAlign: 'left',
      color: "#4C6081"
    },
    notificationHeaderText: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '18px',
      fontWeight: 600,
      textAlign: 'left',
      color: '#23395D',
      margin : "20px 0",
    }
  }));

const TopBarItem = styled(Box)({
  "& .navbar": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    color: '#9398A4',
    cursor: "pointer",
    padding: 16
  },
  "& .active-navbar": {
    color: '#3173E1',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    padding: 16,
    cursor: "pointer"
  }
    
})
 
const handleNotificationIcons = (keyValue: string) => {
  switch (keyValue) {
    case "New Booking Request":
      return <img src={notificationIcon1} alt="" />
    case "New Message":
      return <img src={notificationIcon2} alt="" />
    case "Reservation Confirmed":
      return <img src={notificationIcon3} alt="" />
    case "Payment Notifications":
      return <img src={notificationIcon4} alt="" />
    default:
      return <img src={notificationIcon1} alt="" />
  }
}

interface Props  {
    activeLink? : string,
    navigation?: any,
    notificationData? : any[]
}

interface NotificationDialogProps {
  open: boolean;
  onClose: () => void;
  data? : any[]
}

const NotificationDialog:React.FC<NotificationDialogProps> = ({ open, onClose, data }) => {
    const classes = useStyles();
  
    return (
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <div className={classes.dialogTitle}>
          <Typography className={classes.notificationHeaderText}>Notifications</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <HighlightOffOutlinedIcon />
          </IconButton>
        </div>

        {data && data.length > 0 ? 
        <DialogContent style={{ marginTop: 20, maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
          {data.map((notification, index) => (
            <Box>
            <div key={index} className={classes.notificationItem}>
              <div className={classes.icon}>
                {handleNotificationIcons(truthyValue(notification.attributes.heading))}
              </div>
              <div>
                <Typography className={classes.notificationTitle}>{truthyValue(notification.attributes.heading)}</Typography>
                <Typography className={classes.notificationDescription}>{truthyValue(notification.attributes.remarks)}</Typography>
              </div>
            </div>
            {index !== data.length - 1 && <Divider style={{ margin: "15px 0"}} />} 
            </Box>
          ))}
        </DialogContent>
        :
        <DialogContent style={{ marginTop: 20, maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
          <Box textAlign={'center'}>No Notification Available</Box>
        </DialogContent>
       }
      </Dialog>
    );
  };
  

export default function AppHeader(props: Props) {
    const [mobileMenu,setMobileMenu]=useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [clickAway, setClickAway] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [chatGptModal, setChatGptModal] = useState(false)

    const handlOpenModal = () => {
      if(!localStorage.getItem("userToken")) {
        return false
      }
      setOpenModal(true)
    }

    const openChatGptModal = () => {
      if (!localStorage.getItem("userToken")) {
        return false
      }
      setChatGptModal(true)
    }

    const closeChatGptModal = () => {
      setChatGptModal(false)

    }

    const handlCloseModal = () => {
      setOpenModal(false)
    }

    const open = Boolean(anchorEl)

    const handleClickAway = () => {
        setClickAway(false)
        setAnchorEl(null)
    }
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setClickAway(!clickAway)
        setAnchorEl(anchorEl ? null : event.currentTarget);
      };

    const logout = () => {
        localStorage.clear()
        props.navigation.navigate("LogIn")
        
    }

    const navigateToProfile = () => {
        props.navigation.navigate("UserProfile")
        
    }

    const becomeHostText = () => {
      const userRole = getUserRole()
      return userRole === "host" ? "Host" : "Become a Host" 
    }

    const checkUserAuth = () => {
      if (!localStorage.getItem("userToken")) {
        navigateToHome()
        return false
      }
      return true
    }

    const navigateToHome = () => {
      props.navigation.navigate("Home")
    }

    const navigateToHostPage = () => {
        checkUserAuth() && props.navigation.navigate("HostDashboard")
    }

    const navigateToReservation = () => {
      const userRole = getUserRole()
      if(checkUserAuth()) {
         if(userRole === "host") {
            props.navigation.navigate("HostReservation")
         } else {
            props.navigation.navigate("Reservation")
         }
      }
    }

    const checkUserProfileIcon = () => {
      const userImg = localStorage.getItem("userImg")
      return !userImg || userImg === "" ? defaultProfileIcon : userImg
    }
   
     return <CustomAppBar position="static" style={{boxShadow:"none",borderBottom:"1px solid #EAECF0", background: 'transparent' }}>
        <Toolbar className='header-menu'>
            <Box>
               <img className='logo-img' onClick={navigateToHome} src={require("./Logo.png")} /> 
            </Box>
            <TopBarItem className='navbar-right' style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                <Typography variant="h6" onClick={navigateToHome} className='navbar' >
                  Home
                </Typography>
                <Typography variant="h6" onClick={navigateToHome} className={props.activeLink === "" || props.activeLink === "Book" ? 'active-navbar' : "navbar"} >
                  Book
                </Typography>
                <Typography variant="h6" onClick={navigateToReservation} className={props.activeLink === "Reservation" ? 'active-navbar' : "navbar"} >
                  Reservations
                </Typography>
                <Typography variant="h6" onClick={navigateToHome} className='navbar' >
                  About Us
                </Typography>
                <Typography variant="h6" onClick={navigateToHostPage} className={props.activeLink === "Dashboard" ? 'active-navbar' : "navbar"} >
                  {becomeHostText()}
                </Typography>
                <Typography variant="h6" onClick={navigateToHome} className='navbar' >
                  Help Center
                </Typography>
            </TopBarItem>
            <Box className='navbar-right' style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
                <Button onClick={openChatGptModal} style={{ background: 'linear-gradient(92.64deg, rgba(122, 54, 186, 0.6) 0%, rgba(49, 115, 225, 0.6) 114.09%)', borderRadius: 30, textTransform: 'unset', color: 'white' }}>
                    <img src={require("./image_11.png")} style={{ marginRight: 10 }} />
                    Ask Ava.</Button>
                <Button variant='outlined' style={{ border: '1px solid #D0D5DD', borderRadius: 30, textTransform: 'unset' }}> <img style={{ marginRight: 5 }} src={require("./image_.png")} />Download for IOS</Button>
                <Box onClick={handlOpenModal} style={{ position: 'relative',cursor:"pointer" }}>
                    <img src={require("./notificationbell.svg")} />
                    {props.notificationData && props.notificationData.length > 0 &&
                    <span style={{ backgroundColor: 'red', width: 16, height: 16, borderRadius: '50%', color: 'white', position: 'absolute', top: -5, right: -5, border: '2px solid white', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: 12 }}>
                      {props.notificationData?.length}
                    </span>}
                </Box>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <img src={checkUserProfileIcon()} onClick={handleClick} style={{ width: 40, height: 40, borderRadius: '50%',cursor:"pointer" }} alt="" />
                </ClickAwayListener>
                 {clickAway ? <Popper placement='bottom-start' open={open} anchorEl={anchorEl}>
                     <div style={{
                        background: "white",
                        padding: "5px 15px",
                        border: "1px solid lightblue",
                        borderRadius: "5px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        cursor: "pointer",
                     }}> 
                     
                     {localStorage.getItem("userToken") !== null && <Box style={{ height: 30}} onClick={() => navigateToProfile()}> Profile</Box>}
                     <Box style={{ height: 30}} onClick={() => logout()}> 
                      { !localStorage.getItem("userToken") ? "SignUp/SignIn" : "Logout"}
                     </Box>
                     
                     </div>
                 </Popper> : null}
            </Box>
            <Box className='hamburger-icon'>
                <img src={require("./hamburger.svg")} alt="" onClick={()=>{setMobileMenu(!mobileMenu)}} />
            </Box>
        </Toolbar>

           {/* notification dialog */}

           <NotificationDialog open={openModal} onClose={handlCloseModal} data={props.notificationData} />

           {/* Chat GPT dialog */}

           <ChatgptScreen open={chatGptModal} onClose={closeChatGptModal} navigation={props.navigation} id={'chatgpt'} />


          {mobileMenu === true && <Box style={{ background: 'white', textAlign: "center", position: 'absolute', top: 60, width: '100%', height: '100%', zIndex: 99999, left: 0 }}>
            <TopBarItem>
              <Typography variant="h6" onClick={navigateToHome} className='navbar' >
                Home
              </Typography>
              <Typography variant="h6" onClick={navigateToHome} className={props.activeLink === "" ? 'active-navbar' : "navbar"} >
                Book
              </Typography>
              <Typography variant="h6" onClick={navigateToReservation} className={props.activeLink === "Reservation" ? 'active-navbar' : "navbar"} >
                Reservations
              </Typography>
              <Typography variant="h6" onClick={navigateToHome} className='navbar' >
                About Us
              </Typography>
              <Typography variant="h6" onClick={navigateToHostPage} className={props.activeLink === "Dashboard" ? 'active-navbar' : "navbar"} >
                {becomeHostText()}
              </Typography>
              <Typography variant="h6" onClick={navigateToHome} className='navbar' >
                Help Center
              </Typography>
              <button onClick={logout} style={{ background: '#3173D1', border: 'none', borderRadius: 8, marginTop: 24, color: 'white', fontWeight: 600, height: 48, maxWidth: "100%", width: '80%', fontSize: '14px', letterSpacing: '-1%', cursor: "pointer" }}>
                {!localStorage.getItem("userToken") ? "LogIn" : "Logout"}
              </button> <br />
            </TopBarItem>
          </Box>
          }
    </CustomAppBar>
}
