// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Typography,
    Radio,
    Button,
    OutlinedInput
} from "@material-ui/core"

import PostTripCommonSidebar from "../../../components/src/PostTripCommonSidebar.web"

import AddIcon from '@material-ui/icons/Add';
import LocalGasStationOutlinedIcon from '@material-ui/icons/LocalGasStationOutlined';
import { checkedIcon, radioIcon } from "./assets";
import { AntSwitch, CustomSlider } from "../../../components/src/FilterModal.web";



import PostTripCheckoutFlowController, {
    Props,
  } from "./PostTripCheckoutFlowController";


class PostTripCheckoutFlow extends PostTripCheckoutFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    renderPhotosSection = () => {
        return (
            <Box className="carBox">
                <div className="addphotos">
                    {this.state.images.map((image, index) => (
                        <div key={index} style={{ border: '1px dashed #D0D5DD', position: 'relative', height: 130, borderRadius: 12, margin: "8px 0" }}>
                            {image.preview ? (
                                <img src={image.preview} alt={`Uploaded ${index}`} style={{ width: '100%', height: '100%', borderRadius: 12 }} />
                            ) : (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        data-test-id="imageGrid"
                                        onChange={(event) => this.handleImageUpload(event, index)}
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            cursor: 'pointer',
                                        }}
                                    />
                                    <div style={{ textAlign: 'center', display: "flex", gap: 5, alignItems: "center" }}>
                                        <Box>
                                            <AddIcon className="colorText" />
                                        </Box>
                                        <Typography className="addPhotoText"> Add Photo</Typography>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </Box>
        )
    }

    renderHeaderPart = () => {
        return (
            <Box>
                <Typography className="addPhotosText"> Add Photos </Typography>
                <Typography className="addPhotossubText">Please take up to 9 photos to document the car's condition. Include interior and exterior. The Hosts photos will show up here also.</Typography>
            </Box>
        )
    }

    renderVisibleDamageBox = () => {
        return (
            <Box style={{ margin: "20px 0"}}>
                <Typography className="addPhotosText"> Visible Damage </Typography>

                <Box className="radioTextBox">
                    <Typography className="damageText"> Is there any significant damage?</Typography>
                    <Box style={{ display: "flex", gap: 10, margin: "10px 0" }}>
                        <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                            <Radio icon={<img src={radioIcon} />} checkedIcon={<img src={checkedIcon} />} onChange={this.handleChangeforDamageKey} value={"true"} checked={this.state.damageValue === "true"} name="visibleDamage" />
                            <Typography className="radioText"> Yes</Typography>
                        </Box>
                        <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                            <Radio icon={<img src={radioIcon} />} checkedIcon={<img src={checkedIcon} />} value={"false"} onChange={this.handleChangeforDamageKey} checked={this.state.damageValue === "false"} name="visibleDamage" />
                            <Typography className="radioText"> No</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    renderButtonBox = () => {
        return (
            this.checkBookSlotType()
                ?
                <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
                    <Button data-test-id="backCarBtn" onClick={this.navigateToPreviousPage} className="backButtonCheckout">Back</Button>
                    <Button data-test-id="nextCarBtn" onClick={this.moveToFinalCheckoutPage} className="continueButtonCheckout">Continue</Button>
                </Box>
                :
                <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
                    <Button data-test-id="backBtn" onClick={this.navigateToPreviousPage} className="backButtonCheckout">Back</Button>
                    <Button data-test-id="nextBtn" onClick={this.saveHomeCheckOutImages} className="continueButtonCheckout">Continue</Button>
                </Box>
        )
    }

    renderFinalCheckOut = () => {
        return(
            <Box>
                <Box className="mainFinalBox">
                    <Typography className="labelFinalText" style={{ marginBottom: "8px" }}>Mileage Odometer</Typography>
                    <OutlinedInput
                        className="milegeFinalTextField"
                        placeholder="Enter Odometer"
                        value={this.state.odometerValue}
                        data-test-id="odometerVal"
                        onChange={this.handleOdometerValue}
                        fullWidth
                    />
                </Box>

                <Box className="fuleFinalBox">
                    <Box className="whiteBgFinalBox">
                        <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            <LocalGasStationOutlinedIcon style={{ height: 20, width: 20 }} />
                            <Typography className="fuelFinalText">Fuel Level </Typography>
                        </Box>
                        <Box className="sliderBox">
                            <CustomSlider
                                step={0.5}
                                defaultValue={0}
                                marks
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                                style={{ margin : "25px 0" }}
                                value={this.state.fuelLevel}
                                data-test-id="fuelLevel"
                                onChange={this.handleFuelLevelModify}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box className="mainFinalBox">
                    <Typography className="labelFinalText" style={{ marginBottom: "8px" }}>Provide Keys</Typography>
                    <Box className="keyFinalBox">
                        <Typography className="labelFinalText" style={{ fontSize: "14px" }}>Renter has recieved the Car Keys</Typography>
                        <AntSwitch checked={this.state.keyProvided} onChange={this.handleKeyProvided}  data-test-id="keySwitch" />
                    </Box>
                </Box>

                <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
                    <Button data-test-id="backToCheckout" onClick={this.backToCheckOut} className="backButtonCheckout">Back</Button>
                    <Button data-test-id="endTrip" onClick={this.saveCarCheckOutImages} className="continueButtonCheckout">End Trip</Button>
                </Box>
            </Box>
        )
    }

    renderHomeCheckOut = () => {
        return(
            <div>
                {this.renderHeaderPart()}
                {this.renderPhotosSection()}
                {this.renderVisibleDamageBox()}
                {this.renderButtonBox()}
            </div>
        )
    }

    renderCarCheckOut = () => {
        return (
            this.state.finalCheckout ? this.renderFinalCheckOut() :
                <div>
                    {this.renderHeaderPart()}
                    {this.renderPhotosSection()}
                    {this.renderVisibleDamageBox()}
                    {this.renderButtonBox()}
                </div>
        )
    }


  render() {
    // Customizable Area Start
    
    return (
        <div>
            <Wrapper>
                <PostTripCommonSidebar navigation={this.props.navigation} id="postTripCheckOutFlow" pageTitle="postCheckOut">

                    {this.checkBookSlotType() ? this.renderCarCheckOut() : this.renderHomeCheckOut()}
                </PostTripCommonSidebar>
            </Wrapper>
        </div>
    );
    // Customizable Area End
  }
}

export default PostTripCheckoutFlow;

const Wrapper  = styled(Box)({
    "& .addphotos": {
        display: 'grid', 
        gridTemplateColumns: 'repeat(3, 1fr)', 
        gap: '15px',
        "@media(max-width: 420px)": {
            display: 'grid', 
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },
    "& .addPhotosText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        textAlign: 'left',
        color:"#23395D",
    },
    "& .addPhotossubText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        textAlign: 'left',
        color:"#475467",
        margin: "5px 0"
    },
    "& .addPhotoText" : {
        color : "#3173E1",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center'
    },
    "& .damageText" : {
        color : "#23395D",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        margin: "10px 0"
    },
    "& .radioText" : {
        color : "#475467",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        
    },
    "& .radioTextBox" : {
        padding: "10px 20px",
        border: "1px solid #EAECF0",
        borderRadius: "12px",
        alignItems: "center",
        margin: "10px 0"
    },
    "& .continueButtonCheckout" : {
        width: '150px',
        height: '48px',
        gap: '8px',
        backgroundColor: '#3173E1',
        textTransform: "none",
        borderRadius: '12px',
        color: 'white',
        margin: "15px 0",
        padding: '14px 16px',
        fontWeight: "bold",
      },
      "& .backButtonCheckout" : {
        borderRadius: '12px',
        gap: '8px',
        color: '#475467',
        height: '48px',
        border: "1px solid #D0D5DD",
        fontWeight: "bold",
        backgroundColor: 'white',
        padding: '14px 16px',
        margin: "15px 0",
        width: '115px',
        textTransform: "none"
      },
      "& .labelFinalText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .mainFinalBox" : {
        margin: "15px 0"
    },
    "& .milegeFinalTextField" : {
        height: "45px",
        borderRadius: "12px",
        border: "1px solid #D0D5DD",
        padding : "15px"
    },
    "& .keyFinalBox": {
        padding: "15px 20px",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "12px",
        border: "1px solid #EAECF0",
        alignItems: "center"
    },
    "& .fuleFinalBox" : {
        boxShadow: "0px 4px 5px 0px #0000000D",
        border: "1px solid #DDE0E6",
        borderRadius: "12px",
        padding: "10px"
    },
    "& .fuelFinalText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 700,
        color: '#23395D'
    },
    "& .whiteBgFinalBox" : {
        background: "white",
        borderRadius: "12px",
        padding: "20px"
    },
    "& .sliderBox" : {
        padding: "0 15px"
    }
})

// Customizable Area End