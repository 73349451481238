// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import { CatalogeSummary, ReviewInfo } from "../../../components/src/TypeInterfaces.web";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start  
    bookeSlotId: string
    catalougeDetails: CatalogeSummary
    loading: boolean,
    conversationId: string,
    tabValue: number,
    fuelLevelBefore : number,
    fuelLevelAfter : number,
    reviewInfo : ReviewInfo,
    maintananceStatus : string,
    cleaningStatus : string,
    countMsg : number
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class TripCompletedController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCompletedTripInfoApiCallId: string = ""
    getTripInfoApiCallId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            bookeSlotId: "",
            catalougeDetails: this.catalougeSummary,
            loading: true,
            conversationId: "",
            tabValue: 0,
            fuelLevelAfter: 0,
            fuelLevelBefore : 0,
            reviewInfo : this.reviewDetails,
            cleaningStatus : "",
            maintananceStatus : "",
            countMsg : 0
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            switch (apiRequestCallId) {
                case this.getTripInfoApiCallId:
                    return this.getTripInfoSlotResponse(responseJson)
                case this.getCompletedTripInfoApiCallId:
                    return this.getCompletedTripResponse(responseJson)
                default:
                    break;
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        const bookSlotId = this.props.navigation.getParam("bookSlotId")
        this.getBookedSlotData(bookSlotId)
        this.setState({ bookeSlotId: bookSlotId })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevState.bookeSlotId !== this.state.bookeSlotId) {
            this.getTripData()
        }
    }

    getTripData = () => {
        const header = {
            token: localStorage.getItem("userToken")
        }

        this.getCompletedTripInfoApiCallId = apiCalling({
            header: JSON.stringify(header),
            method: configJSON.apiMethodTypeGet,
            endPoint: configJSON.getCompletedTripApiEndPoint(this.state.bookeSlotId)
        })
    }

    getBookedSlotData = (bookedSlotId: string) => {
        const header = {
            token: localStorage.getItem("userToken")
        }

        this.getTripInfoApiCallId = apiCalling({
            endPoint: `${configJSON.getReservationDetailApiEndPoint}/${bookedSlotId}`,
            header: JSON.stringify(header),
            method: configJSON.apiMethodTypeGet
        })
    }

    catalougeSummary: CatalogeSummary = {
        catalougeId: "",
        catalougeName: "",
        catalougeImg: "",
        reviews: "",
        rating: ""
    }

    reviewDetails : ReviewInfo = {
        reviewImg : "",
        reviewName : "",
        reviewCount : 0,
        reviewTime : "",
        reviewDescription : "",
        reviewDuration : "",
        reviewId : ""
    }

    redirectToBackPage = () => {
        this.props.navigation.goBack()
    }

    getTripInfoSlotResponse = (responseJson: any) => {

        if (responseJson && responseJson.catalogue && responseJson.catalogue.data) {
            const modifiedObj = responseJson.catalogue.data.attributes
            const conversationId = responseJson.catalogue.data.attributes.conversation && truthyValue(responseJson.catalogue.data.attributes.conversation.id)

            const catalogeInfo = {
                catalougeId: truthyValue(modifiedObj.catalogue_id),
                catalougeName: truthyValue(modifiedObj.catalogue_name),
                catalougeImg: this.handleImages(responseJson.catalogue.data),
                reviews: truthyValue(modifiedObj.rating.reviews_count),
                rating: truthyValue(modifiedObj.rating.average_rating)
            }

            this.setState({ catalougeDetails: catalogeInfo, loading: false, conversationId })
        } else {
            this.setState({ loading: false })
        }
    }

    getCompletedTripResponse = (responseJson: any) => {
        if(responseJson && responseJson.data) {
            const attrObj = responseJson.data.attributes

            const reviewInfo : ReviewInfo = {
                reviewImg : truthyValue(attrObj.review.data.attributes.profile_img.url),
                reviewName : truthyValue(attrObj.review.data.attributes.reviewer_name),
                reviewCount : truthyValue(attrObj.review.data.attributes.rating),
                reviewTime : truthyValue(attrObj.review.data.attributes.created_at),
                reviewDescription :truthyValue(attrObj.review.data.attributes.comment),
                reviewDuration : truthyValue(attrObj.review.data.attributes.web_time_ago),
                reviewId : truthyValue(attrObj.review.data.id)
            }

            const cleaningStatus = truthyValue(attrObj.cleaning_status)
            const maintananceStatus = truthyValue(attrObj.maintenance_alerts)

            this.setState({ reviewInfo, loading : false, cleaningStatus, maintananceStatus })
        } else {
            this.setState({ loading : false})
        }
    }

    handleImages = (item: any) => {
        // imageUrl
        let imgUrl: string = ""
        if (item.attributes && item.attributes.images.length > 0 && item.attributes.images[0].url) {
            imgUrl = item.attributes.images[0].url
        }

        return imgUrl
    }

    changeTab = (event: React.ChangeEvent<{}>, value: number) => {
        this.setState({ tabValue: value })
    }

    setCointMsg = (number : number) => {
        this.setState({ countMsg : number})
    }


    // Customizable Area End
}

// Customizable Area End