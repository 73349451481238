import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { 
    Box, 
    Button,
    DialogActions,
    OutlinedInput,
    Typography,
} from '@material-ui/core';
import { styled } from "@material-ui/styles";
import { CardDetails } from "./TypeInterfaces.web";

interface CardComponentProps {
    addCardDetail: (payload: CardDetails) => void;
    handleClose: () => void;
}

const CardComponent: React.FC<CardComponentProps> = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState<string>("");
  const [nameErr, setNameErr] = useState<string>("");
  const [numberErr, setNumberErr] = useState<string>("");
  const [dateErr, setDateErr] = useState<string>("");
  const [cvvErr, setCvvErr] = useState<string>("");

  const [message, setMessage] = useState<any>(null);

  const [cardDetails, setCardDetails] = useState<{ [key: string]: string }>({
    street: "",
    city: "",
    state: "",
    zip_code: "",
    country: ""
  });
  const [cardDetailsErrors, setCardDetailsErrors] = useState<{ [key: string]: boolean }>({
    street: false,
    city: false,
    state: false,
    zip_code: false,
    country: false
  });

  const defaultCardDetails = {
    street: "",
    city: "",
    state: "",
    zip_code: "",
    country: ""
  }

  const defaultCardDetailsError = {
    street: false,
    city: false,
    state: false,
    zip_code: false,
    country: false
  }

  const clearStripeModal = () => {
    const cardNo = elements?.getElement(CardNumberElement);
    const cardExpiry = elements?.getElement(CardExpiryElement);
    const cardCVV = elements?.getElement(CardCvcElement);
    cardNo?.clear();
    cardCVV?.clear();
    cardExpiry?.clear();
    setName("");
  };

  const isNameValid = (): boolean => {
    if (name.length < 1) {
      setNameErr("Your name is incomplete.");
      return false;
    } else if (!(/^[A-Za-z]+( [A-Za-z]+)*$/i.test(name)) || name.trim() === "") {
      setNameErr("Your name is invalid.");
      return false;
    } else {
      setNameErr("");
      return true;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    if(!handleAllValidationOnCardSave()) {
        return false;
     }
  
    const nameValid = isNameValid();
    if (numberErr !== "" || dateErr !== "" || cvvErr !== "") {
      if (!nameValid) {
        return;
      }
    }
  
    const cardElement = elements.getElement(CardNumberElement);
    
    if (!cardElement) {
      setMessage("Card number element not found.");
      return;
    }
  
    const { token, error } = await stripe.createToken(cardElement);
  
    if (!nameValid || numberErr || dateErr || cvvErr) {
      return;
    }
  
    if (error) {
      setMessage(error.message);
    } else {
        if( token && token.id) {
            const payload: CardDetails = {
                card_token: token.id,
                street: cardDetails.street, 
                city: cardDetails.city,
                state: cardDetails.state,
                zip_code: cardDetails.zip_code,
                country: cardDetails.country,
            }

            props.addCardDetail(payload)
        }
    }
  };
  

  const handleCancelClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setNameErr("");
    setNumberErr("");
    setDateErr("");
    setCvvErr("");
    props.handleClose();
    clearStripeModal();
  };

  const handleChangeOfAddCard = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formValue = event.target.value;
    const formName = event.target.name;
  
    // Validate input
    if (formValue.trim() === "") {
      setCardDetailsErrors(prevErrors => ({ ...prevErrors, [formName]: true }));
    } else {
      setCardDetailsErrors(prevErrors => ({ ...prevErrors, [formName]: false }));
    }
  
    // Update card details
    setCardDetails(prevDetails => ({ ...prevDetails, [formName]: formValue }));
  };
  
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (
            !(
                (event.key >= '0' && event.key <= '9') ||
                event.key === 'Backspace' ||
                event.key === 'Delete' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'Tab'
            )
        ) {
            event.preventDefault();
        }
    }
  
    const handleErrorFields = (checkKey: boolean, errMsg: string) => {
        return checkKey && <div className="textLabel" style={{ color: "red", marginBottom: "7px" }}>{errMsg}</div>
    }

    const handleAllValidationOnCardSave = () => {
        const checkValidation = {
            street: false,
            city: false,
            state: false,
            zip_code: false,
            country: false
        };

        if (cardDetails.street.trim() === "") {
            checkValidation.street = true;
        }
        if (cardDetails.city.trim() === "") {
            checkValidation.city = true;
        }
        if (cardDetails.state.trim() === "") {
            checkValidation.state = true;
        }
        if (cardDetails.zip_code.trim() === "") {
            checkValidation.zip_code = true;
        }
        if (cardDetails.country.trim() === "") {
            checkValidation.country = true;
        }

        setCardDetailsErrors(checkValidation);

        return Object.values(checkValidation).every((value) => value === false);
    };
  
  return (
    <R1Form>
          <form className={"r1form"} id="payment-form" onSubmit={handleSubmit}>

              <Typography className="subHeaderText">Card Information</Typography>

              <Box mb="20px" display="flex" flexDirection="column">
                  <label className="labelField">Card Holder Name</label>
                  <input
                      className="inputField"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => {
                          setName(e.target.value);
                          setNameErr("");
                          setMessage("");
                      }}
                      onBlur={() => isNameValid()}
                  />
                  <div className="error">{nameErr}</div>
              </Box>
              <Box mb="20px">
                  <label className="labelField">Card Number</label>
                  <CardNumberElement
                      id='cardNumberId'
                      className="inputField"
                      options={{ showIcon: true }}
                      onChange={(e) => {
                          setNumberErr(e.error?.message || "");
                          setMessage("");
                      }}
                  />
                  <div className="error">{numberErr}</div>
              </Box>
              <Box display="flex">
                  <Box mb="20px" mr="10px" width="50%">
                      <label className="labelField">Expiration Date</label>
                      <CardExpiryElement
                          id='cardExpiryId'
                          className="inputField"
                          onChange={(e) => {
                              setDateErr(e.error?.message || "");
                              setMessage("");
                          }}
                      />
                      <div className="error">{dateErr}</div>
                  </Box>
                  <Box mb="20px" width="50%">
                      <label className="labelField">Security Code</label>
                      <CardCvcElement
                          id='cardCvvId'
                          className="inputField"
                          onChange={(e) => {
                              setCvvErr(e.error?.message || "");
                              setMessage("");
                          }}
                      />
                      <div className="error">{cvvErr}</div>
                  </Box>
              </Box>

              <Typography className="subHeaderText">Billing Information</Typography>
              <Box className="inputBox">
                  <Typography className="textLabel">Street Address</Typography>
                  <OutlinedInput
                      className="textField"
                      name="street"
                      onChange={handleChangeOfAddCard}
                      value={cardDetails.street}
                      placeholder="Street Address"
                      fullWidth
                  />
                  {handleErrorFields(cardDetailsErrors.street, "Please enter valid address")}

              </Box>

              <Box className="gridBox">
                        <Box className="inputBox">
                            <Typography className="textLabel">Country</Typography>
                            <OutlinedInput
                                className="textField"
                                name="country"
                                onChange={handleChangeOfAddCard}
                                value={cardDetails.country}
                                placeholder="Country"
                                fullWidth
                            />
                            {handleErrorFields(cardDetailsErrors.country, "Please enter valid country")}

                        </Box>
                        <Box className="inputBox">
                            <Typography className="textLabel">State</Typography>
                            <OutlinedInput
                                className="textField"
                                name="state"
                                onChange={handleChangeOfAddCard}
                                value={cardDetails.state}
                                placeholder="State"
                                fullWidth
                            />
                            {handleErrorFields(cardDetailsErrors.state, "Please enter valid state")}

                        </Box>
                    </Box>
                    <Box className="gridBox">
                        <Box className="inputBox">
                            <Typography className="textLabel">City</Typography>
                            <OutlinedInput
                                className="textField"
                                fullWidth
                                name="city"
                                onChange={handleChangeOfAddCard}
                                value={cardDetails.city}
                                placeholder="City"
                            />
                            {handleErrorFields(cardDetailsErrors.city, "Please enter valid city")}

                        </Box>
                        <Box className="inputBox">
                            <Typography className="textLabel">Zip Code</Typography>
                            <OutlinedInput
                                className="textField"
                                onKeyDown={handleKeyDown}
                                name="zip_code"
                                onChange={handleChangeOfAddCard}
                                value={cardDetails.zip_code}
                                placeholder="Zip Code"
                                fullWidth
                            />
                            {handleErrorFields(cardDetailsErrors.zip_code, "Please enter valid zip coder")}

                        </Box>
                    </Box>


              <DialogActions style={{ borderTop: "1px solid #EAEAEA" }}>

                  <Box className="gridBox">
                      <Button data-test-id="cancelBtn" className="cancelBtn" onClick={handleCancelClose}>Cancel</Button>
                      <Button data-test-id="saveBtn" type="submit" className="saveBtn">Save</Button>
                  </Box>
              </DialogActions>

              {message && <div style={{ color: "red" }} id="payment-message">{message}</div>}
          </form>      
    </R1Form>
  );
};

const R1Form = styled(Box)({
  width: "100%",
  "& .r1form": {
    boxShadow: "none !important",
    borderRadius: "0 !important",
    "@media (max-width: 600px)": {
      minWidth: "100% !important",
      width: "100% !important",
      borderTop: "1px solid #dcdcdc",
      borderRight: "none",
      padding: "20px 0 0 !important",
    },
    "& .labelField": {
        fontWeight: 500,
        color: "#667085",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        marginBottom: "5px"
    },
    "& .inputField": {
        border: "1px solid #CBD5E1",
        borderRadius: "30px",
        padding: "10px 14px",
    },
    "& .cancelBtn": {
        height: "40px",
        width: "80px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        border: "1px solid #D0D5DD",
        borderRadius: 30,
        color: "#475467",
    },
    "& .saveBtn": {
        height: "40px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        borderRadius: 30,
        width: "120px",
        color: "white",
        background: "#3173E1",
    },
    "& .error": {
      color: "red",
    },
  },
});

export default CardComponent;