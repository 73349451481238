// Customizable Area Start
import React from "react";
import {
    Box,
    Grid,
    Typography,
    styled,
    Input
} from "@material-ui/core"
import AppHeader from "../../notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import ProfileSidebar from "../../../components/src/ProfileSidebar.web";
import ProfileHeader from "../../../components/src/ProfileHeader.web";
import Loader from "../../../components/src/Loader.web";

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { ticketIcon } from "../../user-profile-basic/src/assets";
import { addIcon, sendIcon } from "../../../blocks/chat/src/assets";

import TicketSupportChatController, {
    Props,
  } from "./TicketSupportChatController";


class TicketSupportChat extends TicketSupportChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    renderBreadcrumbSection = () => {
        return (
            <Box className="breadcrumbsBox">
                <Typography className="breadcrumbText" onClick={this.navToSupportCenter} data-test-id="breadcrumbText">Support Center</Typography>
                <ArrowForwardIosIcon style={{ height: 14, color: "#64748B" }} fontSize="small" />
                <Typography className="breadcrumbText" onClick={this.navToTicketCenter} data-test-id="breadcrumbTextTwo">Ticket Support</Typography>
                <ArrowForwardIosIcon style={{ height: 14, color: "#64748B" }} fontSize="small" />
                <Typography className="breadcrumbText"><span className="activeColorText">{this.state.selectedTicket.ticketTitle}</span></Typography>
            </Box>
        )
    }

    renderTicketBox = () => {
        return (
            <Box className="mainTicketBoxChat" >
                <Box className="innerBox1Chat">
                    <Box style={{ display: "flex", gap: 8 }}>
                        <div>
                            <img src={ticketIcon} />
                        </div>
                        <div>
                            <Typography className="ticketMessageChat">{this.state.selectedTicket.ticketTitle}</Typography>
                            <Typography className="catogoryTextChat">Catogory : <span className="catogoryTypeChat"> {this.state.selectedTicket.ticketCatogory}</span></Typography>
                        </div>
                    </Box>
                    {this.handleChatTicketStatus(this.state.selectedTicket.ticketStatus) ?
                        <Box className="currentOpenChat">
                            Currently open
                        </Box>
                        :
                        <Box className="resolvedChat">
                            Resolved
                        </Box>
                    }
                    
                </Box>

                <Box className="innerBox1Chat">
                    <Typography className="ticketSubMessageChat">{this.state.selectedTicket.ticketMessage}</Typography>
                    <Typography className="ticketSubMessageChat">{this.state.selectedTicket.ticketTime}</Typography>
                </Box>
            </Box>
        )
    }

    renderChatSection = () => {
        return(
            <Box className="mainBoxChatPart">
                <Box className="senderBoxChatPart">
                    <img
                        className="imgBoxChatPart"
                        src={this.state.adminImage}
                    />
                    <Typography className="senderNameBoxChatPart">Support</Typography>
                    <Box className="chipBoxChatPart">
                        <Typography className="innerTextChatPart">Support</Typography>
                    </Box>
                </Box>

                <Box className="chatAreaChatPart">
                    {this.state.chatMessage.map(item => {

                        return !item.isSupportMsg ?
                            <Box key={item.chatId}>
                                <Box className="senderChatBoxChatPart">
                                    <Box>
                                        <Box className="commonChatBoxChatPart senderChatRadiusChatPart">
                                            <Typography className="chatTextChatPart">
                                                {item.chatMessage}
                                            </Typography>
                                        </Box>
                                        <Typography className="timeTextChatBlockChatPart" style={{ textAlign: "right" }}>{item.chatTime}</Typography>
                                    </Box>
                                    <img className="imgChatChatPart" src={this.state.userImage} />

                                </Box>
                            </Box>
                            :
                            <Box key={item.chatId}>
                                <Box className={"receiverChatBoxChatPart"}>
                                    <img className="imgChatChatPart" src={this.state.adminImage} />
                                    <Box>
                                        <Box className={`commonChatBoxChatPart receiverChatRadiusChatPart`}>
                                            <Typography className="chatTextChatPart">
                                                {item.chatMessage}
                                            </Typography>
                                        </Box>
                                        <Typography className="timeTextChatBlockChatPart">{item.chatTime}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                    }



                    )}

                    

                </Box>

                <Box className="messageBoxChatPart">
                    <Box className="textInputChatPart">
                        <Input
                            startAdornment={<img src={addIcon} />}
                            endAdornment={<img src={sendIcon} data-test-id="sendIcon" onClick={this.sendMsgToSupport} style={{ cursor: "pointer" }} alt="send" />}
                            placeholder="Write your message..."
                            className="inputTextChatPart"
                            value={this.state.sendTexMsg}
                            onChange={this.handleTextMessageChange}
                            onKeyDown={this.handleKeyDown}
                            data-test-id="inputText"
                            fullWidth
                            disableUnderline
                        />
                    </Box>
                </Box>

            </Box>
        )
    }


  render() {
    // Customizable Area Start
    
    return (
        <div>
            <AppHeader navigation={this.props.navigation} />

            <Wrapper>

                <ProfileHeader />

                <Grid container spacing={3}>
                    <Grid item className="mainGrid">
                        <Box style={{ margin: "30px 0" }} className="mainBoxStyle">
                            <ProfileSidebar activeKey={5} navigation={this.props.navigation} />
                        </Box>
                    </Grid>
                    <Grid item md={9} className="mainBoxSecondary">
                        {this.state.loader ? 
                               <Loader loading />
                            :
                            <Box style={{ margin: "30px 0" }}>
                                
                                {this.renderBreadcrumbSection()}
                                {this.renderTicketBox()}
                                {this.renderChatSection()}

                            </Box>
                        }
                    </Grid>
                </Grid>
            </Wrapper>


            <Footer />
        </div>
    );
    // Customizable Area End
  }
}

export default TicketSupportChat;

const Wrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    "& .mainBox": {
        "@media(max-width:960px)": {
            display: "flex",
        }
    },
    "& .mainGrid": {
        "@media(max-width:960px)": {
            width: "100% !important",
            overflowX: "scroll",
        }
    },
    "& .mainBoxStyle": {
        "@media(max-width:960px)": {
            display: "flex",
            "& .MuiGrid": {
                maxWidth: "100% !important"
            }
        }
    },
    "& .mainBoxSecondary": {
        width: "100% !important"
    },
    "& .paddingBox": {
        padding: "0 30px",
    },
    "& .subText": {
        fontFamily: "Plus Jakarta Sans",
        color: "#475467",
        fontSize: "16px",
        fontWeight: 400,
    },
    "& .breadcrumbsBox": {
        gap: "10px",
        marginBottom: "30px",
        alignItems: "center",
        display: "flex",
    },
    "& .activeColorText" : {
        color: "#3173E1",
      },
    "& .breadcrumbText": {
        fontSize: '14px',
        textAlign: 'left',
        fontWeight: 600,
        color: '#64748B',
        fontFamily: 'Plus Jakarta Sans',
        cursor: "pointer"
    },
    "& .mainTicketBoxChat" : {
        border: '1px solid #EAECF0',
        borderRadius: '12px',
        margin:"15px 0",
        padding: '20px',
        cursor : "pointer"
    },
    "& .innerBox1Chat" : {
        margin: "10px 0",
        display: "flex",
        justifyContent: "space-between",
    }, 
    "& .ticketTextChat": {
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 500,
        color: "#101828",
        fontSize: "16px",
    },
    "& .catogoryTextChat" : {
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 500,
        fontSize: "14px",
        color: "#344054",
    },
    "& .ticketMessageChat" : {
        fontWeight: 600,
        fontSize: "16px",
        fontFamily: "Plus Jakarta Sans",
        color: "#101828",
    },
    "& .ticketSubMessageChat" : {
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Plus Jakarta Sans",
        color: "#475467",
    },
    "& .catogoryTypeChat" : {
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Plus Jakarta Sans",
        color: "#667085",
    },
    "& .resolvedChat" : {
        height: "20px",
        borderRadius: 16,
        fontFamily: 'Plus Jakarta Sans',
        padding: "2px 10px",
        fontSize: '14px',
        color: "#067647",
        fontWeight: 500,
        background: "#ECFDF3",
        border: "1px solid #ABEFC6"
    },
    "& .currentOpenChat" : {
        fontSize: '14px',
        fontFamily: 'Plus Jakarta Sans',
        color: "#3173E1",
        fontWeight: 500,
        borderRadius: 16,
        height: "20px",
        padding: "2px 10px",
        border: "1px solid #3173E1"
    },
    "& .mainBoxChatPart": {
        border: "1px solid #EAECF0",
        borderRadius: "12px"
    },
    "& .senderBoxChatPart": {
        padding: "20px",
        display: "flex",
        alignItems: "center",
        gap: "12px",
        borderBottom: "1px solid #EAECF0",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    "& .imgBoxChatPart" : {
        borderRadius: "100%",
        height : 32,
        width: 32
    },
    "& .senderNameBoxChatPart" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        textAlign: 'left',
        color: '#23395D'
    },
    "& .chipBoxChatPart" : {
        background:"#F9FAFB",
        border: "1px solid #EAECF0",
        borderRadius: "6px"
    },
    "& .chatAreaChatPart": {
        padding: "0 20px",
        maxHeight: "675px",
        overflowY: "scroll",
        scrollbarWidth: "none"
    },
    "& .innerTextChatPart": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 500,
        color: '#344054',
        padding: "2px 6px",
        textTransform: "capitalize"
    },
    "& .commonChatBoxChatPart": {
        background: "#F4F4F5",
        padding: "10px 16px",
        width: "max-content"
    },
    "& .senderChatRadiusChatPart" : {
        borderRadius: "16px 0px 16px 16px",
    },
    "& .receiverChatRadiusChatPart" : {
        borderRadius: "0 16px 16px 16px",        
    },
    "& .chatTextChatPart" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        textAlign: 'left',
        color: '#475467',
        maxWidth: "465px"
    },
    "& .timeTextChatBlockChatPart": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 400,
        textAlign: 'left',
        color: '#667085',
        margin: "8px 0"
    },
    "& .receiverChatBoxChatPart": {
        display: "flex",
        gap: "12px",
        justifyContent: "start",
        margin: "10px 0",
    },
    "& .senderChatBoxChatPart": {
        display: "flex",
        justifyContent: "end",
        gap: "12px",
        margin: "10px 0",
    },
    "& .imgChatChatPart" : {
        height: 40,
        width: 40,
        borderRadius: "100%",
    },
    "& .messageBoxChatPart" : {
        border: '1px solid #D0D5DD',
        borderRadius: '12px',
        margin: '10px 20px 20px 20px',
    },
    "& .textInputChatPart" : {
        padding: '16px'
    },
    "& .inputTextChatPart" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        textAlign: 'left',
        color: '#475467',
    },
    "& .inputTextChatPart .MuiInputBase-input": {
        margin: "0 10px"
    }
})

// Customizable Area End