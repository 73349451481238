// Customizable Area Start
import React from "react";

import {
  Box,
  styled,
  Typography,
  Button,
  Select,
  MenuItem,
  Checkbox
} from "@material-ui/core";
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Loader from "../../../components/src/Loader.web";
import Footer from "../../../components/src/Footer.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import TripPreferenceController, {
  Props,
  configJSON
} from "./TripPreferenceController";

export default class TripPreference extends TripPreferenceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderCoomonHeaderPart = (headerName : string) => {
    return (
        <Box className="tripSection">
          <Typography className="tripSectionHead">
            {headerName}
          </Typography>
          <HelpOutlineOutlinedIcon />
        </Box>
    )
  }

  renderMinimumTripDurationSection = () => {
    return (
        <Box className="tripBlockSection">
          {this.renderCoomonHeaderPart("Minimum trip duration")}

          {this.state.tripPreferenceType.minDuration ?
            <Box className="outerBox">
              <Select
                className="selectComp subTitleText"
                style={{ background: "white" }}
                fullWidth
                data-test-id="minDurationSelect"
                name="minDuration"
                value={this.state.tripPreferenceValue.minDuration}
                disableUnderline
                onChange={(event) => this.handleSelectChange(event,"minDuration")}
              >
                  {this.minimumTripDurationData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label} {option.isRecommand && "(Recommended)"}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            :
            <Box className="outerBox">
              <Typography className="subTitleText">{this.state.displayData.minDuration}</Typography>
              <Typography className="editBtn" data-test-id="minDuration" onClick={() => this.onEditClick("minDuration")}> Edit</Typography>
            </Box>
          }
        </Box>
    )
  }

  renderMaximumTripDurationSection = () => {
    return (
        <Box className="tripBlockSection">
          {this.renderCoomonHeaderPart("Maximum trip")}
         
          {this.state.tripPreferenceType.maxDuration ?
            <Box className="outerBox">
              <Select
                className="selectComp subTitleText"
                style={{ background: "white" }}
                fullWidth
                name="maxDuration"
                data-test-id="maxDurationSelect"
                value={this.state.tripPreferenceValue.maxDuration}
                disableUnderline
                onChange={(event) => this.handleSelectChange(event,"maxDuration")}
              >
                  {this.maximumTripDurationData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label} {option.isRecommand && "(Recommended)"}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            :
            <Box className="outerBox">
              <Typography className="subTitleText">{this.state.displayData.maxDuration}</Typography>
              <Typography className="editBtn"  data-test-id="maxDuration" onClick={() => this.onEditClick("maxDuration")}> Edit</Typography>
            </Box>
          }
        </Box>
    )
  }

  renderAdvanceNoticeSection = () => {
    return (
        <Box className="tripBlockSection">
          {this.renderCoomonHeaderPart("Advance notice")}
         
          {this.state.tripPreferenceType.advanceNotice ?
            <Box className="outerBox">
              <Select
                className="selectComp subTitleText"
                style={{ background: "white" }}
                fullWidth
                disableUnderline
                name="advanceNotice"
                data-test-id="advanceNoticeSelect"
                value={this.state.tripPreferenceValue.advanceNotice}
                onChange={(event) => this.handleSelectChange(event,"advanceNotice")}
              >
                  {this.advancedNoticeData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label} {option.isRecommand && "(Recommended)"}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            :
            <Box className="outerBox">
              <Typography className="subTitleText">{this.state.displayData.advanceNotice}</Typography>
              <Typography className="editBtn"  data-test-id="advanceNotice" onClick={() => this.onEditClick("advanceNotice")}> Edit</Typography>
            </Box>
          }
        </Box>
    )
  }

  renderWeekendCheckSection = () => {
    return (
      <Box className="tripBlockSection">
        {this.renderCoomonHeaderPart("Weekend duration trips")}

        <Box className="outerBox">
          <Typography className="subTitleText">2-day min for weekend trips</Typography>
          <Checkbox
            checkedIcon={<RadioButtonCheckedIcon style={{ color: "#3273E1" }} />}
            icon={<RadioButtonUncheckedIcon style={{ color: "#3273E1" }}  />}
            data-test-id="weekEndDuration"
            checked={this.state.tripPreferenceValue.weekEndDuration}
            onChange={(event:React.ChangeEvent<HTMLInputElement>) => this.handleChangeforChecks(event,"weekEndDuration")}
          />
        </Box>

      </Box>
    )
  }

  renderInstantAcceptanceCheckSection = () => {
    return (
      <Box className="tripBlockSection">
        {this.renderCoomonHeaderPart("Instant acceptance")}
        
        <Box className="outerBox">
          <Typography className="subTitleText">Allow instant bookings</Typography>
          <Checkbox
            checkedIcon={<RadioButtonCheckedIcon style={{ color: "#3273E1" }} />}
            icon={<RadioButtonUncheckedIcon style={{ color: "#3273E1" }}  />}
            checked={this.state.tripPreferenceValue.instantAcceptance}
            data-test-id="instantAcceptance"
            onChange={(event:React.ChangeEvent<HTMLInputElement>) => this.handleChangeforChecks(event,"instantAcceptance")}
          />
        </Box>

      </Box>
    )
  }
  
  render() {
    // Customizable Area Start

    return (
      <CustomBox>
        <AppHeader activeLink="Dashboard" navigation={this.props.navigation} />
          {this.state.loading ? <Loader loading />
            :
            <Box className="mainBox">
              <Box className="LocationSection">
                <Box className="backSection" onClick={this.navToPreviousPage} data-test-id="back-btn">
                  <ArrowBackIosIcon className="backIcon" />
                  <Typography className="backText">Back</Typography>
                </Box>
                <Box className="backSectionIcon">
                  <ArrowBackIosIcon className="backIcon" />
                </Box>
                <Box className="locationSectionBox">
                  <Typography className="loactionText">
                    {configJSON.tripHeadText}
                  </Typography>

                  <Box className="tripSectionBox">

                    {this.renderMinimumTripDurationSection()}
                    {this.renderMaximumTripDurationSection()}
                    {this.renderAdvanceNoticeSection()}
                    {this.renderWeekendCheckSection()}
                    {this.renderInstantAcceptanceCheckSection()}

                  </Box>
                </Box>

                <Button data-test-id="saveBtn" className="saveBtn" onClick={this.updateTripPreferenceData}>Save</Button>
              </Box>
            </Box>
          }
          
        <Footer />
      </CustomBox>
    );
    // Customizable Area End
  }
}
const CustomBox = styled(Box)({
  "& .mainBox": {
    width: "100%",
    maxWidth: "1220px",
    margin: "40px auto",
    "@media(max-width:1280px)": {
      maxWidth: "100%",
      margin: "40px",
    }
  },
  "& .LocationSection": {
    width: "72.5%",
    "@media(max-width:600px)": {
      width: "90%"
    }
  },
  "& .backSectionIcon": {
    display: "none",
    "@media(max-width: 600px)": {
      display: "block"
    }
  },
  "& .backSection": {
    display: "flex",
    alignItems: "center",
    color: "#667085",
    gap: "6px",
    cursor: "pointer",
    "@media(max-width: 600px)": {
      display: "none"
    }
  },
  "& .backText": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px"
  },
  "& .backIcon": {
    width: "20px",
    height: "20px"
  },
  "& .locationSectionBox": {
    flexDirection: "column",
    paddingTop: "32px",
    display: "flex",
    gap: "32px",
    "@media(max-width:600px)": {
      width: "100%"
    }
  },
  "& .tripSectionBox": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    "@media(max-width:600px)": {
      width: "80%"
    }
  },
  "& .tripSectionHead": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#23395D"
  },
  "& .loactionText": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "30px",
    fontWeight: 600,
    lineHeight: "38px",
    textAlign: "left",
    color: "#101828",
    "@media(max-width:600px)": {
      textAlign: "center",
      marginTop: "-65px"
    }
  },

  "& .tripSection": {
    display: "flex",
    alignItems: "center",
    color: "#23395D",
    gap: "5px"
  },
  "& .tripSectionField": {
    border: "1px solid #D0D5DD",
    borderRadius: "12px",
    width: "100%",
    padding: "20px"
  },
  "& .tripBlockSection": {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  "& .cursor": {
    cursor: "pointer"
  },
  "& .editBtn": {
    cursor: "pointer",
    color: "#0F73EE",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontWeight: 500,
  },
  "& .saveBtn" : {
    height: 40,
    width: 90,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    textTransform: "none",
    fontWeight: 600,
    background: "#3173E1",
    borderRadius: "12px",
    color: 'white',
    margin: "20px 0"
  },
  "& .cancelBtn" : {
    height: 40,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    borderRadius: "12px",
    fontWeight: 600,
    textTransform: "none",
    width: 100,
    background: "white",
    color: '#475467',
    margin: "20px 0",
    border: "1px solid #D0D5DD", 
  },
  "& .outerBox" : {
    border: "1px solid #D0D5DD",
    padding : "20px",
    width: 885,
    borderRadius: 12,
    display: "flex", 
    justifyContent: "space-between", 
    alignItems : "center",
    "@media(max-width:1280px)": {
      width: "100%",
    } 
  },
  "& .subTitleText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '18px',
    fontWeight: 600,
    color: '#23395D',
  },
  "& .selectComp .MuiInputBase-root" : {
    fontFamily: 'Plus Jakarta Sans',
     fontSize: '18px',
    fontWeight: 600,
    color: '#23395D',
  },
  "& .selectComp .MuiSelect-select:focus" : {
    background: "white"
  }
});

// Customizable Area End
