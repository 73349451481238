import React from 'react'
import { 
    Box, 
    styled,
    Select,
    Typography,
    MenuItem,
} from "@material-ui/core"
import Pagination from '@material-ui/lab/Pagination';
import { arrowLeftIcon, arrowRightIcon } from '../../blocks/reservations/src/assets';

interface Props {
    totalPages : number,
    currentPage : number,
    perPage : number,
    onPerPageChange: (event: React.ChangeEvent<{ value: unknown }>) => void,
    onPaginationChange: (event: React.ChangeEvent<unknown>, page: number) => void,
    onPreviousClick: () => void,
    onNextClick: () => void,
}

const CustomPagination : React.FC<Props> = (props) => {
  return (
      <Wrapper>
              <Box className="paginationBox">
                  <CustomPaginationWrapper
                      data-test-id="pagination"
                      count={props.totalPages}
                      page={props.currentPage}
                      onChange={props.onPaginationChange}
                      siblingCount={0}
                      boundaryCount={3}
                  />
                  <Box className='selectpageBox'>
                      Show:
                      <Select
                          value={props.perPage}
                          onChange={props.onPerPageChange}
                          disableUnderline
                          data-test-id="pageChange"
                          className="paginationSection"
                      >
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={9}>9</MenuItem>
                      </Select>
                  </Box>
              </Box>

              <Box className='flexClass'>

                    {props.currentPage > 1 &&
                        <Box onClick={props.onPreviousClick} className='prevBtnFlexCSS'>
                            <img style={{ width: "20px" }} src={arrowLeftIcon}></img>
                            <Typography >Previous</Typography>
                        </Box>
                    }
                      
                    {props.currentPage < props.totalPages &&
                        <Box onClick={props.onNextClick} className='nextBtnFlexCSS'>
                            <Typography >Next</Typography>
                            <img style={{ width: "20px" }} src={arrowRightIcon}></img>
                        </Box>
                    }

              </Box>
      </Wrapper>
  )
}

const Wrapper = styled(Box)({
    display: 'flex', 
    justifyContent : "space-between",
    "& .paginationBox" : {
        display: 'flex', 
        alignItems: 'center', 
        gap: '30px'
    },
    "& .selectpageBox" : {
        display: 'flex', 
        alignItems: 'center', 
        gap: '15px', 
        fontSize: '14px', 
        fontWeight: 700, 
        color: '#475467', 
        cursor: "pointer"
    },
    "& .paginationSection" : {
        color: '#475467',
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '14px',
    },
    "& .flexClass" : {
        display: "flex"
    },
    "& .nextBtnFlexCSS" : {
        display: "flex", 
        gap: 8, 
        alignItems: 'center', 
        marginLeft: "14px", 
        cursor: "pointer"
    },
    "& .prevBtnFlexCSS" : {
        display: "flex", 
        gap: 8, 
        cursor: "pointer",
        alignItems: 'center'
    }
})


const CustomPaginationWrapper = styled(Pagination)({
    '& .MuiPagination-ul li:first-child': {
      display: 'none',
    },
    '& .MuiPagination-ul li:last-child': {
      display: 'none',
    },
    '@media(max-width: 767px)': {
      '& .MuiPaginationItem-root': {
        minWidth: 25, height: 25, margin: 0
      }
    }
  })

export default CustomPagination