// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface ImageFile {
    preview: string;
    file: File | null;
  }

interface S {
  // Customizable Area Start  
  images : ImageFile[],
  userId : string,
  loading: boolean,
  damageValue: string,
  checkoutCompleted : boolean,
  bookedSlotId : string,
  bookSlotType : string,
  finalCheckout : boolean,
  odometerValue :  string,
  keyProvided : boolean,
  fuelLevel: number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostTripCheckoutFlowController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  saveCheckOutImgApiCallId : string = ""
  getCheckOutImgApiCallId : string = ""
  startTripApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        images : Array(9).fill({ preview: '', file: null }),
        userId: "",
        loading: true,
        damageValue: "",
        checkoutCompleted: false,
        bookedSlotId: "",
        bookSlotType : "",
        finalCheckout : false,
        odometerValue : "",
        fuelLevel: 0,
        keyProvided : true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getCheckOutImgApiCallId : 
          return this.handleResponseForCheckOutImages(responseJson)
        case this.saveCheckOutImgApiCallId : 
          return this.handleResponseForConfirmCheckoutAPiCallId(responseJson)
        default : 
           break
      }

            
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    const bookedSlotId = this.props.navigation.getParam("bookSlotId")
    const bookedSlotType = this.props.navigation.getParam("bookSlotType") 
    this.setState({ bookedSlotId : bookedSlotId, bookSlotType : bookedSlotType}) 
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
      if(prevState.bookedSlotId !== this.state.bookedSlotId) {
         this.getCheckOutImages()
      }
  }

   handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = event.target.files?.[0];
    if (file) {
      const newImages = [...this.state.images];
      newImages[index] = { preview: URL.createObjectURL(file), file };
      this.setState({ images : newImages})
    }
  };

  handleResponseForCheckOutImages = (responseJson : any) => {
    if(responseJson && responseJson.data ) {
        const modifyJson = truthyValue(responseJson.data.attributes)

        const imageArr = this.showImagePreview(truthyValue(modifyJson.images))
        const damageValue = truthyValue(modifyJson.issues)
        const fuelValue = truthyValue(modifyJson.fuel_level)
        const odometerVal = truthyValue(modifyJson.mileage_odometer)
        const keyVal = truthyValue(modifyJson.is_key_provided)

         this.setState({ images : imageArr, loading: false, damageValue : damageValue.toString(), fuelLevel : fuelValue, odometerValue : odometerVal, keyProvided : keyVal})
        
    } else {
        this.setState({ loading : false})
    }
  }

  handleResponseForConfirmCheckoutAPiCallId = (responseJson : any) => {
    if(responseJson && responseJson.data && responseJson.data.id !== null) {
        toast.success("All Photos Saved Successfully")
        this.setState({ loading : false, checkoutCompleted : true})
        this.redirectToReviewPage()
    } else {
        toast.error("Something went wrong")
        this.setState({ loading : false})
    }
  }

   saveHomeCheckOutImages = () => {

    const unfilledImageSlots = this.state.images.filter(image => image.file === null).length;
    
    if (unfilledImageSlots > 0) {
      toast.error('Please upload all images.');
      return;
    }

    this.setState({ loading : true})

    const header = {
        "token": localStorage.getItem("userToken"),
      };

    const formData = new FormData()

    formData.append("issues]", this.state.damageValue)

    
    this.state.images.forEach((image) => {
        if (image.file) {
          formData.append('images[]', image.file);
        }
      });

    this.saveCheckOutImgApiCallId = apiCalling({
        header : JSON.stringify(header),
        endPoint: configJSON.renterCheckOutApiEndPoint(this.state.bookedSlotId),
        method: configJSON.apiTypePost,
        body: formData
    })

  };

  saveCarCheckOutImages = () => {

    if (this.state.odometerValue.trim() === "") {
      toast.error('Please fill all detail.');
      return;
    }

    this.setState({ loading : true})

    const header = {
        "token": localStorage.getItem("userToken"),
      };

    const formData = new FormData()

    formData.append("issues]", this.state.damageValue)
    formData.append("mileage_odometer", this.state.odometerValue)
    formData.append("fuel_level", this.state.fuelLevel.toString())
    formData.append("is_key_provided", `${this.state.keyProvided}`)

    
    this.state.images.forEach((image) => {
        if (image.file) {
          formData.append('images[]', image.file);
        }
      });

    this.saveCheckOutImgApiCallId = apiCalling({
        header : JSON.stringify(header),
        endPoint: configJSON.renterCheckOutApiEndPoint(this.state.bookedSlotId),
        method: configJSON.apiTypePost,
        body: formData
    })

  };

  getCheckOutImages = () => {
    const header = {
        "token": localStorage.getItem("userToken"),
      };

    this.getCheckOutImgApiCallId = apiCalling({
        header : JSON.stringify(header),
        endPoint: configJSON.renterCheckOutApiEndPoint(this.state.bookedSlotId),
        method: configJSON.apiTypeGet,
    })
  }

  showImagePreview = (array : any[]) => {
     let modifiedArr : ImageFile[] =  Array(9).fill({ preview: '', file: null })

     if(array.length > 0) {
        modifiedArr = array.map((item : any) => {
            return {
                ...item,
                preview : truthyValue(item.url)
            }
         })
     }

     return modifiedArr
  }

  handleChangeforDamageKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ damageValue : event.target.value})
}
   
   navigateToPreviousPage = () => {
     this.props.navigation.goBack()
   }

  redirectToReviewPage = () => {
    this.props.navigation.navigate("PostReview", { bookSlotId: this.state.bookedSlotId })
  }

  checkBookSlotType = () => {
    return this.state.bookSlotType === "Car" ? true : false
  }

  moveToFinalCheckoutPage = () => {
    const unfilledImageSlots = this.state.images.filter(image => image.file === null).length;

    if (unfilledImageSlots > 0) {
      toast.error('Please upload all images.');
      return;
    }

    this.setState({ finalCheckout : true})
  }

  backToCheckOut = () => {
    this.setState({ finalCheckout : false})
  }

  handleOdometerValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputVal = event.target.value
    this.setState({ odometerValue: inputVal })
  }

  handleKeyProvided = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    this.setState({ keyProvided: checked })
  }

  handleFuelLevelModify = (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
    this.setState({ fuelLevel: newValue as number })
  }

  // Customizable Area End
}

// Customizable Area End